import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import Switch from 'react-switch';

//components
import withLocalization from '../../../../../hoc/withLocalization';
import applicationRouter from '../../../../../hoc/applicationRouter';
import Button from '../../../../../components/CustomButton/CustomButton.jsx';

//assets
import CloseApproveModalIcon from '../../../../../assets/img/CloseApproveModalIcon.svg';

export class AgreementModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAgreementModal: false,
            isChecked: false,
            checked: false
        };
    }


    handleAgreementModal = () => {
        this.setState({ showAgreementModal: !this.state.showAgreementModal });
    };

    onCheckChange(evt) {
        this.setState({ isChecked: !this.state.isChecked });
    }

    handleMove = () => {
        this.props.router.navigate('/admin');
    }

    handleSwitchChange = () => {
        this.setState({ checked: !this.state.checked }, () => {
            this.props.commonStore.setAppLang(this.state.checked ? 'en' : 'no');
        })
    }
    render() {
        const { t } = this.props;
        const { checked } = this.state;
        return (
            <>
                <Modal size="lg" show={this.props.show} className="AgreementModal" id="agreement">
                    <Modal.Header>
                        <Modal.Title>
                            {t('Review & Act on this document')}
                        </Modal.Title>
                        <div className='language-container'>
                            <Switch
                                height={20} width={40}
                                checked={checked}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor={'#128eb8'}
                                onChange={() => this.handleSwitchChange()}

                            />
                            <span className='mx-2'>{checked ? "EN" : "NO"}</span>
                        </div>
                        <img src={CloseApproveModalIcon} alt='close' className="cursor-pointer" onClick={() => this.setState({ accepting: null })} />
                    </Modal.Header>
                    <Modal.Body>
                        <div className='aggreement-main'>
                            <div className="agreement-modal-body">
                                <h4 style={{ fontWeight: 'bold' }}>{t('Sender')}</h4>
                                <h5 style={{ fontWeight: 'bold' }}>{this.props.user}</h5>
                                <p>{this.props.email}</p>
                            </div>

                            <hr></hr>
                            <p>
                                {t(`By selecting the 'I accept' button, you are signing this agreement electronically. You agree your electronic signature is the legal equivalent of your manual signature on this agreement. By selecting 'I accept' you consent to be legally bound by this agreement's terms and conditions.`)}
                            </p>
                            <p>{t('Please read the Electronic Record and Signature Disclosure')}</p>
                            <input
                                type="checkbox"
                                checked={this.state.isChecked}
                                onChange={(evt) => {
                                    this.onCheckChange(evt);
                                }}
                                className="check-box"
                            ></input>
                            <label style={{ paddingLeft: '10px' }}>{t('I agree to use electronic records and signature')}</label>
                            <br />
                            <div className='mt-3'>
                                <Button 
                                    fill wd
                                    disabled={!this.state.isChecked}
                                    onClick={() => this.props.handleAgreement()}
                                >
                                    {t('Continue')}
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default inject('commonStore')(withLocalization(applicationRouter(observer(AgreementModal))));
