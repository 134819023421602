import { observable, action, makeObservable } from 'mobx';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';

import agent from '../agent';
import * as scheduleUtils from '../../utils/scheduleUtils';

class ScheduleStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentAvailabilityList: observable,
            resources: observable,
            filteredResources: observable,
            currentAvailableAssignees: observable,
            currentEntity: observable,
            currentView: observable,
            availabiltyData: observable,
            currentShiftEntity: observable,
            isAvailabilityData: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            userData: observable,
            requestShift: observable,
            lastListLoadTime: observable,
            currentProjectMember: observable,
            currentWeekHeader: observable,
            detailsInEmployeeType: observable,
            shiftEventSelectionData: observable,
            isAllShiftEventSelected: observable,
            shiftAllowance: observable,
            filters: observable,
            availabilityFilters: observable,
            appliedAvailabilityFilters: observable,
            headerWeek: observable,
            resetLastListLoadTime: action,
            setLoading: action,
            setFilter: action,
            setAvailabilityFilter: action,
            onAvailabilityFilter: action,
            loadAvailabilityList: action,
            loadByUser: action,
            loadResources: action,
            loadEverything: action,
            loadList: action,
            returnDefaultNew: action,
            changeEntity: action,
            load: action,
            saveAvailability: action,
            removeAvailability: action,
            updateStatus: action,
            getAvailability: action,
            save: action,
            clearCurrentEntity: action,
            addBulkShift: action,
            updateAvailabilityStatus: action,
            memberAction: action,
            move: action,
            copy: action,
            copyToNextWeek: action,
            remove: action,
            removeMultiple: action,
            getWeekHeader: action,
            copyMonth: action,
            getAvailableAssignees: action,
            getSwapShiftRequests: action,
            getSwapShiftRequestsForPM: action,
            acceptSwapShiftRequests: action,
            rejectSwapShiftRequests: action,
            approveOrRejectSwapShiftRequestsFromPM: action,
            sendSwapShiftRequest: action,
            hideShowShifts: action,
            setFilteredResources: action,
            resetFilteredResources: action,
            initializeShiftEventCheckboxData: action,
            setShiftEventSelectionData: action,
            toggleSelectAllShiftEvent: action,
            overwriteOrRevertShiftChanges: action,
            getShiftsAndAssignees: action,
            approveTimesheets: action
        });
    }

    currentList = {};
    currentAvailabilityList = {};
    currentView = 0;
    resources = null;
    filteredResources = null;
    currentAvailableAssignees = [];
    currentEntity = {};
    availabiltyData = {};
    currentShiftEntity = {};
    isAvailabilityData = false;
    updatingErrors = null;
    updating = false;
    loading = false;
    requestParams = null;
    deleteSuccess = false;
    userData = null;
    requestShift = null;
    lastListLoadTime = null;
    currentProjectMember = null;
    currentWeekHeader = [];
    filters = {
        project: '',
        currentView: 0,
        sortFilterValue: null,
        resourceFilterValue: null,
        dayDurationFilterValue: null,
        pmManagedShiftsFilterValue: null,
    };
    headerWeek = null;
    detailsInEmployeeType = null
    shiftEventSelectionData = {};
    isAllShiftEventSelected = false;
    shiftAllowance = {};

    availabilityFilters = {
        name: ''
    };
    appliedAvailabilityFilters = {
        name: ''
    };

    setAvailabilityFilter(name, value) {
        const filters = Object.assign({}, this.availabilityFilters);
        filters[name] = value;
        this.availabilityFilters = filters;
    }

    onAvailabilityFilter() {
        this.appliedAvailabilityFilters = Object.assign({}, this.availabilityFilters);
    }


    initializeShiftEventCheckboxData(_schedulerData) {
        const events = _schedulerData && _schedulerData.events ? _schedulerData.events.filter(e => e.source === "shift") : []
        this.shiftEventSelectionData = {};
        if (events.length) {
            events.forEach(event => {
                if(event.isEditable){
                    this.shiftEventSelectionData[event.id] = {
                        checked: false,
                        details: {
                            shiftId: event.id,
                            userId: event.resourceId,
                            timelogEntries: event.timelogEntries,
                            timelogStatus: event.timelogStatus,
                            timelogCount: event.timelogCount,
                            timelogsToBeApproved: event.timelogsToBeApproved,
                            timelogsToBeApprovedCount: event.timelogsToBeApprovedCount
                        }
                    }
                }
            })
        }
        this.isAllShiftEventSelected = false;
    }

    setShiftEventSelectionData(id, value) {
        this.shiftEventSelectionData[id].checked = value;
        this.isAllShiftEventSelected = Object.keys(this.shiftEventSelectionData).every(id => this.shiftEventSelectionData[id].checked);
    }

    toggleSelectAllShiftEvent(value) {
        this.isAllShiftEventSelected = value;
        Object.keys(this.shiftEventSelectionData).forEach(id => {
            this.shiftEventSelectionData[id].checked = value
        })
    }

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    setLoading(value) {
        this.loading = value;
    }

    loadAvailabilityList(params) {
        return agent.Schedule.availabilityList(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    async loadByUser(params = {}) {
        this.loading = true;
        const list = await agent.Schedule.list(params);
        this.requestParams = params;
        this.userData = list;
        this.loading = false;
        return list;
    }

    async loadResources(params, isReload) {
        if (!this.resources || params.currentView !== this.currentView || isReload) {
            return agent.Schedule.loadResources(params).then(
                action((list) => {
                    let r;
                    if (params && params.userType === 'member' && !params.currentView) {
                        const filtered = _.filter(list, (r) => {
                            return r.id === 0 || r.id === params.userId;
                        });
                        r = filtered;
                    } else {
                        r = list;
                    }
                    r.forEach((res) => {
                        if (res.id === 0 && params.t) res.name = params.t(res.name);
                    });
                    this.resources = r;
                    this.currentView = params.currentView;
                })
            );
        }
        return this.resources;
    }

    setFilteredResources(projectMemberIds) {
        this.filteredResources = this.resources.filter((r) => projectMemberIds.includes(r.id) || r.id === 0);
    }

    resetFilteredResources() {
        this.filteredResources = null;
    }

    async loadEverything(params = {}, dateTimeRules, isScheduleShiftsCalculationsEnabled) {
        await this.loadResources(params);
        const list = await agent.Schedule.list(params);
        this.currentAvailabilityList = scheduleUtils.transformToSchedulerAvailabilityList(list, dateTimeRules);
        this.requestParams = params;
        this.currentList = scheduleUtils.transformToSchedulerEventList(list, dateTimeRules);
        this.headerWeek = list.detailsInDay;
        this.detailsInEmployeeType = list.detailsInEmployeeType;
        if (isScheduleShiftsCalculationsEnabled) await this.getWeekHeader(params);
        this.loading = false;
    }

    getAvailableAssignees(id) {
        return agent.Schedule.getAvailableAssignees(id)
            .then(
                action((list) => {
                    this.requestShift = id;
                    this.currentAvailableAssignees = list.availableusers;
                    this.loading = false;
                    return list.availableusers;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    getSwapShiftRequests() {
        return agent.Schedule.getSwapShiftRequests()
            .then(
                action((list) => {
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.loading = false;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    getSwapShiftRequestsForPM(params) {
        return agent.Schedule.getSwapShiftRequestsForPM(params)
            .then(
                action((list) => {
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.loading = false;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    acceptSwapShiftRequests(data) {
        return agent.Schedule.acceptSwapShiftRequests(data)
            .then(
                action((response) => {
                    response.time = new Date();
                    this.lastListLoadTime = response.time;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    rejectSwapShiftRequests(data) {
        return agent.Schedule.rejectSwapShiftRequests(data)
            .then(
                action((response) => {
                    response.time = new Date()
                    this.lastListLoadTime = response.time;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    approveOrRejectSwapShiftRequestsFromPM(data) {
        return agent.Schedule.approveOrRejectSwapShiftRequestsFromPM(data)
            .then(
                action((response) => {
                    this.lastListLoadTime = response.time;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    approveOrRejectTradeShiftRequestsFromPM(data) {
        return agent.Schedule.approveOrRejectTradeShiftRequestsFromPM(data)
            .then(
                action((response) => {
                    this.lastListLoadTime = response.time;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    loadList(params, dateTimeRules) {
        return agent.Schedule.list(params)
            .then(
                action((list) => {
                    if (params.isModalView)
                        return { shifts: scheduleUtils.transformToSchedulerEventList(list, dateTimeRules, params.isModalView), shiftAllowance: list.shiftAllowance }
                    this.requestParams = params;
                    this.currentList = scheduleUtils.transformToSchedulerEventList(list, dateTimeRules, params.isModalView);
                    this.currentAvailabilityList = scheduleUtils.transformToSchedulerAvailabilityList(list, dateTimeRules);
                    this.loading = false;
                    this.headerWeek = list.detailsInDay
                    return this.currentList;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    returnDefaultNew(params) {
        if (params) {
            if (params.for_date)
                params.to_date = params.for_date;
            this.currentEntity = Object.assign({}, params);
        }
        if (this.currentEntity && !this.currentEntity.data) this.currentEntity.data = {};
        this.loading = false;
    }

    changeEntity(name, value) {
        const entity = Object.assign({}, this.currentEntity);
        const nameArray = name.split('.');
        if (nameArray.length === 2) {
            let data = []
            if (name === 'data.invited_ids') {
                value.forEach(item => {
                    data.push(`${item}`)
                })
                value = data
            }
            const [firstProp, secondProp] = nameArray;
            entity[firstProp] = entity[firstProp] || {};
            entity[firstProp][secondProp] = value;
        } else {
            entity[name] = value;
        }
        this.currentEntity = entity;
    }

    load(id) {
        this.loading = true
        return agent.Schedule.load(id)
            .then(
                action((response) => {
                    this.currentEntity = response.shift;
                    this.currentEntity.isPendingShift = !this.currentEntity.assignee_id;
                    this.currentProjectMember = response.memberHavingSameProject;
                    this.loading = false;
                    return response.shift;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    saveAvailability(values) {
        this.updating = true;
        return agent.Schedule.saveAvailability(values)
            .then(
                action((user) => {
                    this.currentEntity = user.shift;
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async removeAvailability(id) {
        const res = await agent.Schedule.removeAvailability(id);
        this.deleteSuccess = true;
        return res;
    }

    updateStatus(id, data) {
        this.updating = true;
        return agent.Schedule.updateStatus(id, data)
            .then(
                action((user) => {
                    this.currentEntity = user.shift;
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    sendSwapShiftRequest(shift, users, fromUser, swap_type) {
        this.updating = true;
        return agent.Schedule.sendSwapShiftRequest({ shiftId: shift.id, users: users, fromUser: fromUser, swap_type: swap_type })
            .then(
                action((response) => {
                    this.currentShiftEntity = response;
                    return this.currentShiftEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    getAvailability(values) {
        this.isAvailabilityData = true;
        values.start_date = moment(values.start_date).format('YYYY-MM-DD');
        values.end_date = moment(values.end_date).format('YYYY-MM-DD');
        let multiUser = false;
        if (values && values.userIDs) {
            multiUser = true;
        }
        return agent.Schedule.getAvailability(values)
            .then(
                action((res) => {
                    if (!multiUser) {
                        this.availabiltyData = res.availability[0];
                    }
                    else {
                        this.availabiltyData = res.availability;
                    }
                    this.isAvailabilityData = false;
                    this.shiftAllowance = res.shiftAllowance;
                    return this.availabiltyData;
                })
            )
            .catch(
                action((err) => {
                    this.isAvailabilityData = false;
                    throw err;
                })
            )
    }

    save(values, isAdd) {
        this.updating = true;
        this.loading = true;
        values.for_date = moment(values.for_date).format('YYYY-MM-DD');
        values.to_date = moment(values.to_date).format('YYYY-MM-DD');
        return agent.Schedule.save(values, isAdd)
            .then(
                action((user) => {
                    if (!user.shift) return null;
                    this.currentEntity = user.shift;
                    this.currentEntity.do_notify = values.do_notify
                    this.clearCurrentEntity()
                    this.loading = false;
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    this.loading = false;
                    throw err;
                })
            );
    }

    clearCurrentEntity() {
        this.currentEntity.id = '';
    }

    addBulkShift(values) {
        this.updating = true;
        values.start_date = moment(values.start_date).format('YYYY-MM-DD');
        values.end_date = moment(values.end_date).format('YYYY-MM-DD');
        return agent.Schedule.addBulkShift(values)
            .then(
                action((user) => {
                    this.updating = false;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
    }

    updateAvailabilityStatus(id, values) {
        this.updating = true;
        return agent.Schedule.updateAvailabilityStatus(id, values)
            .then(
                action((res) => {
                    this.updating = false;
                    return res;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
    }

    async memberAction(action, currentUser, extras) {
        this.updating = true;
        if (!extras) extras = {};
        extras.proposed = this.currentEntity.data.proposed || null;
        const changes = await agent.Schedule.memberAction(this.currentEntity.id, action, {
            action,
            comment: this.currentEntity.comment,
            extras,
        });
        this.updating = false;
        return changes;
    }

    async move(data) {
        this.updating = true;
        const changes = await agent.Schedule.move(data);
        this.updating = false;
        return changes;
    }

    async copy(data) {
        this.updating = true;
        const changes = await agent.Schedule.copy(data);
        this.updating = false;
        return changes;
    }

    async copyToNextWeek(params) {
        this.updating = true;
        const changes = await agent.Schedule.copyToNextWeek(params);
        this.updating = false;
        return changes;
    }

    async remove(id, params = {}) {
        await agent.Schedule.remove(id, params);
        this.deleteSuccess = true;
        return 1;
    }

    async removeMultiple(id, params = {}) {
        return agent.Schedule.removeMultiple(id, params).then(
            action((res) => {
                return res;
            })
        )
            .catch(
                action((err) => {
                    throw err;
                })
            )
    }

    async hideShowShifts(data) {
        return agent.Schedule.hideShowShifts(data).then(
            action((res) => {
                return res;
            })
        )
            .catch(
                action((err) => {
                    throw err;
                })
            )
    }

    async getWeekHeader(params) {
        return agent.Schedule.getWeekHeader(params).then(
            action((res) => {
                this.currentWeekHeader = this.getSingleHeader(this.headerWeek, res)
                return this.currentWeekHeader;
            })
        )
            .catch(
                action((err) => {
                    throw err;
                })
            )
    }

    getSingleHeader = (headerWeek, headerWeekBudget) => {
        const map = new Map(headerWeek.map(item => [item.for_date, item]));;
        const headerMerged = headerWeekBudget?.headerData?.map(item => {
            const isItem = map.get(moment(item.for_date).format("YYYY-MM-DD"));
            if (isItem) return { ...item, ...isItem };
            else return item
        });
        return headerMerged
    }

    async copyMonth(data) {
        this.updating = true;
        const changes = await agent.Schedule.copyMonth(data);
        this.updating = false;
        return changes;
    }

    overwriteOrRevertShiftChanges = (data) => {
        return agent.Schedule.overwriteOrRevertShiftChanges(data).then(
            action((res) => {
                return res;
            })
        )
            .catch(
                action((err) => {
                    throw err;
                })
            )
    }

    getShiftsAndAssignees = (params) => {
        return agent.Schedule.getShiftsAndAssignees(params).then(
            action(res => {
                return res.list;
            })
        ).catch(
            action(err => {
                throw err;
            })
        )
    }

    getAvailableAssigneesForBulkShift = () => {
        return agent.Schedule.getAvailableAssigneesForBulkShift().then(
            action(res => {
                return res.availableAssignees;
            })
        ).catch(
            action(err => {
                throw err;
            })
        )
    }

    approveTimesheets = (payload) => {
        return agent.Schedule.approveTimesheets(payload).then(
            action(res => {
                return res;
            })
        ).catch(
            action(err => {
                throw err;
            })
        )
    }
}

const _ScheduleStore = new ScheduleStore();
export default _ScheduleStore;