import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';
import moment from 'moment/min/moment-with-locales';
import { confirmAlert } from 'react-confirm-alert';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import AdminAddLimitBeforeAdd from './AdminAddLimitBeforeAdd';
import GenericForm from '~/components/GenericForm/GenericForm';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import getSchema from '~/library/schemas/user';
import customerFeature from '~/utils/customerFeature';
import { getCountryCode } from '../../../utils/dateFormat';
import { isUserUpdatedValuesForVisma } from '~/utils/integrationUtils'

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            'ui:subtitle': 'Member information',
            'ui:className': 'profile-edit-form__group',
            image: { xs: { span: 12, order: 1 }, sm: { span: 4, order: 1 }, md: 3 },
            first_name: { xs: { span: 6, order: 1 }, sm: { span: 4, order: 1 }, md: 3, className: "d-block d-sm-flex align-items-center mt-2 mt-md-1" },
            last_name: { xs: { span: 6, order: 1 }, sm: { span: 4, order: 1 }, md: 3, className: "d-block d-sm-flex align-items-center mt-2 mt-md-1" },
            status: { xs: { span: 4, offset: 1, order: 3 }, sm: { span: 2, offset: 0, order: 1 }, md: 2, className: "d-block d-sm-block d-md-flex align-items-center mt-2 mt-md-0" },
            email: { xs: { span: 12, order: 1 }, sm: { span: 6, order: 1 }, md: 3, className: "mt-2" },
            phone: { xs: { span: 6, order: 1 }, sm: { span: 4, order: 1 }, md: 2, className: "mt-2" },
            bank_account_number: { xs: { span: 6, order: 1 }, sm: { span: 6, order: 1 }, md: 3, className: "mt-2" },
            social_number: { xs: { span: 6, order: 2 }, sm: { span: 6, order: 1 }, md: 2, className: "mt-2" },
            birthday: { xs: { span: 6, order: 2 }, sm: { span: 6, order: 1 }, md: 2, className: "mt-2" },
        },
        {
            'ui:subtitle': 'Address information',
            'ui:className': 'profile-edit-form__group',
            address: { xs: 12, sm: 4, className: "mt-2" },
            post_number: { xs: 6, sm: 4, className: "mt-2" },
            post_place: { xs: 6, sm: 4, className: "mt-2" },
        },
        {
            'ui:subtitle': 'Access Information',
            'ui:className': 'profile-edit-form__group',
            internal_number: { xs: 3, sm: 4, className: "mt-2" },
            username: { xs: 6, sm: 4, className: "mt-2" },
            password: { xs: 3, sm: 4, className: "mt-2" },
            allow_mobile_app_access: { xs: 3, sm: 4, className: "mt-2" },
        },
        {
            'ui:subtitle': 'System settings',
            'ui:className': 'profile-edit-form__group',
            timelog_start_from: { xs: { span: 6, order: 1 }, sm: 4, className: "mt-2" },
            allow_tip: { xs: { span: 4, order: 2 }, sm: 4, md: 4, className: "mt-3" },
            vacation_days: { xs: { span: 6, order: 1 }, sm: 4, className: "mt-2" },
            employee_type: { xs: { span: 8, order: 3 }, sm: 4, md: 4, className: "mt-3" },
            groups: { xs: { span: 8, order: 3 }, sm: 4, md: 4, className: "mt-3" },
        },
        {
            'ui:subtitle': 'Payment settings',
            'ui:className': 'profile-edit-form__group',
            payment_mode: { xs: { span: 12, order: 1 }, sm: { span: 4, order: 1 }, className: "mt-2" },
            hourly_rate: { xs: { span: 12, order: 1 }, sm: { span: 4, order: 1 }, className: "mt-2" },
            fixed_hourly_rate: { xs: { span: 12, order: 1 }, sm: { span: 4, order: 1 }, className: "mt-2" },
            payment_cycle: { xs: { span: 12, order: 4 }, sm: { span: 4, order: 1 }, className: "mt-2" },
            expected_fixed_hours: { xs: { span: 12, order: 2 }, sm: { span: 4, order: 1 }, className: "mt-2 me-md-2" },
            employee_percent: { xs: { span: 12, order: 4 }, sm: { span: 4, order: 1 }, className: "mt-2" },
            weekend_extra_payment_percent: { xs: { span: 12, order: 1 }, sm: { span: 4, order: 1 }, className: "mt-2" },
            calculated_weekly_hourly_payment: { xs: { span: 12, order: 3 }, sm: { span: 4, order: 1 }, className: "mt-2" },
            extra_payments: { xs: { span: 12, order: 3 }, sm: { span: 6, order: 1 }, className: "mt-2" },
        },
        {
            'ui:className': 'profile-edit-form__group',
            attachments: { md: 12 },
        },
        {
            'ui:subtitle': 'Integration Details',
            'ui:className': 'profile-edit-form__group',
            departmentIntegration: { md: 4 },
        }
    ],

    status: {
        'ui:widget': 'ActiveInactive',
    },
    image: {
        'ui:widget': 'ImageUpload',
    },
    allow_tip: {
        'ui:widget': 'Radio',
    },
    disable_autolog: {
        'ui:widget': 'TrueFalse',
    },
    attachments: {
        'ui:widget': 'AttachmentsWidget',
        'ui:imageContext': {
            model: 'User',
            fileType: 'docs',
            id: 0,
        },
    },
    ManagesUsers: {
        items: {
            id: {
                'ui:widget': 'MembersWidget',
            },
        },
    },
    MemberInProjects: {
        'ui:widget': 'MemberInProjectsWidgetMultiSelect',
    },
    groups: {
        'ui:widget': 'SingleUserGroupsWidget',
    },
    allow_mobile_app_access: {
        'ui:widget': 'TrueFalse',
    },
    departmentIntegration: {
        'ui:widget': 'UserFormIntegrationWidget',
    },
    extra_payments: {
        'ui:widget': 'SimpleMultiSelectWidget',
        'ui:options': []
    }
};

class UsersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            uiSchema: defaultUiSchema,
            assignedDocuments: [],
            payment_mode: 'hourly'
        };
        this.setRef = this.setRef.bind(this);
        this.confirmSync = this.confirmSync.bind(this);
        props.userStore.startLoading();
    }

    setRef(button) {
        this.childRef = button;
    }

    async loadData() {
        const { userStore, commonStore, mode, id, add } = this.props;
        const { currentEntity } = userStore;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data
            && commonStore.config.client.data.dateTimeRules
            ? commonStore.config.client.data.dateTimeRules : false;

        if (!add) {
            await userStore.load(id, add);
        }

        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
                isAdd: add,
                mode,
                userType: userStore.currentUser.user_type,
                currentEntityUser: this.props.userStore?.currentEntity?.user || 'fixed',
                currentUser: this.props.userStore?.currentUser,
                translate: this.props.t
            }),
            payment_mode: currentEntity.user?.payment_mode || 'hourly'
        });

        const params = queryString.parse(this.props.router.location.search) || {};
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, currentEntity.user, mode, userStore.currentUser) });
        if (add) {
            if (mode !== 'superadmins') {
                await userStore.validateAddPossible();
            }

            let vacation_days = 25;
            if (
                commonStore.config &&
                commonStore.config.client &&
                commonStore.config.client.data &&
                commonStore.config.client.data.Vacation_setup &&
                commonStore.config.client.data.Vacation_setup.standared_vacation_days
            )
                vacation_days = commonStore.config.client.data.Vacation_setup.standared_vacation_days;

            const countryCode = getCountryCode(dateTimeRules);

            return userStore.returnDefaultNew(
                mode,
                userStore.currentUser.client_id,
                params,
                userStore.currentUser,
                vacation_days,
                countryCode
            );
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    addAssignedDocuments = async (user_id) => {
        const { documentStore } = this.props;
        let data = {
            document_template_ids: this.state.assignedDocuments,
            user_id: user_id,
        };
        await documentStore.assignDocumentTemplatesToUser(data);
    };

    employeeContract = () => {
        const { commonStore, t, id, add } = this.props;
        id === 0 ? this.childRef.click()
            :
            this.props.router.navigate('/admin/employeecontract', {
                state: { detail: id },
            });
        if (!add) commonStore.addNotification(t('Saved'), null, 'success');
    };

    validateUser(formData, errors) {
        const { t } = this.props;
        if (formData.birthday && moment(formData.birthday).isSameOrAfter(moment())) {
            errors.birthday.addError(t('Please enter valid birthdate'));
        }
        if (formData.username && formData.username.trim() === '') {
            errors.username.addError(t('is a required property'));
        }
        if (formData?.payment_cycle === 'monthly' && formData?.expected_fixed_hours > 743) {
            errors.expected_fixed_hours.addError(t('Max monthly overtime hours limit is 743'));
        }
        if (formData?.payment_cycle === 'weekly' && formData?.expected_fixed_hours > 167) {
            errors.expected_fixed_hours.addError(t('Max weekly overtime hours limit is 167'));
        }
        if (!formData.employee_percent || isNaN(formData.employee_percent) || formData.employee_percent > 100) {
            errors?.employee_percent?.addError(t("Employment percentage should be between 1 to 100"));
        }
        return errors;
    }

    confirmSync = (values, result, add) => {
        const { commonStore, userStore, t } = this.props;
        if (commonStore.config?.integration_details?.product && values.user_type !== 'admin') {
            if (add || !values.int_id) {
                const str = `${t("Do You want to Sync Member with")} ${t(commonStore.config?.integration_details?.product)}?`;
                confirmAlert({
                    title: this.props.t('Confirm to Sync'),
                    message: str,
                    buttons: [
                        {
                            label: t('Yes'),
                            onClick: () => {
                                return userStore.saveIntoIntegration(result.user).then((res2) => {
                                    userStore.resetLastListLoadTime(new Date());
                                    commonStore.addNotification(`${t(res2.message)} ${commonStore.config?.integration_details?.product}`, null, 'success', "top-right", 3000);
                                    return this.redirectAfterSaveAndSycn();
                                }).catch(() => {
                                    this.props.onAddEdit(result.user.id)
                                });
                            },
                        },
                        {
                            label: t('No'),
                            onClick: () => {
                                return this.redirectAfterSaveAndSycn();
                            },
                        },
                    ],
                })
            }
            else {
                userStore.saveIntoIntegration(result.user).then((res2) => {
                    return this.redirectAfterSaveAndSycn();
                });
            }

        }
        else {
            return this.redirectAfterSaveAndSycn();
        }
    }

    redirectAfterSaveAndSycn = () => {
        if (this.props.goBack) this.getBack();
        else this.props.router.navigate(this.props.getListUrl());
    }

    onSave(values, redirect) {
        const { userStore, t, commonStore, add, mode } = this.props;
        values.isAdminOrManager = true;

        //taking username from email if not provided//
        if (!values.username) values.username = values.email.split('@')[0];

        if (!values.MemberInProjects && values.user_type !== 'admin' && values.user_type !== 'super-admin') {

            if (userStore.currentEntity.MemberInProjects && userStore.currentEntity.MemberInProjects.length > 0) {
                values.MemberInProjects = userStore.currentEntity.MemberInProjects;
            } else {
                commonStore.addNotification(t('Select projects for member'), null, 'error');
                return;
            }
        }

        return userStore.save(values, add, mode).then((result) => {
            if (!result.user || !result.user.id) {
                // some error
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            if (result.user.id && result.user.status === 'active') {
                this.addAssignedDocuments(result.user.id);
            }

            commonStore.addNotification(t('Saved'), null, 'success');

            isUserUpdatedValuesForVisma(commonStore, add, values, t, result, this.confirmSync);
        });

    }

    //added getBack for calling updating parent//
    getBack() {
        const { page } = this.props;
        this.props.goBack(page);
    }

    prepareSchema(_schema, currentEntity, mode, currentUser) {
        const clientConfig = this.props.commonStore.config.client.data;
        const extra_payments_rules = clientConfig.extraPayments ? clientConfig.extraPayments : [];
        const autoTimelogsEnabled = clientConfig && clientConfig.loginRules ?
            clientConfig.loginRules.autoTimelogs === 'gps' : false;
        const isMobileAccessDisabled = clientConfig && clientConfig.loginRules ? clientConfig.loginRules.disableMobileAccess : false;

        const schema = cloneDeep(_schema);
        schema.image = {
            'ui:widget': 'ImageUpload',
            'ui:imageContext': {
                model: 'User',
                fileType: 'logo',
                id: currentEntity ? currentEntity.id : 0,
            },
        };
        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'User',
                fileType: 'docs',
                id: currentEntity ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
                withTable: true,
            },
        };

        if (schema.extra_payments) {
            schema.extra_payments = {
                'ui:widget': 'SimpleMultiSelectWidget',
                'ui:options': extra_payments_rules.filter(ele => ele.isActive && !(['shift_allowance', 'emergency_shifts'].includes(ele.code))).map(ele => { return { label: ele.name, value: ele.code } })
            }
        }
        if (mode === 'members') {
            const accessObj = {
                'ui:subtitle': 'Access Information',
                'ui:className': 'profile-edit-form__group',
            };
            accessObj.username = { xs: 12, sm: 4, className: "mt-1" };
            accessObj.password = { xs: 6, sm: 4, className: "mt-1" };
            accessObj.user_type = { xs: 6, sm: 4, className: "mt-1" };
            accessObj.internal_number = { xs: 6, sm: 4, className: "mt-1" };
            if (customerFeature('locked_mode')) {
                accessObj.generic_pin = { xs: 6, sm: 4, className: "mt-1" };
            }
            if (currentUser.role !== 'member' && autoTimelogsEnabled) {
                accessObj.disable_autolog = { xs: 6, sm: 4, md: 2, className: "mt-2" };
            }
            if (isMobileAccessDisabled)
                accessObj.allow_mobile_app_access = { xs: 6, sm: 4, md: 2, className: "mt-2" };
            schema['ui:layout'][2] = accessObj;

            if (currentUser.role !== 'member') {
                schema['ui:layout'][3].MemberInProjects = { xs: { span: 12, order: 1 }, sm: 8, md: 4, className: "mt-2" };
            }
        }
        if (mode === 'managers') {
            schema['ui:layout'].push({
                ManagesUsers: { md: 12 },
            });
        }

        if (this.props.commonStore.config?.integration_details?.product !== 'tripletex') {
            schema['ui:layout'] = schema['ui:layout'].filter(ele => ele['ui:subtitle'] !== 'Integration Details')
        }

        if (mode === 'superadmins') {
            schema['ui:layout'][1] = schema['ui:layout'][2];
            schema['ui:layout'][2] = schema['ui:layout'][4];
            schema['ui:layout'].pop();
            schema['ui:layout'].pop();
            schema['ui:layout'].pop();
        }

        return schema;
    }

    handleAssignDocuments = (ids) => {
        this.setState({ assignedDocuments: ids });
    };

    updateSchema = (allFormData, oldData) => {
        if (allFormData.formData.payment_mode === oldData.payment_mode) return;
        const { schema } = allFormData;
        let _schema = cloneDeep(schema);
        if (_schema.properties.expected_fixed_hours && allFormData?.formData?.payment_mode)
            _schema.properties.expected_fixed_hours.readOnly = allFormData?.formData?.payment_mode === 'hourly' ? true : false
        this.setState({ schema: _schema })
    }

    calculateFixedHourlyRate = (formData) => {
        const { commonStore, userStore } = this.props;
        if (commonStore?.config?.client?.data?.basicRules?.hideSensitiveDetailsFromPM && userStore?.currentUser?.user_type === 'pm' && userStore?.currentUser?.id !== userStore.currentEntity.user?.id) return formData;
        const decimalPoint = this.props.commonStore?.config?.client?.data?.dateTimeRules?.currency_decimal_places
            ? this.props.commonStore.config.client.data.dateTimeRules.currency_decimal_places : 2;
        if (formData?.payment_mode === 'fixed') {
            if (formData?.expected_fixed_hours === null || formData?.expected_fixed_hours === 0 ||
                formData?.hourly_rate === null || formData?.hourly_rate !== 0)
                formData['fixed_hourly_rate'] = 0
            if (formData?.hourly_rate > 0 && formData?.expected_fixed_hours > 0)
                formData['fixed_hourly_rate'] = parseFloat((formData.hourly_rate / formData.expected_fixed_hours).toFixed(decimalPoint))
            if (formData?.fixed_hourly_rate > 0 && formData.weekend_extra_payment_percent > 0)
                formData['calculated_weekly_hourly_payment'] = parseFloat((formData.fixed_hourly_rate + (formData.fixed_hourly_rate * formData.weekend_extra_payment_percent) / 100).toFixed(decimalPoint))
            else
                formData['calculated_weekly_hourly_payment'] = 0
        }
        if (formData?.payment_mode === 'hourly') {
            formData.expected_fixed_hours = 0;
            formData.fixed_hourly_rate = formData?.hourly_rate ? formData?.hourly_rate : 0;
            if (formData?.hourly_rate > 0)
                formData['calculated_weekly_hourly_payment'] = parseFloat((formData.hourly_rate + (formData.hourly_rate * formData.weekend_extra_payment_percent) / 100).toFixed(decimalPoint))
            else
                formData['calculated_weekly_hourly_payment'] = 0
        }
        return formData
    }

    render() {
        const { userStore, add, t, mode } = this.props;
        const { currentEntity, addPossibility } = userStore;
        const { schema } = this.state;
        if (this.props.userStore.loading) {
            return <LoadingSpinner />;
        }
        const addValid = !!add || addPossibility.allowed;

        let assignDocument = false;
        if (userStore.currentUser.user_type !== 'member' && userStore.currentUser.user_type !== 'super-admin') {
            assignDocument = true;
        }

        return (
            <div className="primary-page profile-edit-form">
                {!addValid && <AdminAddLimitBeforeAdd />}

                {/* removing social and number page as per request - 28/10/2021 */}
                {/* {addValid && !canProceedEdit && <UserValidateBeforeAdd />} */}

                {/* removed caneditProceed condition- 28/10/2021 */}
                {/* {addValid && canProceedEdit && ( */}

                {addValid && (
                    <div>
                        <Row>
                            {currentEntity?.user?.user_type !== 'admin' &&
                                currentEntity.user.user_type !== 'super-admin' && (
                                    <Col xs={12} sm={{ span: 6, offset: 6 }}
                                        md={{ span: 4, offset: 8 }}
                                        className={"text-end"}
                                    >
                                        <Button
                                            className="nextbtn btn-fill"
                                            fill
                                            wd
                                            icon
                                            onClick={this.employeeContract}
                                        >
                                            {t('Employee Contract')}
                                        </Button>
                                    </Col>
                                )}

                            <GenericForm
                                entity={currentEntity.user}
                                uiSchema={this.prepareSchema(
                                    defaultUiSchema,
                                    currentEntity.user,
                                    mode,
                                    userStore.currentUser
                                )}
                                updateSchema={(allFormData, formData) => this.updateSchema(allFormData, formData)}
                                onChange={this.calculateFixedHourlyRate}
                                schema={schema}
                                translationScope="forms.user"
                                onSave={(values, redirect) => this.onSave(values, redirect)}
                                listUrl={this.props.getListUrl}
                                isAdding={add}
                                setRef={this.setRef}
                                assignDocument={assignDocument}
                                handleAssignDocuments={(ids) => this.handleAssignDocuments(ids)}
                                customvalidate={(formData, errors) => this.validateUser(formData, errors)}
                                userContract={true}
                                docAttachments={true}
                                goBack={this.props.goBack ? () => this.getBack() : false}
                            />
                        </Row>
                    </div>
                )}
            </div>
        );
    }
}

export default inject(
    'userStore',
    'commonStore',
    'documentStore'
)(applicationRouter(withLocalization(observer(UsersForm))));
