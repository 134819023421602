import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//hocs and components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import MemberAvailability from './MemberAvailability';


class ShowAvailabiliy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availability: [],
            tempAvailability: []
        };
    }

    componentDidMount() {
        this.getAvailabilityData();
    }

    async getAvailabilityData() {
        const { scheduleStore, start_date, end_date, userIDs } = this.props;
        const avaData = { start_date: start_date, end_date: end_date, userIDs: userIDs };
        if (avaData) {
            const data = await scheduleStore.getAvailability(avaData);
            if (data) {
                this.setState({ availability: data }, () => {
                    this.filterObj()
                })
            }
        }
    }

    filterObj() {
        const { availability } = this.state;
        const result = availability.reduce(function (r, a) {
            r[a.for_date] = r[a.for_date] || [];
            r[a.for_date].push(a);
            return r;
        }, Object.create(null));
        this.setState({ tempAvailability: result }, () => {
        });

    }

    render() {
        const { t } = this.props;
        return (
            <>
                {Object.keys(this.state.tempAvailability).length <= 0 ?
                    <span>{t("No Availability")}</span>
                    :
                    <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                        {Object.keys(this.state.tempAvailability).length && Object.keys(this.state.tempAvailability).map((ava, index) =>
                            <div key={index}>
                                <MemberAvailability
                                    obj={this.state.tempAvailability[ava]}
                                    index={index}
                                />
                            </div>
                        )}
                    </div>
                }
            </>
        )
    }
}
export default inject('scheduleStore')(withLocalization(applicationRouter(observer(ShowAvailabiliy))));