import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

//components
import applicationRouter from '~/hoc/applicationRouter';
import { AttachmentsWidget } from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import DatePicker from '../../../components/DatePicker/DatePicker';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

//elements
import Input from '../../../elements/Input';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '../../../elements/LoadingSpinner';

//utils
import { longDateFormat } from '../../../utils/dateFormat';

class SubscriptionsForm extends Component {
    state = {
        name: '',
        expiry_date: '',
        attachments: '',
        imageFileExtentions: ['jpg', 'jpeg', 'png', 'svg', 'svgz', 'webp', 'bmp', 'gif', 'j2k', 'ico'],
        user_id: null,
        error: '',
        disable: false,
    };

    handleClose = () => {
        this.props.router.navigate('/admin/subscriptions');
    };
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    componentDidMount() {
        let getId = (this.props.router.location.state && this.props.router.location.state.subscriptions) || null;
        if (getId) {
            this.loadData();
        }
    }
    async loadData() {
        const { subscriptionStore } = this.props;
        let getId = (this.props.router.location.state && this.props.router.location.state.subscriptions) || null;
        const subscriptions = await subscriptionStore.load(getId, !getId);
        this.setState({
            attachments: subscriptions.subscriptions.attachments,
            name: subscriptions.subscriptions.name,
            expiry_date: new Date(moment(subscriptions.subscriptions.expiry_date).format('MM/DD/YYYY')),
            user_id: subscriptions.subscriptions.user_id,
            disable: true,
        });
    }

    handleSave = () => {
        const { commonStore, subscriptionStore, t } = this.props;
        const { currentEntity } = subscriptionStore;
        let getId = (this.props.router.location.state && this.props.router.location.state.subscriptions) || null;
        const state = Object.assign({}, this.state);
        state.name = this.state.name;
        state.user_id = this.state.user_id;
        state.expiry_date = moment(this.state.expiry_date).format('YYYY-MM-DD');
        if (getId) state.id = currentEntity.subscriptions.id;

        if (!state.name || !state.expiry_date || !state.attachments) {
            commonStore.addNotification(t('Please fill all the fields'), null, 'warning');
            this.setState({ error: t('Please Add Value') });
            return;
        }
        this.setState({ error: '' });
        if (getId) {
            subscriptionStore.save(state, !getId).then((result) => {
                if (!result) {
                    commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                    return true;
                }
                this.setState({ error: '' });
                commonStore.addNotification(t('Details Added Successfully'), null, 'success');
                this.props.router.navigate('/admin/subscriptions');
            });
        } else {
            subscriptionStore.save(state, !getId).then((result) => {
                if (!result) {
                    commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                    return true;
                }
                this.setState({ error: '' });
                commonStore.addNotification(t('Details Added Successfully'), null, 'success');
                this.props.router.navigate('/admin/subscriptions');
            });
        }
    };

    handleAttachments = (value) => {
        this.setState({ attachments: value });
    };

    render() {
        const { t, commonStore, userStore, subscriptionStore } = this.props;
        const { loading } = subscriptionStore;
        const { error } = this.state;
        const dateTimeRules =
            commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules
                ? commonStore.config.client.data.dateTimeRules
                : false;
        if (loading) {
            return <LoadingSpinner />;
        }
        return (
            <>
                <div>
                    <div>
                        <div className="row-flex" style={{ justifyContent: 'center' }}>
                            <div>
                                <span style={{ color: '#818181' }}>{t('Please Add Attachments') + '*'}</span>
                                <AttachmentsWidget
                                    value={this.state.attachments}
                                    onChange={(attachIds) => {
                                        this.setState({
                                            attachments: attachIds,
                                        });
                                    }}
                                    options={{
                                        imageContext: {
                                            existingAttachment:
                                                subscriptionStore.currentEntity.subscriptions &&
                                                subscriptionStore.currentEntity.subscriptions.Attachments,
                                            fileType: 'docs',
                                            id: 0,
                                            model: 'Image',
                                        },
                                    }}
                                    error={this.state.attachments && !this.state.attachments}
                                />
                                {!this.state.attachments && (
                                    <span className="text-danger text-center ml-30">{t(error)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    {userStore.currentUser.user_type !== 'member' && (
                        <div>
                            <div className="row-flex" style={{ justifyContent: 'center' }}>
                                <div className="subs_w-35">
                                    <MembersWidget
                                        value={this.state.user_id}
                                        onChange={(user_id) => this.handleChange('user_id', user_id)}
                                        label={t('Select Member') + '*'}
                                        mode="member"
                                        disabled={this.state.disable}
                                    />
                                    {!this.state.user_id && <span className="text-danger text-center">{t(error)}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        <div className="row-flex" style={{ justifyContent: 'center' }}>
                            <div className="subs_w-37">
                                <Input
                                    className="custom-form__col"
                                    label={t('Please Enter Name') + '*'}
                                    placeholder={t('Enter Name')}
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(evt) => {
                                        this.handleChange('name', evt.target.value);
                                    }}
                                />
                                {!this.state.name && <span className="text-danger text-center ml-10">{t(error)}</span>}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row-flex" style={{ justifyContent: 'center' }}>
                            <div className="subs_w-35">
                                <DatePicker
                                    selected={this.state.expiry_date}
                                    onChange={(date) => this.handleChange('expiry_date', date)}
                                    dateFormat={longDateFormat(dateTimeRules)}
                                    minDate={new Date()}
                                    label={t('Expiry date') + '*'}
                                />
                                {!this.state.expiry_date && <span className="text-danger text-center">{t(error)}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <div>
                        <Button fill wd onClick={this.handleSave}>
                            {t('Save')}
                        </Button>
                    </div>
                    <div className="ml-10">
                        <Button wd variant="secondary" onClick={this.handleClose}>
                            {t('Close')}
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}
export default inject(
    'authStore',
    'subscriptionStore',
    'userStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(SubscriptionsForm)))));
