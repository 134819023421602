import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';
import { find } from 'lodash';

//components
import withLocalization from '../../hoc/withLocalization';
import DatePicker from '../../components/DatePicker/DatePicker';
import { ProjectsWidget } from '../../components/GenericForm/Widgets/ProjectsWidget';
import { AllUsersWidget } from '../../components/GenericForm/Widgets/UsersWidget';


//elements
import Button from '../../components/CustomButton/CustomButton.jsx';
import Select from '../../elements/Select';

//utils
import { getShortDateFormat } from '../../utils/dateFormat';
import { countryOptions } from '../../utils/constants';

//assets
import settingSlider from '../../assets/img/settings-sliders.svg';

//styles
import '../Chat/ConversationSearch/ConversationSearch.css';


class GenericFilter extends Component {
    setFilterDate(name, name1, value) {
        this.props.setFilter(name1, value);
        this.props.setFilter(name, moment(new Date(value)).format('YYYY-MM-DD'));
    }
    render() {
        const {
            filters,
            setFilter,
            onFilter,
            loading,
            t,
            searchFilterName = 'name',
            searchFilterPlaceholder = 'Search',
            hasSearch = true,
            hasDateFilters,
            hasProjectFilter,
            hasBiztypeFilter,
            hasClientFilter,
            hasStatusFilter,
            hasCountryFilter,
            hasDeviationReporterFilters,
            statusOptions,
            clientOptions,
            hasCustomFilter,
            customFilterName,
            customFilterOptions,
            statusDisabled
        } = this.props;

        const { config } = this.props.commonStore;
        const { currentUser } = this.props.userStore;
        const { status, project, biztype, country, client_id, reported_by_id, assigned_id } = filters || {};
        const options = statusOptions?.map((status) => {
            return { value: `${status.code}`, label: t(status.name) };
        });

        options?.unshift({ value: '', label: t('All') });
        const currentSelect = find(options, (o) => {
            if (status) return o.value === status;
            else return o.value === '';
        });
        const { biztypes } = this.props.commonStore.config;
        const biztypeoptions = biztypes?.map((b) => {
            return { value: `${b.id}`, label: b.name };
        });
        biztypeoptions?.unshift({ value: '', label: t('All') });
        const currentSelectbiztype = find(biztypeoptions, (o) => {
            return o.value === biztype;
        });
        const currentCountrySelect = find(countryOptions, (o) => {
            return o.value === country;
        });

        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules
            ? config.client.data.dateTimeRules
            : false;
        let mdSpanSize =
            11 -
            (hasDateFilters ? 4 : 0) -
            (hasStatusFilter ? 2 : 0) -
            (hasProjectFilter ? 2 : 0) -
            (hasClientFilter ? 2 : 0) -
            (hasBiztypeFilter ? 2 : 0) -
            (hasCountryFilter ? 2 : 0) -
            (hasCustomFilter ? 2 : 0) -
            (hasDeviationReporterFilters ? 4 : 0);

        let mdFilterBtnOffset = 0;
        if(!hasSearch){
            mdFilterBtnOffset = mdSpanSize;
        }
        else if (mdSpanSize === 11) {
            mdFilterBtnOffset = 4;
            mdSpanSize -= 4;
        }

        return (
            <Row>
                {hasSearch &&
                    <Col
                        xs={{ span: 9, order: 2 }}
                        sm={{ span: 10, order: 2 }}
                        md={{ span: mdSpanSize, order: 1 }}
                        className={'mt-2'}
                    >
                        <div className="conversation-search">
                            <input
                                type="text"
                                className="conversation-search-input"
                                placeholder={this.props.t(searchFilterPlaceholder)}
                                onChange={(e) => setFilter(searchFilterName, e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onFilter();
                                    }
                                }}
                                value={filters[searchFilterName]}
                            />
                            <i className="fa fa-search conversation-search-icon" onClick={() => onFilter()} />
                        </div>
                    </Col>
                }
                {hasStatusFilter && (
                    <Col
                        xs={{ span: 12, order: 1 }}
                        sm={{ span: 6, order: 1 }}
                        md={{ span: 2, order: 1 }}
                        className={'mt-2'}
                    >
                        <Select
                            options={options}
                            value={currentSelect}
                            onChange={(id) => {
                                if (id?.value === currentSelect?.value) return
                                setFilter('status', id.value);
                                onFilter();
                            }}
                            placeholder={t('Select') + ' ' + t('Status')}
                            isDisabled={statusDisabled}
                        />
                    </Col>
                )}
                {hasCustomFilter && (
                    <Col
                        xs={{ span: 12, order: 1 }}
                        sm={{ span: 6, order: 1 }}
                        md={{ span: 2, order: 1 }}
                        className={'mt-2'}
                    >
                        <Select
                            options={customFilterOptions}
                            value={customFilterOptions.find(o => o.value === filters[customFilterName.value]) || ''}
                            onChange={(id) => {
                                if (id?.value === filters[customFilterName?.value]) return
                                setFilter(customFilterName.value, id.value);
                                onFilter();
                            }}
                        />
                    </Col>
                )}
                {hasProjectFilter && (
                    <Col
                        xs={{ span: 12, order: 1 }}
                        sm={{ span: 6, order: 1 }}
                        md={{ span: 2, order: 1 }}
                        className={'mt-2'}
                    >
                        <ProjectsWidget
                            allowAll={currentUser.user_type !== 'member'}
                            allLabel={t('All projects')}
                            value={project || ''}
                            onChange={(id) => {
                                if (id === project) return
                                setFilter('project', id)
                                onFilter();
                            }}
                        />
                    </Col>
                )}
                {hasClientFilter && (
                    <Col
                        xs={{ span: 12, order: 1 }}
                        sm={{ span: 6, order: 1 }}
                        md={{ span: 2, order: 1 }}
                        className={'mt-2'}
                    >
                        <Select
                            options={clientOptions}
                            value={client_id}
                            onChange={id => {
                                setFilter('client_id', id)
                                onFilter();
                            }}
                            placeholder={t('Select') + ' ' + t('Client')}
                        />
                    </Col>
                )}
                {hasBiztypeFilter && (
                    <Col
                        xs={{ span: 12, order: 1 }}
                        sm={{ span: 6, order: 1 }}
                        md={{ span: 2, order: 1 }}
                        className={'mt-2'}
                    >
                        <Select
                            options={biztypeoptions}
                            value={currentSelectbiztype}
                            disabled={loading}
                            onChange={(id) => {
                                setFilter('biztype', id.value);
                                onFilter();
                            }}
                            placeholder={t('Select') + ' ' + t('Biztype')}
                        />
                    </Col>
                )}
                {hasCountryFilter && (
                    <Col
                        xs={{ span: 12, order: 1 }}
                        sm={{ span: 6, order: 1 }}
                        md={{ span: 2, order: 1 }}
                        className={'mt-2'}
                    >
                        <Select
                            options={countryOptions}
                            onChange={(e) => {
                                setFilter('country', e.value);
                                onFilter();
                            }}
                            value={currentCountrySelect}
                            placeholder={t('Select') + ' ' + t('Country')}
                        />
                    </Col>
                )}
                {hasDeviationReporterFilters && (
                    <>
                        <Col
                            xs={{ span: 12, order: 1 }}
                            sm={{ span: 6, order: 1 }}
                            md={{ span: 2, order: 1 }}
                            className={'mt-2'}
                        >
                            <AllUsersWidget
                                emptyOption
                                emptyOptionLabel={t('Responsible')}
                                placeholder={t('Responsible')}
                                value={assigned_id}
                                onChange={value => setFilter('assigned_id', value)}
                            />
                        </Col>
                        <Col
                            xs={{ span: 12, order: 1 }}
                            sm={{ span: 6, order: 1 }}
                            md={{ span: 2, order: 1 }}
                            className={'mt-2'}
                        >
                            <AllUsersWidget
                                emptyOption
                                emptyOptionLabel={t('Reported by')}
                                placeholder={t('Reported by')}
                                value={reported_by_id}
                                onChange={value => setFilter('reported_by_id', value)}
                            />
                        </Col>
                    </>
                )}
                {hasDateFilters && (
                    <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 1 }} className={'mt-2'}>
                        <Row>
                            <Col xs={12} sm={6}>
                                <DatePicker
                                    selected={this.props.filters.fromDate1}
                                    onChange={(date) => this.setFilterDate('fromDate', 'fromDate1', date)}
                                    dateFormat={getShortDateFormat(dateTimeRules)}
                                    placeholder={t('From')}
                                    isClearable={true}
                                />
                            </Col>
                            <Col xs={12} sm={6} className="mt-sm-0 mt-2">
                                <DatePicker
                                    selected={this.props.filters.toDate1}
                                    onChange={(date) => this.setFilterDate('toDate', 'toDate1', date)}
                                    dateFormat={getShortDateFormat(dateTimeRules)}
                                    placeholder={t('To')}
                                    isClearable={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col
                    xs={{ span: 3, order: 3 }}
                    sm={{ span: 2, order: 3 }}
                    md={{ span: 1, order: 1, offset: mdFilterBtnOffset }}
                    className={'mt-2 gx-0 text-center'}
                >
                    <Button icon fill onClick={() => onFilter()}>
                        <img src={settingSlider} alt={'filter-btn'} style={{ height: '1rem' }} />
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default inject('commonStore', 'userStore')(withLocalization(observer(GenericFilter)));
