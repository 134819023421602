const ShowModalOnReadMore = ({data, charLength, handleModel, id, t}) => {
    const rawString = data?.map(ele => ele.name).join(',');
    const trim = rawString?.substring(0, charLength)
    if (rawString?.length < 60) {
        return (
            <div>{trim}</div>
        )
    }
    return (
        <div>{trim}...<span className='text-primary cursor-pointer' onClick={() => handleModel(id)}>{t('See More')}</span></div>
    )
}
export default ShowModalOnReadMore;