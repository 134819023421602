import React from "react";
import { inject } from "mobx-react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

// Components
import withLocalization from "../../../hoc/withLocalization";
import applicationRouter from "../../../hoc/applicationRouter";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { TasksWidget } from "../../../components/GenericForm/Widgets/TasksWidget";
import { ProjectsWidget } from "../../../components/GenericForm/Widgets/ProjectsWidget";

//utils
import { longDateFormat, shortDateFormat } from "../../../utils/dateFormat";

//elements
import { NumberInput } from "../../../elements/Input";
import Button from '../../../components/CustomButton/CustomButton.jsx';
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox.jsx";

class ServiceRequestExtendForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                project_id: '',
                task_id: '',
                no_of_member: '',
                originalToDate: ''
            },
            getAllShifts: [],
            validateShifts: [],
            getDatesToCheckShifts: [],
            confirmOverwrite: false
        }
    }
    
    async loadData() {
        const { userStore, serviceRequestStore } = this.props;
        const { currentUser } = userStore;
        let getId = this.props.update_id || null;
        if (getId) {
            const getData = await serviceRequestStore.load(getId, currentUser?.user_type, true);
            const { alreadyExistShifts, serviceRequest, datesTocheck} = getData;
            this.setState(_ => ({ data : { ...serviceRequest, originalToDate: serviceRequest.to_date}, getAllShifts: alreadyExistShifts, getDatesToCheckShifts: datesTocheck }));
        }
    }

    componentDidMount() {
        this.loadData();
    }

    handleDateChange = (type, date) => {
        const { commonStore, t } = this.props;
        const { data, getAllShifts, getDatesToCheckShifts } = this.state;
        if (moment(data.from_date).isAfter(date) || moment(data.originalToDate).isAfter(date))
            return commonStore.addNotification(t('From date should be before to date'), null, 'error');
        const filterDateWithSelectedDate = getDatesToCheckShifts.filter(ele => moment(ele).isSameOrBefore(moment(date)))
        const validate = filterDateWithSelectedDate.filter(ele => getAllShifts.some(item => item.for_date === ele));
        this.setState(prevState => ({ data: { ...prevState.data, to_date: date }, validateShifts: validate }))
    }

    handleSaveExtendRequestModal = async () => {
        const { serviceRequestStore, t, commonStore } = this.props;
        try {
            const { data, confirmOverwrite, validateShifts } = this.state;
            const savedServiceRequest = await serviceRequestStore.saveExtendRequest({
                to_date: data.to_date,
                id: this.props.update_id,
                confirmOverwrite: confirmOverwrite,
                overwriteShiftsForDates: validateShifts
            });
            commonStore.addNotification(t(savedServiceRequest.message), null, 'success');
            serviceRequestStore.resetLastListLoadTime();
            this.props.handleCloseExtendRequestModal();
        }
        catch (err) {
            commonStore.addNotification(t(err.message), null, 'error');
        }
    }

    render() {
        const {t, commonStore} = this.props;
        const { data, validateShifts, confirmOverwrite } = this.state;
        const { config } = commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        return (
            <>
                <div className='m-1'>
                    <div className='mt-3 fw-600'>
                        **{t('The actual request date is from')} {shortDateFormat(data.from_date, dateTimeRules)} {t('to')} {shortDateFormat(data.originalToDate, dateTimeRules)}.<br />
                        **{t('By updating `To Date` you can extend this request and new shifts will be assigned to previously selected members')}.
                    </div>
                    <Row className='mt-3 mb-3'>
                        <Col lg={4} sm={6} xs={12}>
                            <label className='fw-600'>{t('From Date')}</label>
                            <DatePicker
                                selected={data?.from_date && new Date(data.from_date)}
                                dateFormat={longDateFormat(data.from_date, dateTimeRules)}
                                disabled
                            />
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <label className='fw-600'>{t('To Date')}</label>
                            <DatePicker
                                selected={data?.to_date && new Date(data.to_date)}
                                onChange={(date) => this.handleDateChange('to_date', moment(date).format('YYYY-MM-DD'))}
                                dateFormat={longDateFormat(data.to_date, dateTimeRules)}
                                maxDate={new Date(moment(data.to_date).add(10, 'days'))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <label className='fw-600'>{t('Project')}</label>
                            <ProjectsWidget
                                label={`${t('Project')}`}
                                allLabel={t('All projects')}
                                value={data.project_id}
                                disabled
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <label className='fw-600'>{t('Service')}</label>
                            <TasksWidget
                                className="mt-2 pb-2"
                                value={data.task_id}
                                disabled
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <NumberInput
                                className={'mt-2 mb-25'}
                                label={`${t('Total Members Required')}`}
                                value={data.no_of_member}
                                disabled
                            />
                        </Col>
                    </Row>
                </div>
                {!!validateShifts?.length &&
                    <>
                        <div className='mt-3 fw-600 text-danger'>
                            **{t('Shifts of members already exists')}.
                        </div>
                        <CustomCheckbox
                            isChecked={confirmOverwrite}
                            onChange={(checked) => this.setState({ confirmOverwrite: checked })}
                            label={t('Are you sure, you want to overwrite them')+'?'}
                        />
                    </>
                }
                <div className='m-3 d-flex justify-content-end'>
                    <Button fill wd ms2 onClick={() => this.handleSaveExtendRequestModal()}
                        disabled={(data.to_date === data.originalToDate || (moment(data.from_date).isAfter(data.to_date) || moment(data.originalToDate).isAfter(data.to_date))) || (validateShifts?.length && !confirmOverwrite)}>
                        {t('Save')}
                    </Button>
                    <Button dynamic_lg ms2 onClick={() => this.props.handleCloseExtendRequestModal()} >
                        {t('Cancel')}
                    </Button>
                </div>
            </>
        )
    }
}

export default inject('serviceRequestStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(ServiceRequestExtendForm)))