import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//HOCs and components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import ScheduleManagementCard from './ScheduleManagementCard.js';
import Select from '../../../elements/Select.js';
import LoadingSpinner from '../../../elements/LoadingSpinner';

//utils
import { shortDateFormat } from '../../../utils/dateFormat';

const reqType = [
    { value: "all", title: "All", label: "All" },
    { value: "sick-leave", title: "Sick Leave", label: "Sick Leave", },
    { value: "trade-shift", title: "Swap", label: "Swap", },
    { value: "swap-shift", title: "Trade", label: "Trade" },
    { value: "grab-shift", title: "Grab", label: "Grab" },
]

const statusType = [
    { value: "all", title: "All", label: "All" },
    { value: "pending", title: "Pending", label: "Pending" },
    { value: "approved", title: "approved", label: "approved" },
    { value: "rejected", title: "rejected", label: "rejected" }
]

class ShiftSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleManagementData: [],
            isLoading: true,
            curReqType: reqType[0],
            curStatusType: statusType[0],
            page: 0,
            totalPages: 0
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const { commonStore, t } = this.props;
        try {
            const params = this.getParamsFromState();
            const res = await this.props.scheduleStore.getSwapShiftRequestsForPM(params);
            this.setState({ scheduleManagementData: res.rows, totalPages: parseInt(res.pages) },
                () => this.setState({ isLoading: false }));
        }
        catch (err) {
            commonStore.addNotification(t('Something went wrong'), null, 'error');
            console.error(err);
            this.setState({ isLoading: false })
        }
    }

    getParamsFromState = () => {
        const { curReqType, curStatusType, page } = this.state;
        const params = {
            pagesize: 10,
            page: page
        };
        if (curReqType.value !== 'all') params[curReqType.value.replace('-', '_')] = true;
        if (curStatusType.value !== 'all') params[curStatusType.value] = true;
        return params;
    }

    approveSwapShiftAcceptedRequest = (status, swap_id, shift_id, toUser, swap_type, from_user_id, swap_history_id) => {
        const { commonStore, t, scheduleStore } = this.props;
        this.setState({ isLoading: true });
        if (swap_type !== 'trade-shift') {
            scheduleStore.approveOrRejectSwapShiftRequestsFromPM({ swap_id: swap_id, status: status, shift_id: shift_id, toUser: toUser, swap_history_id: swap_history_id }).then(res => {
                this.loadData();
            }).catch(err => {
                console.error(err);
                commonStore.addNotification(t('Something went wrong'), null, 'error');
            });
        }
        else {
            scheduleStore.approveOrRejectTradeShiftRequestsFromPM({ swap_id: swap_id, status: status, from_shift_id: shift_id, toUser: toUser, from_user_id: from_user_id, swap_history_id: swap_history_id }).then(res => {
                this.loadData();
            }).catch(err => {
                console.error(err);
                commonStore.addNotification(t('Something went wrong'), null, 'error');
            })
        }
    }

    renderTableActionButtons = (full) => {
        const { t, userStore } = this.props;
        const { currentUser } = userStore;
        const isPM = currentUser.user_type === 'pm' || currentUser.user_type === 'admin';
        const { is_accepted: isAccepted, is_declined: isDeclined, is_rejected: isRejected, is_approved: isApproved } = full;
        const isSwapTradeAcceptedAndReadyForPMApproval = isPM && isAccepted && !isRejected && !isApproved
            && (full.swap_status === 'pending-swap' || full.swap_status === 'swap-accepted');
        return (
            <>
                {isSwapTradeAcceptedAndReadyForPMApproval &&
                    <>
                        <Button btn_reject nb
                            onClick={() => this.approveSwapShiftAcceptedRequest('rejected', full.swap_id, full.shift_id, full.accepted_id, full.swap_type, full.from_user_id, full.swap_history_id)}>{t('Reject')}</Button>
                        <Button fill nb
                            onClick={() => this.approveSwapShiftAcceptedRequest('approved', full.swap_id, full.shift_id, full.accepted_id, full.swap_type, full.from_user_id, full.swap_history_id)}>{t('Approve')}</Button>
                    </>
                }
                {(full.swap_status === 'pending-swap' || full.swap_status === 'swap-accepted') && isPM && !isAccepted && !isDeclined &&
                    <Button fill nb style={{ background: '#FFC800' }}>
                        <i className="fa fa-clock" /> {t('Awaiting')}
                    </Button>
                }
            </>
        );
    };

    onRequestTypeChange = (evt, type) => {
        this.setState({ isLoading: true, [type]: evt, page: 0 }, this.loadData);
    }

    handlePageChange(isNext) {
        this.setState(prevState => ({ page: isNext ? prevState.page + 1 : prevState.page - 1 }), this.loadData)
    }

    translateLabels = () => {
        const { t } = this.props;
        reqType.map(item => { item.label = t(item.title) })
        statusType.map(item => { item.label = t(item.title) })
    }

    render() {

        const { t, commonStore } = this.props;
        const { isLoading, curReqType, curStatusType, scheduleManagementData, page, totalPages } = this.state;
        if (isLoading) return <LoadingSpinner />;

        this.translateLabels();

        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ?
            commonStore.config.client.data.dateTimeRules : false;

        const overtimeCalcPeriod = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.basicRules
            && commonStore.config.client.data.basicRules.overtimeCalcRules && commonStore.config.client.data.basicRules.overtimeCalcRules.calc_period ?
            commonStore.config.client.data.basicRules.overtimeCalcRules.calc_period : "";

        return (
            <>
                <div className="d-flex justify-content-space-between align-items-center">
                    <div className='d-flex'>
                        <div>
                            <label>{t('Status')}</label>
                            <Select
                                options={statusType}
                                value={curStatusType}
                                onChange={event => this.onRequestTypeChange(event, "curStatusType")}
                                style={{ minWidth: '150px' }}
                            />
                        </div>
                        <div className='ml-10'>
                            <label>{t('Type')}</label>
                            <Select
                                options={reqType}
                                value={curReqType}
                                onChange={event => this.onRequestTypeChange(event, "curReqType")}
                                style={{ minWidth: '150px' }}
                            />
                        </div>
                    </div>
                    <div>
                        <Button wd
                            disabled={!page}
                            onClick={() => this.handlePageChange(false)}
                        >
                            <i class="fa fa-arrow-left me-1" aria-hidden="true"></i>
                            {t('Prev')}
                        </Button>
                        <Button fill wd
                            disabled={!totalPages || page === (totalPages - 1)}
                            style={{ margin: "0 1em" }}
                            onClick={() => this.handlePageChange(true)}
                        >
                            {t('Next')}
                            <i class="fa fa-arrow-right ms-1" aria-hidden="true"></i>
                        </Button>
                    </div>
                </div>
                {scheduleManagementData.length < 1 ? (
                    <div className="d-flex justify-content-center no-data-tag">
                        {t('No data found')}
                    </div>
                ) :
                    <>
                        {scheduleManagementData.map((data, idx) => {
                            return (
                                <div key={`swap_list${idx}`}>
                                    {idx > 0 ? scheduleManagementData[idx - 1].shift_date !== data.shift_date ?
                                        <div className='shftListTag'>{t("Date")} {shortDateFormat(data.shift_date, dateTimeRules)}</div>
                                        : '' :
                                        <div className='shftListTag'>{t("Date")} {shortDateFormat(data.shift_date, dateTimeRules)}</div>

                                    }
                                    <ScheduleManagementCard
                                        key={data.swap_id}
                                        renderTableActionButtons={this.renderTableActionButtons}
                                        scheduleData={data}
                                        dateTimeRules={dateTimeRules}
                                        t={t}
                                        overtimeCalcPeriod={overtimeCalcPeriod}
                                    />
                                </div>
                            )
                        })
                        }
                    </>}
            </>

        );
    }
}

export default inject('commonStore', 'scheduleStore', 'userStore')(applicationRouter(withLocalization(observer(ShiftSettings))));
