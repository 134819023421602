import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ChecklistAssign from './ChecklistAssign';
import GenericList from '~/components/GenericList/GenericList';
import ChecklistCheck from './ChecklistCheck';
import ChecklistAccept from './ChecklistAccept';
import ChecklistTableAd from './ChecklistTableAd';
import GenericFilter from '../../../components/GenericList/GenericFilter';

//services
import { serverTimeFormat } from '~/library/core';
import config from '~/library/config';
import agent from '~/library/agent';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';
import { statusMap } from '~/utils/normalStatuses';
import { checklist_status } from '../../../utils/normalStatuses';

//assets
import editIcon from '../../../assets/img/editing.svg';
import deleteIcon from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg'

//styles
import '../../../styles/css/styles.css';
class ChecklistTable extends Component {
    state = {
        add: false,
        currentChecklistId: 0,
        showChecklistTodo: false,
        showChecklistAccept: false,
        currentChecklist: {},
        isSchedule: false,
    };

    componentWillMount() {
        const params = new URLSearchParams(this.props.router.location.search);
        const filterByProject = params.get('project');
        this.props.checklistStore.setFilter('project', filterByProject || null);
        this.props.checklistStore.setFilter('name', '');
        this.props.checklistStore.setFilter('status', '');
        this.props.checklistStore.onFilter();
    }

    renderTableActionButtons = (id, full) => {
        const { user_type } = this.props.userStore.currentUser;
        const { t } = this.props;
        const actionable = full.status !== 'approved';
        let statusText = full.status;
        if (statusText !== 'Approved') statusText = 'active';
        statusText = statusMap[statusText] || '';

        return (
            <div className="actions-center">
                <div
                    className={`status-block status-${full.status === 'Approved' ? 'approved' : 'active'}${actionable ? ' status-actionable' : ''
                        }${user_type === 'member' ? ' status-no-click-for-member' : ''}`}
                    onClick={() =>
                        user_type === 'pm' ||
                            (user_type === 'admin' && (full.status === 'Done' || full.status === 'Approved'))
                            ? this.viewChecklistAccept(full)
                            : null
                    }
                >

                    {statusText.toLowerCase() === 'pending' || !(full.status_changed_by && full.status_changed_on) ?
                        <div className='text-capitalize'>{t(statusText)}</div>
                        :
                        (
                            <>
                                <div className="text-capitalize">
                                    {full.status_changed_by &&
                                        moment(new Date(full.status_changed_on)).format(serverTimeFormat(true))}
                                </div>
                                <div className="text-capitalize">
                                    {this.props.commonStore.getUsername(full.status_changed_by)}
                                </div>
                            </>
                        )}
                </div>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{t('Do Checklist')}</Tooltip>}>
                    <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'todo')}>
                        <i className="fa fa-thumbs-up actionblueicon" />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{t('Quick Report')}</Tooltip>}>
                    <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'quickreport')}>
                        <i className="far fa-file-alt actionblueicon" />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{t('Full Report')}</Tooltip>}>
                    <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'fullreport')}>
                        <i className="fa fa-file-alt actionblueicon" />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{t(full.status !== 'New' ? 'Cannot update checklist once started' : 'Update checklist')}</Tooltip>}>
                    <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')} disabled={full.status !== 'New'}>
                        <img src={editIcon} alt={'edit button'} />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{t('Remove Checklist')}</Tooltip>}>
                    <Button icon_sm_delete fill onClick={() => this.handleTableButtonAction(id, 'remove')} >
                        <img src={deleteIcon} alt="delete button" />
                    </Button>
                </OverlayTrigger>
            </div>
        );
    };

    viewChecklistAssign = () => {
        this.setState({ add: true, currentChecklistId: 0 });
        this.renderChecklistAssignModal();
    };

    viewChecklistAssignSchedule = () => {
        this.setState({ isSchedule: true });
    };

    renderChecklistAssignModal = () => {
        this.setState({
            showChecklistAssign: true,
        });
    };

    handleClosesChecklisAssignModal = () => {
        this.setState({ showChecklistAssign: false, add: false });
        this.props.checklistStore.returnDefaultNew();
    };

    // Accept modal
    viewChecklistAccept = (checklist) => {
        this.setState({ currentChecklist: checklist });
        this.renderChecklistAcceptModal();
    };

    renderChecklistAcceptModal = () => {
        this.setState({
            showChecklistAccept: true,
        });
    };

    handleClosesChecklisAcceptModal = () => {
        this.setState({ showChecklistAccept: false });
    };

    updateCurrentChecklistComment = (data) => {
        this.setState({
            currentChecklist: {
                ...this.state.currentChecklist,
                status_note: data,
            },
        });
    };

    acceptChecklist = (data) => {
        const { t, commonStore, checklistStore } = this.props;
        let hydrated = {
            id: this.state.currentChecklist.id,
            status_note: this.state.currentChecklist.status_note,
        };
        if (hydrated.id) {
            checklistStore
                .approveChecklist(hydrated)
                .then((res) => {
                    // this.props.checklistStore.resetLastListLoadTime();
                    commonStore.addNotification(t('Checklist Approve sucessfully.'), null, 'success');
                    this.handleClosesChecklisAcceptModal();
                })
                .catch((err) => {
                    commonStore.addNotification(err.message || t('Please fill all the fields'), null, 'warning');
                });
        } else {
            commonStore.addNotification(t('Error'), null, 'error');
        }
    };

    // handle action button
    handleTableButtonAction = (id, type) => {
        if (type === 'edit') {
            this.setState({ currentChecklistId: id, add: false });
            this.renderChecklistAssignModal();
        } else if (type === 'todo') {
            this.setState({ currentChecklistId: id, add: false });
            this.renderChecklistTodoModal();
        } else if (type === 'remove') {
            this.removeChecklist(id);
        } else if (type === 'quickreport') {
            this.getChecklistReport(id, 'quickreport');
        } else if (type === 'fullreport') {
            this.getChecklistReport(id, 'fullreport');
        }
    };

    removeChecklist(id) {
        return this.props.handleDelete(id, (id) => {
            return this.props.checklistStore.checklistDelete(id).then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                return res;
            });
        });
    }

    getChecklistReport(id, type) {
        let hydrated = {
            id: id,
            authorization: this.props.commonStore.token,
        };
        window.open(`${config.API_ROOT}/userchecklists/report/${type}${agent.convertToGetParams(hydrated)}`);
    }

    //TODO
    renderChecklistTodoModal = () => {
        this.setState({
            showChecklistTodo: true,
        });
    };

    handleClosesChecklisTodoModal = () => {
        this.setState({ showChecklistTodo: false });
    };

    applyForm = () => {
        const { currentChecklist: checklist } = this.props.checklistStore;
        const { t, commonStore, checklistStore } = this.props;

        if (
            checklist.project_id == null ||
            checklist.project_id === '' ||
            checklist.checklist_templates_id == null ||
            checklist.checklist_templates_id === ''
        ) {
            commonStore.addNotification(t('Please fill all required fields'), null, 'error');
            return;
        }

        if (
            (checklist.cornjob_type === 'Gap Between Days' &&
                (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === '')) ||
            (checklist.cornjob_type === 'Weekdays' &&
                (checklist.cornjob_duration === '' || !isNaN(checklist.cornjob_duration))) ||
            (checklist.cornjob_type === 'Monthly' &&
                (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === '')) ||
            (checklist.cornjob_type === 'Half Yearly' &&
                (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === '')) ||
            (checklist.cornjob_type === 'Annually' &&
                (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ''))
        ) {
            commonStore.addNotification(t('Please fill all required fields'), null, 'error');
            return;
        }
        let hydrated = {
            user_id: checklist.user_id,
            project_id: checklist.project_id,
            checklist_templates_id: checklist.checklist_templates_id,
            isSendEmail: checklist.isSendEmail,
            cornjob_type: checklist.cornjob_type,
            cornjob_duration: checklist.cornjob_duration,
            isobligatory: checklist.isobligatory,
            allow_check_all: checklist.allow_check_all,
            next_day: checklist.cornjob_type === 'Date Of Month' ? checklist.date_of_month : null
        };
        if (checklist.id && checklist.id > 0) {
            hydrated['id'] = checklist.id;
        }

        checklistStore
            .assignChecklist(hydrated)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(
                    t(
                        res.isIdAvailable === true
                            ? 'Checklist Updated Successfully.'
                            : 'Checklist Assigned Successfully.'
                    ),
                    null,
                    'success'
                );
                this.handleClosesChecklisAssignModal();
            })
            .catch((err) => {
                console.error({ err });
                commonStore.addNotification(t(err?.response?.body?.error || err.message || 'Please fill all required fields'), null, 'error');
            });
    };

    reRenderForm = (id) => {
        this.handleClosesChecklisTodoModal();
        setTimeout(
            function () {
                this.handleTableButtonAction(id, 'todo');
            }.bind(this),
            300
        );
    };

    setStatusColor = (status) => {
        let color = '';
        switch (status) {
            case 'Done':
                color = 'green';
                break;

            case 'Approved':
                color = 'green';
                break;

            case 'Deviation':
                color = 'red';
                break;

            case 'Under work':
                color = '#ff8c00';
                break;

            default:
                color = 'blue';
        }
        return color;
    };

    render() {
        const { t } = this.props;
        const { loading, filters, appliedFilters } = this.props.checklistStore;
        const { config } = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        return (
            <>
                {this.state.isSchedule && <ChecklistTableAd />}
                {!this.state.isSchedule && (
                    <div className="table-list">
                        <div className="table-list__header mt-2">
                            {this.props.userStore.currentUser.user_type !== 'member' && (
                                <Button fill wd onClick={() => this.viewChecklistAssignSchedule()}>
                                    {t('Show only Scheduled')}
                                </Button>
                            )}
                            <Button fill wd onClick={() => this.viewChecklistAssign()}>
                                {t('Do Checklists')}
                            </Button>
                        </div>
                        <GenericList
                            columns={[
                                {
                                    Header: 'Id',
                                    id: 'id',
                                    accessor: 'id',
                                    minWidth: 20,
                                    maxWidth: 30,
                                },
                                {
                                    Header: 'Name',
                                    id: 'name',
                                    accessor: ({ id, name }) => (
                                        <div
                                            className="list-main-column"
                                            onClick={() => this.handleTableButtonAction(id, 'edit')}
                                        >
                                            {`${name}`}
                                        </div>
                                    ),
                                    isSortable: true,
                                    maxWidth: 200,
                                    minWidth: 80,
                                    textAlign: 'start'
                                },
                                {
                                    Header: 'User',
                                    id: 'user_name',
                                    accessor: ({ first_name, last_name, to_project }) =>
                                        to_project === false ? (
                                            <span>{`${first_name} ${last_name}`}</span>
                                        ) : (
                                            'To Project'
                                        ),
                                    maxWidth: 130,
                                    minWidth: 80,
                                },
                                {
                                    Header: 'Project',
                                    id: 'project_name',
                                    accessor: ({ project_name }) => <span>{project_name}</span>,
                                    maxWidth: 130,
                                    minWidth: 60,
                                },
                                {
                                    Header: 'Assigned by',
                                    id: 'created_by_first_name',
                                    accessor: ({ created_by_first_name, created_by_last_name }) => (
                                        <span>{`${created_by_first_name} ${created_by_last_name}`}</span>
                                    ),
                                    maxWidth: 120,
                                    minWidth: 70,
                                },
                                {
                                    Header: 'Status',
                                    id: 'status',
                                    accessor: ({ status }) => (
                                        <span
                                            style={{
                                                color: this.setStatusColor(status),
                                            }}
                                        >
                                            {t(status)}
                                        </span>
                                    ),
                                    isSortable: true,
                                    maxWidth: 90,
                                    minWidth: 70,
                                },
                                {
                                    Header: 'Last Updated',
                                    id: 'updated_at',
                                    accessor: ({ updated_at }) => (
                                        <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                                    ),
                                    isSortable: true,
                                    maxWidth: 80,
                                    minWidth: 60,
                                },
                                {
                                    Header: 'Actions',
                                    id: 'actions',
                                    accessor: (full) => this.renderTableActionButtons(full.id, full),
                                    maxWidth: 300,
                                    minWidth: 260,
                                },
                            ]}
                            forceReload={
                                this.state.checklistChanged === true ||
                                this.props.checklistStore.deleteSuccess ||
                                this.props.checklistStore.addOrUpdated
                            }
                            filters={appliedFilters}
                            lastListLoadTime={this.props.checklistStore.lastListLoadTime}
                            header={
                                <GenericFilter
                                    filters={filters}
                                    setFilter={(name, value) => this.props.checklistStore.setFilter(name, value)}
                                    onFilter={() => this.props.checklistStore.onFilter()}
                                    loading={loading}
                                    hasProjectFilter={true}
                                    hasStatusFilter={true}
                                    statusOptions={checklist_status}
                                />
                            }
                            requestData={(params) => this.props.checklistStore.getChecklists(params)}
                        />
                    </div>
                )}
                <Modal
                    className={`${"modal right fade modal_revised"}`}
                    show={this.state.showChecklistAssign}
                    onHide={this.handleClosesChecklisAssignModal}
                >
                    <Modal.Body>
                        <div className='d-flex justify-content-between mt-2 mb-3'>
                            <div className="modal-body-custom__header">{t('Do Checklist')}</div>
                            <div className='d-flex'>
                                <div className='me-3'>
                                    <Button variant="secondary" onClick={this.applyForm} className="btn-wd btn-fill">
                                        {t('Save')}
                                    </Button>
                                </div>
                                <div className='me-3'>
                                    <Button variant="secondary" onClick={this.handleClosesChecklisAssignModal} className="btn-wd">
                                        {t('Close')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <ChecklistAssign
                            add={this.state.add}
                            id={this.state.currentChecklistId}
                            handleClosesChecklisAssignModal={this.handleClosesChecklisAssignModal}
                        />
                    </Modal.Body>
                </Modal>
                <Modal
                    className="modal right fade"
                    show={this.state.showChecklistTodo}
                    onHide={this.handleClosesChecklisTodoModal}
                >
                    <Modal.Header>
                        <Modal.Title>{t('Do Checklists')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleClosesChecklisTodoModal}
                            alt='close'
                        />
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: '500px', overflowY: 'auto' }}>
                        <ChecklistCheck id={this.state.currentChecklistId} reRenderForm={this.reRenderForm} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClosesChecklisTodoModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="modal fade"
                    show={this.state.showChecklistAccept}
                    onHide={this.handleClosesChecklisAcceptModal}
                >
                    <Modal.Header>
                        <Modal.Title>{t('Approve')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleClosesChecklisAcceptModal}
                            alt='close'
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <ChecklistAccept
                            checklist={this.state.currentChecklist}
                            updateCurrentChecklistComment={this.updateCurrentChecklistComment}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.currentChecklist && this.state.currentChecklist.status !== 'Approved' && (
                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    fill
                                    wd
                                    icon
                                    type="submit"
                                    style={{ backgroundColor: 'green', marginRight: 30, border: 0 }}
                                    onClick={() => this.acceptChecklist()}
                                >
                                    {' '}
                                    <i className="fa fa-check" /> {t('Accept')}
                                </Button>
                            </div>
                        )}
                        <Button variant="secondary" onClick={this.handleClosesChecklisAcceptModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default inject(
    'checklistStore',
    'userStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(ChecklistTable)))));
