import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import Switch from 'react-switch';
import { getDay } from 'date-fns';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import TimePickerInput from '../../../components/TimePicker';
import CheckBoxWidget from '../../../components/GenericForm/Widgets/CheckBoxWidget';
import DatePicker from '../../../components/DatePicker/DatePicker';

//elements
import Select from '../../../elements/Select';
import RequiredStar from '../../../elements/RequiredStar';
import { Textarea } from '../../../elements/Input';

//utils
import { longDateFormat } from '../../../utils/dateFormat';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { checkIfCurrentDaysIsWeekendAsPerExtraPaymentSettings } from '../../../utils/commonMethods';

import '../../../styles/css/styles.css';

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const requestOptions = [{ label: 'Weekly', title: 'Weekly', value: 'weekly' }, { label: 'Repeating', title: 'Repeating', value: 'repeating' }]

class ScheduleAddAvailability extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from: moment(new Date()).startOf('month').toDate(),
            to: moment(new Date()).toDate(),
            from_date: new Date(),
            to_date: new Date(),
            dates: [],
            data: this.props.selectedRowData ? this.props.selectedRowData : {
                "client_id": null,
                "user_id": null,
                "availability": [],
                "from_date": "", "to_date": "",
                "request_type": 'weekly'
            },
        }
    }

    onDaysSelectionChange(index, value) {
        let newData = this.state.data;
        switch (value) {
            case true:
                delete newData.availability[index].from;
                delete newData.availability[index].to;
                break;
            case false:
                newData.availability[index].from = 0;
                newData.availability[index].to = 0;
                break;
            default:
                break;
        }
        this.setState({ data: newData });
        this.props.setData(newData);
    }

    onAvailabilitySelectionChange(checked, index) {
        let newData = this.state.data;
        if (checked) {
            newData.availability[index].availability_type = 'available';
        } else {
            newData.availability[index].availability_type = 'not available';
        }
        this.setState({ data: newData });
        this.props.setData(newData);
    }

    handleWeekDateChange = (name, value) => {
        let newData = this.state.data;
        newData[name] = value;
        this.setState({ data: newData });
        this.props.setData(newData);
        if (newData.from_date && newData.to_date)
            this.getDatesInRange(newData.from_date, newData.to_date);
    };

    setInputValues = (index, name, value) => {
        let newData = this.state.data;
        newData.availability[index][name] = value;
        if (newData.availability[index]['from']) {
            newData.availability[index]['isToTimePicker'] = true;
        }
        this.setState({ data: newData });
        this.props.setData(newData);
    };

    disableWeekends = current => {
        return current.day() === 0 && current.day() === 6;
    }

    getDatesInRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        let newData = this.state.data;
        newData.availability = [];
        dates.map(date => {
            let temp = date;
            newData.availability.push({ for_date: temp, availability_type: 'available' });
        });
        this.setState({ data: newData, dates: dates });
        this.props.setData(newData);
    }

    getDayWorkingDayCircle = (date) => {
        const { config } = this.props.commonStore;
        const { extra_payments } = this.props.userStore.currentUser;
        const clientExtraPaymentRules = config?.client?.data?.extraPayments;
        const isWeekendDay = checkIfCurrentDaysIsWeekendAsPerExtraPaymentSettings(date.for_date, extra_payments, clientExtraPaymentRules);
        return (
            <span className={`workingDayCircle me-2 ${isWeekendDay ? 'weekend' : ''}`}></span>
        )
    }

    getDay = (date) => {
        return days[new Date(date.for_date).getDay()];
    }

    handleRequestTypeChange = (name, value) => {
        this.setState(prevState => {
            const newData = { ...prevState.data, [name]: value };
            this.props.setData(newData);
            return { data: newData };
        });
    };

    translateLabels = () => {
        const { t } = this.props;
        requestOptions.map(item => {
            item.label = t(item.title)
        });
    }

    render() {
        const { data } = this.state;
        const { t, commonStore } = this.props;
        const { config } = commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        this.translateLabels();
        return (
            <div className='availability-card'>
                <div className='avlbty-info'>
                    <div className='d-flex'>
                        <Select
                            value={requestOptions.find(e => e.value === data.request_type)}
                            options={requestOptions}
                            name="request_type"
                            onChange={(selected) => { this.handleRequestTypeChange('request_type', selected.value) }}
                        />
                    </div>
                    <div className='d-flex'>
                        <DatePicker
                            isValidDate={this.disableWeekends}
                            placeholder={t("From Week")}
                            selected={data.from_date && new Date(data.from_date)}
                            onChange={(date) => this.handleWeekDateChange('from_date', date)}
                            dateFormat={longDateFormat(dateTimeRules)}
                            filterDate={date => getDay(date) === 1}
                            maxDate={data.to_date && new Date(data.to_date)}
                        />
                        <RequiredStar />
                    </div>
                    <div className='d-flex'>
                        <DatePicker
                            isValidDate={this.disableWeekends}
                            placeholder={t("To Week")}
                            selected={data.to_date && new Date(data.to_date)}
                            onChange={(date) => this.handleWeekDateChange('to_date', date)}
                            dateFormat={longDateFormat(dateTimeRules)}
                            filterDate={date => getDay(date) === 0}
                            minDate={data.from_date && new Date(data.from_date)}
                        />
                        <RequiredStar />
                    </div>
                </div>
                {data.availability.map((date, index) => {
                    return (
                        <div className='availability-days row-flex' style={{ color: '#3abda9' }} key={index}>
                            {date && <div className='col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex align-items-center'>
                                {this.getDayWorkingDayCircle(date)}
                                <span className='title'>{t(this.getDay(date))}
                                </span>
                            </div>}
                            <div className='col-lg-2 col-md-2 col-sm-12 col-xs-12'>
                                <span className='title'>
                                    <CheckBoxWidget
                                        value={data.availability[index].from != undefined && data.availability[index].from >= 0 ? false : true}
                                        onChange={(value) => this.onDaysSelectionChange(index, value)}
                                        label={t("All day")} />
                                </span>
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                <label>
                                    <span className='switchLbl'>{t('Available')}</span>
                                    <Switch onChange={(checked) => this.onAvailabilitySelectionChange(checked, index)}
                                        height={32} handleDiameter={24}
                                        uncheckedIcon={<div className='switchTxt'>Off</div>}
                                        checkedIcon={<div className='switchTxt'>On</div>}
                                        onColor='#9EE985' offColor='#E2EBDF'
                                        checked={data.availability[index].availability_type === 'available' ? true : false}

                                    />
                                </label>
                            </div>
                            <div className='col-lg-5 col-md-5 col-sm-12 col-xs-12'>
                                <div className='title'>
                                    <Textarea
                                        rows={1}
                                        placeholder={t('Comments')}

                                        onChange={(e) => this.setInputValues(index, 'comment', e.target.value)}
                                        value={data.availability[index].comment && data.availability[index].comment}
                                    />
                                    {data.availability[index] && data.availability[index].from != undefined && data.availability[index].from >= 0 &&
                                        data.availability[index].to != undefined && data.availability[index].to >= 0 &&
                                        <span className='row-flex mt-10'>
                                            <div className='pe-3'>
                                                <TimePickerInput
                                                    value={data.availability[index].from ? minutesToTimeInput(data.availability[index].from, false) : `00:00`}
                                                    format={format == "hh:mm" ? "HH:mm" : format}
                                                    onChange={(value) => this.setInputValues(index, 'from', getMinutesFromTimeInput(value))}
                                                />
                                            </div>

                                            {data.availability[index]['isToTimePicker'] ?
                                                <TimePickerInput
                                                    value={data.availability[index].to ? minutesToTimeInput(data.availability[index].to, false) : `00:00`}
                                                    format={format == "hh:mm" ? "HH:mm" : format}
                                                    onChange={(value) => this.setInputValues(index, 'to', getMinutesFromTimeInput(value))}
                                                />
                                                :
                                                <TimePickerInput
                                                    value={data.availability[index].to ? minutesToTimeInput(data.availability[index].to, false) : `00:00`}
                                                    format={format == "hh:mm" ? "HH:mm" : format}
                                                    onChange={(value) => this.setInputValues(index, 'to', getMinutesFromTimeInput(value))}
                                                    disabled
                                                />
                                            }
                                        </span>}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default inject('commonStore', 'userStore')(applicationRouter(withLocalization(withRemoveDialog(observer(ScheduleAddAvailability)))));
