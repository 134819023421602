import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import CustomRadio from '../../../../components/CustomRadio/CustomRadio';
import TableWidget from '../TableWidget.js';
import SwitchWidget from '../SwitchWidget';

export class TipRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tip_allocation: [{
                label: "Allow tip only to selected members under members profile.",
                value: "selected"
            },
            {
                label: "Allow tip to all members and divide equally.",
                value: "equally"
            },
            {
                label: "Allow tips to all members according to given percent(%) under members profile",
                value: "percentage"
            }],
        }
    }
    handleUserDataChange = (name, value) => {
        const { currentEntity } = this.props.clientStore;
        const tipRules = currentEntity.client.data.tipRules ? currentEntity.client.data.tipRules : {};
        currentEntity.client.data.tipRules = { ...tipRules, profiles: value }
    }

    onChange(field, value) {
        const { clientStore } = this.props
        let tipRules = clientStore.currentEntity.client.data && clientStore.currentEntity.client.data.tipRules ? clientStore.currentEntity.client.data.tipRules : {};
        clientStore.currentEntity.client.data.tipRules = { ...tipRules, [field]: value }
    }
    handleSwitch(name) {
        const { clientStore } = this.props
        let tipRules = clientStore.currentEntity.client.data && clientStore.currentEntity.client.data.tipRules ? clientStore.currentEntity.client.data.tipRules : {};
        clientStore.currentEntity.client.data.tipRules = { ...tipRules, tipEditor: name }
    }
    render() {
        const { t, clientStore } = this.props;
        const tipRules = clientStore.currentEntity.client && clientStore.currentEntity.client.data && clientStore.currentEntity.client.data.tipRules ? clientStore.currentEntity.client.data.tipRules : {};
        const profiles = JSON.parse(JSON.stringify(tipRules.profiles ? tipRules.profiles : []));
        const roles = JSON.parse(JSON.stringify(clientStore.currentEntity.client && clientStore.currentEntity.client.data && clientStore.currentEntity.client.data.roles ? clientStore.currentEntity.client.data.roles : {}));
        let tempProfiles = [...profiles];
        roles.forEach(ele => {
            // Adding new employee_type to Profile when its not available in Profiles//
            if (!profiles.some(role => role.employee_type === ele.code)) {
                tempProfiles.push({
                    "employee_type": ele.code,
                    "percentage": 0
                })
            }
        })

        // removing employee_type from Profile when its not available in roles //
        profiles.forEach(ele => {
            if (!roles.some(role => role.code === ele.employee_type)) {
                const filteredArr = tempProfiles.filter(element => element.employee_type !== ele.employee_type)
                tempProfiles = [...filteredArr]
            }
        })
        const _value = tipRules.tipAllocation ? tipRules.tipAllocation : 'selected';
        const editor_value = tipRules.tipEditor ? tipRules.tipEditor : 'pm';
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div id="editor_radio">
                            <Row style={{ marginTop: '2rem' }}>
                                <Col sm={1}>
                                    <SwitchWidget
                                        value={editor_value === "pm" ? true : false}
                                        onChange={checked => checked ? this.handleSwitch('pm', checked) : ''} />
                                </Col>
                                <Col sm={10}>
                                    <label className="control-label">{t('Allow only PM to enter Tip amount into the system')}</label>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Allow only PM to enter Tip amount into the system")}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '2rem', marginBottom: '3rem' }}>
                                <Col sm={1}>
                                    <SwitchWidget
                                        value={editor_value === "member" ? true : false}
                                        onChange={checked => checked ? this.handleSwitch('member', checked) : ''} />
                                </Col>
                                <Col sm={10}>
                                    <label className="control-label">{t('Allow members to enter tip amount into the system')}</label>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Allow members to enter tip amount into the system")}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </div>
                        <div id="allocation_radio">
                            <label className="control-label">{t('Tip allocation rule')}{":"}</label>
                            {this.state.tip_allocation.map(({ label, value }, index) =>
                                <Row key={index}>
                                    <Col xs={12}>
                                        <CustomRadio
                                            name={"allocation"}
                                            key={label}
                                            onChange={() => this.onChange("tipAllocation", value)}
                                            checked={value === _value}
                                            option={label}
                                            label={t(label)}
                                        >
                                        </CustomRadio>
                                    </Col>
                                </Row>
                            )}
                        </div>
                        {<div className="mt-2 tip-custom-card">
                            <h6>{t('Profile Status')}</h6>
                            <TableWidget
                                headers={['Titles', 'Share(%)']}
                                metaData={[{ field: 'employee_type', type: 'text', placeholder: 'type', add: false },
                                { field: 'percentage', type: 'number', placeholder: '%', required: true }]}
                                data={tempProfiles}
                                page={"tip"}
                                onChange={data => this.handleUserDataChange('tipRules', data)}
                            />
                        </div>}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(TipRules))));