import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { v4 as uuid } from 'uuid';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';
import GenericList from '~/components/GenericList/GenericList';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

class SubscriptionsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedReminder: {},
            showSubscriptionModal: false,
        };
    }

    handleTableButtonAction = (id, type) => {
        if (type === 'edit') this.props.router.navigate(`/admin/subscriptions/${id}`, { state: { subscriptions: id } });
        if (type === 'remove')
            return this.props.handleDelete(id, (id) => {
                return this.props.subscriptionStore.remove(id).then((res) => {
                    this.props.commonStore.addNotification(this.props.t('Deleted'), null, 'error');
                    this.props.router.navigate('/admin/subscriptions');
                });
            });
    };

    viewSubscriptionModal = (obj) => {
        this.setState({ selectedReminder: obj });
        this.renderSubscriptionModel();
    };

    renderSubscriptionModel = () => {
        this.setState({
            showSubscriptionModal: true,
        });
    };

    handleClosesSubscriptionModal = () => {
        this.setState({
            showSubscriptionModal: false,
        });
    };
    renderTableActionButtons = (id, full) => {
        return (
            <div className="actions-center actions-center__center">
                <Button icon_sm fill onClick={() => this.viewSubscriptionModal(full)}>
                    <i className="fa fa-bell actionblueicon" />
                </Button>
                <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')}>
                    <img src={Edit} alt="edit" />
                </Button>
                <Button icon_sm_delete fill onClick={() => this.handleTableButtonAction(id, 'remove')}>
                    <img src={Delete} alt="delete" />
                </Button>
            </div>
        );
    };

    render() {
        const { t } = this.props;
        const { loading, filters, appliedFilters, lastListLoadTime } = this.props.subscriptionStore;
        const { user_type } = this.props.userStore.currentUser;
        const { config } = this.props.commonStore;
        const defaultData = [{ days: '7' }];
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        const subscriptionRules =
            config.client &&
                config.client.data &&
                config.client.data.extendedRules &&
                config.client.data.extendedRules.subscriptionRules
                ? config.client.data.extendedRules.subscriptionRules
                : defaultData;
        let dates = [];
        let nDates = [];
        subscriptionRules.forEach((element) => {
            dates.push(moment(this.state.selectedReminder.expiry_date).subtract(element.days, 'days'));
            nDates = dates.sort(function (a, b) {
                return a - b;
            });
        });

        return (
            <Fragment>
                <GenericList
                    tableHeader={this.props.t('Reminder')}
                    columns={[
                        {
                            Header: 'ID',
                            id: 'id',
                            accessor: 'id',
                            isSortable: true,
                        },
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: ({ name }) => <span>{name}</span>,

                            isSortable: true,
                        },
                        user_type === 'member'
                            ? null
                            : {
                                Header: 'Member',
                                id: 'last_name',
                                accessor: ({ first_name, last_name }) => <span>{`${first_name} ${last_name}`}</span>,

                                isSortable: true,
                            },
                        {
                            Header: 'Expiry date',
                            id: 'expiry_date',
                            accessor: ({ expiry_date }) => shortDateFormat(expiry_date, dateTimeRules),
                            isSortable: true,
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            accessor: (full) => this.renderTableActionButtons(full.id, full),
                        },
                    ]}
                    filters={appliedFilters}
                    lastListLoadTime={lastListLoadTime}
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.subscriptionStore.setFilter(name, value)}
                            onFilter={() => this.props.subscriptionStore.onFilter()}
                            loading={loading}
                        />
                    }
                    requestData={(params) => this.props.subscriptionStore.loadList(params)}
                >
                    <Button fill wd onClick={() => this.props.onAdd()}>
                        <i className="fa fa-plus-circle fa-xl margin-right-10" />
                        {this.props.t('Add new')}
                    </Button>
                </GenericList>
                <Modal
                    className="modal fade"
                    show={this.state.showSubscriptionModal}
                    onHide={this.handleClosesSubscriptionModal}
                >
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{t('Reminders')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleClosesSubscriptionModal}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <h5>{t('Upcoming Reminders for this License/Documents')}</h5>
                        {nDates.map((date) => (
                            <ul key={uuid()}>
                                <li>{shortDateFormat(date, dateTimeRules)}</li>
                            </ul>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClosesSubscriptionModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}
export default inject(
    'userStore',
    'subscriptionStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(SubscriptionsList)))));
