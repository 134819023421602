import React, { Component } from 'react';
import moment from 'moment'; // 'moment/min/moment-with-locales';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

//components
import withLocalization from '../../../hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import Calendar from '../../../components/ReactCalendar';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import AfterCopyResponse from './AfterCopyResponse.js'

//elements
import CustomMultiSelect from '../../../elements/CustomMultiSelect';
import SwitchWidget from '../ControlPanel/SwitchWidget.js';
import Select from '../../../elements/Select.js';
import RequiredStar from '../../../elements/RequiredStar.js';
import LoadingSpinner from '../../../elements/LoadingSpinner';

//assests
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

//utils
import { compareFuncInt, getMomemntLocale } from '../../../utils/commonMethods.js'


const shiftTypes = [
    { value: 0, label: "All Shifts", title: "All Shifts" },
    { value: 1, label: "Published Shifts", title: "Published Shifts" },
    { value: 2, label: "Unpublished Shifts", title: "Unpublished Shifts" }
]

class CopyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all: false,
            users: [],
            fromWeeks: [],
            toWeeks: [],
            selected: [],
            userOptions: [],
            availableShiftsAndAssignees: [],
            activeStartDate: moment(new Date()).add(1, 'month').toDate(),
            overwriteShifts: false,
            shiftType: 0,
            afterCopyResponse: null,
            isLoading: true,
            checkAvailableShiftsBetween: { fromWeek: 0, endWeek: 0 },
            availableShiftCount: 0,
            hasVisitedFailedStatusTabs: null
        };
        this.getDateTimeRules = this.getDateTimeRules.bind(this);
    }

    componentDidMount() {
        let firstDayOfStartMonth = moment(new Date()).startOf('month');
        let endDayOfStartMonth = moment(new Date()).endOf('month');
        let firstDayOfNextMonth = moment(new Date()).add(1, 'month').startOf('month');
        let endDayOfNextMonth = moment(new Date()).add(1, 'month').endOf('month');
        let monthRange = moment.range(firstDayOfStartMonth, endDayOfStartMonth);
        let monthRangeNext = moment.range(firstDayOfNextMonth, endDayOfNextMonth);
        const fromWeeks = [];
        let toWeeks = [];

        for (let mday of monthRange.by('days')) {
            if (fromWeeks.indexOf(mday.week()) === -1) {
                fromWeeks.push(mday.week());
            }
        }

        for (let mday of monthRangeNext.by('days')) {
            if (toWeeks.indexOf(mday.week()) === -1) {
                toWeeks.push(mday.week());
            }
        }

        toWeeks = toWeeks.filter(val => !fromWeeks.includes(val));
        this.setState(
            {
                fromWeeks, toWeeks,
                checkAvailableShiftsBetween: {
                    fromWeek: fromWeeks.sort(compareFuncInt)[0],
                    endWeek: toWeeks.sort(compareFuncInt)[0]
                }
            },
            this.getAvailableShiftsAndAssignees);
    }

    selectAll() {
        const { all, userOptions } = this.state;
        let users = [];
        if (!all) { users = userOptions.map((r) => r.value); }
        this.setState({ all: !all, users });
    }

    async handleRun(action, data = {}) {
        const { t, commonStore, scheduleStore } = this.props;
        const { fromWeeks, toWeeks, users, overwriteShifts, hasVisitedFailedStatusTabs } = this.state;
        if (action === 'save') {
            if (!users.length) {
                return commonStore.addNotification(t('Please select users'), null, 'warning');
            }
            if (!fromWeeks.length) {
                return commonStore.addNotification(t('Please select weeks to copy'), null, 'warning');
            }
            if (!toWeeks.length) {
                return commonStore.addNotification(t('Please select weeks to copy onto'), null, 'warning');
            }
            if (toWeeks.length % fromWeeks.length !== 0) {
                return commonStore.addNotification(t('Please ensure the number of weeks to copy onto is a multiple of source weeks'), null, 'warning', "top-right", 3000);
            }
            if (_.min(fromWeeks) >= _.min(toWeeks)) {
                return commonStore.addNotification(t('Sorry, cant copy between years'), null, 'error');
            }

            if (overwriteShifts) {
                const str = t("You have selected to overwrite the existing shifts if any found during copying, please be advised that if you are not confident then skip this and system will show you the intersecting shifts and you select shifts individually to overwrite");
                confirmAlert({
                    title: this.props.t('Copy and Overwrite Shifts'),
                    message: str,
                    buttons: [
                        {
                            label: this.props.t('Proceed'),
                            onClick: () => {
                                this.setState({ isLoading: true }, this.submitCopyWeekRequest)
                            },
                        },
                        {
                            label: this.props.t('Cancel'),
                            onClick: () => { this.setState({ overwriteShifts: !overwriteShifts }) },
                        },
                    ],
                })
            } else {
                this.submitCopyWeekRequest()
            }

        }
        else if (action === 'overwrite') {
            await scheduleStore.overwriteOrRevertShiftChanges(data).then(res => {
                commonStore.addNotification(t(res.message), null, 'success');
                this.props.finish();
            }).catch(err => {
                commonStore.addNotification(t(typeof err === 'string' ? err : "Something Went Wrong!"), null, 'error');
            })
        }
        else {
            if (hasVisitedFailedStatusTabs && Object.keys(hasVisitedFailedStatusTabs).find(key => hasVisitedFailedStatusTabs[key] === false)) {
                const str = t("Few shifts failed to copy please check the tabs with excalimation mark for details");
                confirmAlert({
                    title: this.props.t('Close confirmation'),
                    message: str,
                    buttons: [
                        {
                            label: this.props.t("Ok, let me check")
                        },
                        {
                            label: this.props.t('Close anyway'),
                            onClick: this.props.finish
                        },
                    ],
                })
            } else {
                this.props.finish();
            }
        }
    }

    submitCopyWeekRequest = async () => {
        const { scheduleStore, t, commonStore } = this.props;
        const { fromWeeks, toWeeks, users, shiftType, overwriteShifts } = this.state;
        const _fromWeeks = _.orderBy(fromWeeks, (r) => r);
        const _toWeeks = _.orderBy(toWeeks, (r) => r);
        await scheduleStore.copyMonth({
            users,
            fromWeeks: _fromWeeks,
            toWeeks: _toWeeks,
            nowWeek: parseInt(moment(new Date()).format('w')),
            overwriteShifts,
            shiftType
        }).then(res => {
            this.setState({ afterCopyResponse: res, isLoading: false });
            commonStore.addNotification(t(res.message), null, 'success');
        }).catch(err => {
            this.setState({ isLoading: false })
            commonStore.addNotification(t(typeof err === 'string' ? err : "Something Went Wrong!"), null, 'error');
        });
    }

    onHandleWeek(week, source) {
        const { checkAvailableShiftsBetween } = this.state;
        const weeks = this.state[source];
        if (weeks.indexOf(week) >= 0) {
            weeks.splice(weeks.indexOf(week), 1);
        } else {
            weeks.push(week);
        }
        if (source === 'fromWeeks') {
            if (weeks.length && checkAvailableShiftsBetween.fromWeek !== weeks.sort(compareFuncInt)[0]) {
                this.setState({
                    isLoading: true, fromWeeks: weeks,
                    checkAvailableShiftsBetween: { ...checkAvailableShiftsBetween, fromWeek: weeks.sort(compareFuncInt)[0] }
                },
                    this.getAvailableShiftsAndAssignees)
            } else {
                this.setState({ fromWeeks: weeks }, this.filterUserOptionsAndShiftCounts);
            }
        } else {
            if (weeks.length && checkAvailableShiftsBetween.endWeek !== weeks.sort(compareFuncInt)[0]) {
                this.setState({
                    isLoading: true, toWeeks: weeks,
                    checkAvailableShiftsBetween: { ...checkAvailableShiftsBetween, endWeek: weeks.sort(compareFuncInt)[0] }
                },
                    this.getAvailableShiftsAndAssignees)
            } else {
                this.setState({ toWeeks: weeks });
            }
        }
    }

    getDateTimeRules = () => {
        const { commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data
            && commonStore.config.client.data.dateTimeRules ?
            commonStore.config.client.data.dateTimeRules : false;
        return dateTimeRules;
    }

    getAvailableShiftsAndAssignees = async () => {
        const { scheduleStore, t, commonStore } = this.props;
        const { checkAvailableShiftsBetween } = this.state;
        const { project, dayDurationFilterValue } = scheduleStore.filters;
        if (checkAvailableShiftsBetween.fromWeek && checkAvailableShiftsBetween.endWeek) {
            const params = {
                fromWeek: checkAvailableShiftsBetween.fromWeek,
                endWeek: checkAvailableShiftsBetween.endWeek,
                projectId: project,
                hours: dayDurationFilterValue
            }
            await scheduleStore.getShiftsAndAssignees(params).then(res => {
                const _availableShiftsAndAssignees = res;
                const { _options, _availableShiftCount } = this.getFilteredUserOptions(_availableShiftsAndAssignees)
                this.setState({ availableShiftsAndAssignees: _availableShiftsAndAssignees, options: _options, availableShiftCount: _availableShiftCount, isLoading: false });
            }).catch(err => {
                commonStore.addNotification(t(typeof err === 'string' ? err : "Something Went Wrong!"), null, 'error');
                this.setState({ isLoading: false });
            });
        }
    }

    getFilteredUserOptions = (list) => {
        const { shiftType, fromWeeks } = this.state;
        let userOptions = [], _availableShiftCount = 0;
        list.forEach(shift => {
            if (fromWeeks.includes(parseInt(shift.week_number)) && (!shiftType || (shift.show_to_member === (shiftType === 1)))) {
                _availableShiftCount++;
                if (!userOptions.find(e => e.value === shift.user_id)) {
                    userOptions.push({
                        value: shift.user_id,
                        label: shift.full_name
                    })
                }
            }
        });
        return { _options: userOptions, _availableShiftCount };
    }

    filterUserOptionsAndShiftCounts = () => {
        const { availableShiftsAndAssignees } = this.state;
        const { _options, _availableShiftCount } = this.getFilteredUserOptions(availableShiftsAndAssignees);
        this.setState({ userOptions: _options, availableShiftCount: _availableShiftCount })
    }

    translateLabels = () => {
        const { t } = this.props;
        shiftTypes.map(item => {
            item.label = t(item.title)
        })
    }


    render() {
        const { t } = this.props;
        const { fromWeeks, toWeeks, activeStartDate, shiftType, overwriteShifts, hasVisitedFailedStatusTabs,
            availableShiftsAndAssignees, availableShiftCount, afterCopyResponse, isLoading, options } = this.state;

        //to get comma seprate values from array of objects
        const getTileClass = (p, lookInFrom) => {
            const selectedWeeks = lookInFrom ? fromWeeks : toWeeks;
            if (selectedWeeks.indexOf(parseInt(moment(p.date).format('w'))) >= 0) {
                return lookInFrom ? 'day-week-selected-from' : 'day-week-selected-to';
            }
            return '';
        };
        this.translateLabels();
        return (
            <Modal dialogClassName="lg" className="lg" size="lg" show backdrop="static" onHide={() => this.handleRun('cancel')}>
                <Modal.Header className="set_ModalHeader">
                    <Modal.Title>{this.props.t('Copy')}{' '}{this.props.t('Schedule')}</Modal.Title>
                    <img
                        src={CloseModalIcon}
                        className="cursor-pointer"
                        onClick={() => this.handleRun('cancel')}
                    />
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? <LoadingSpinner /> :
                        <>
                            {!afterCopyResponse ?
                                <>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <label className="control-label">{t('Employees with available schedule')}<RequiredStar /></label>
                                            <CustomMultiSelect
                                                options={options}
                                                value={this.state.users}
                                                onChange={selected => this.setState({ users: selected })}
                                                overrideStrings={{
                                                    selectSomeItems: t('Select Members'),
                                                    allItemsAreSelected: t('All members are selected'),
                                                    search: t('Search'),
                                                    selectAll: t('Select All'),
                                                    noOptions: t('No Options')
                                                }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label className="control-label">{t('shifts')} {t('Type')}<RequiredStar /></label>
                                            <Select
                                                options={shiftTypes}
                                                value={shiftTypes.find(item => item.value === shiftType)}
                                                onChange={selectedItem => this.setState({ shiftType: selectedItem.value, isLoading: true }, () => {
                                                    const { _options, _availableShiftCount } = this.getFilteredUserOptions(availableShiftsAndAssignees)
                                                    this.setState({ options: _options, availableShiftCount: _availableShiftCount, isLoading: false });
                                                })}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <div className='row'>
                                                <div className='col-12 mb-2'>
                                                    <label className="control-label">{t('Overwrite Shifts')}</label>
                                                    &nbsp;
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {t("If enabled, this will overwrite all the intersecting shifts with copied ones otherwise it will not copy the intersecting shifts and prompt list for intersecting shifts")}.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className='col-12'>
                                                    <SwitchWidget
                                                        value={overwriteShifts}
                                                        onChange={checked => this.setState({ overwriteShifts: checked })} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <p className={!availableShiftCount ? 'text-danger' : 'text-primary'}><b>{t('Note')}:&nbsp;</b>
                                            {!availableShiftCount ?
                                                t('No shifts are available to copy in the selected weeks')
                                                :
                                                `${t('There are')} ${availableShiftCount} ${t('shift(s) available to copy')}`
                                            }
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label style={{ color: '#989898', fontSize: '14px', marginBottom: '12px' }}>
                                                {t('From weeks')}
                                                <span style={{ color: 'black', fontSize: '16px' }}>
                                                    &nbsp;&nbsp;{_.orderBy(fromWeeks, (r) => r).join(', ')}
                                                </span>
                                            </label>
                                            <Calendar
                                                locale={getMomemntLocale()}
                                                t={t}
                                                showFixedNumberOfWeeks
                                                showWeekNumbers
                                                checkedWeekNumbers={fromWeeks}
                                                disabledWeekNumbers={toWeeks}
                                                onClickWeekNumber={(week) => this.onHandleWeek(week, 'fromWeeks')}
                                                tileClassName={(params) => getTileClass(params, true)}
                                            />
                                        </div>
                                        <div
                                            className="col-md-6"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <label style={{ color: '#989898', fontSize: '14px', marginBottom: '12px' }}>
                                                {t('To weeks')}
                                                <span style={{ color: 'black', fontSize: '16px' }}>
                                                    &nbsp;&nbsp;{_.orderBy(toWeeks, (r) => r).join(', ')}
                                                </span>
                                            </label>
                                            <Calendar
                                                locale={getMomemntLocale()}
                                                t={t}
                                                activeStartDate={activeStartDate}
                                                onActiveStartDateChange={(a, v) => this.setState({ activeStartDate: v })}
                                                showFixedNumberOfWeeks
                                                showWeekNumbers
                                                checkedWeekNumbers={toWeeks}
                                                disabledWeekNumbers={fromWeeks}
                                                onClickWeekNumber={(week) => this.onHandleWeek(week, 'toWeeks')}
                                                tileClassName={(params) => getTileClass(params, false)}
                                            />
                                        </div>
                                    </div>
                                </>
                                :
                                <AfterCopyResponse
                                    afterCopyResponse={afterCopyResponse}
                                    dateTimeRules={this.getDateTimeRules()}
                                    closeModal={(data) => this.handleRun("overwrite", data)}
                                    updateVisitedFailedStatusTabs={(tabs) => this.setState({ hasVisitedFailedStatusTabs: tabs })}
                                    hasVisitedFailedStatusTabs={hasVisitedFailedStatusTabs}
                                />
                            }
                        </>
                    }

                </Modal.Body>
                {!afterCopyResponse &&
                    <Modal.Footer className='d-flex'>
                        <div className={'text-secondary col'}><b>{t('Note')}:&nbsp;</b>
                            {t('Shift Allowance and Emergency Shifts(On Call) will not be include in this, As it needs to be maintained manually')}.
                        </div>
                        <div className="col-4" style={{textAlign: 'center'}}>
                            <Button wd fill type="submit" onClick={() => this.handleRun('save')} disabled={isLoading || !availableShiftCount}>
                                {t('Start copy')}
                            </Button>
                        </div>
                    </Modal.Footer>
                }
            </Modal>
        );
    }
}

export default inject('userStore', 'commonStore', 'scheduleStore')(applicationRouter(withLocalization(observer(CopyForm))));
