import React, { Component } from 'react';
import { inject } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import config from '~/library/config';

import './Message.css';

const Avatar = props => (
    <div className="avatar-wrapper">
        <div style={{marginLeft:'10px'}}>
            <img
                src={props.icon}
                className='avatart-profile'
                alt='avatar-profile'     
            />
        </div>
        <div style={{marginTop:'10px'}}>
            <span className="user-title" > {props.title}</span>
        </div>
    </div>
);

class Message extends Component {
    getUrl = img => {        
        return `${config.UPLOADS_API_ENDPOINT}/${img}`;
    };

    render() {
        const {
            resolvedUser, data, isMine, startsSequence, endsSequence, showTimestamp, commonStore
        } = this.props;        
        let image_id = 0;
        if(data.data){
            image_id = data.data.image_id;
        }
        if(data.image_id){
            image_id = data.image_id;
        }
        const friendlyTimestamp = moment(data.timestamp).format('LLLL');
        let dateTimeRules = commonStore.config.client && commonStore.config.client.data && 
        commonStore.config.client.data.dateTimeRules ? 
        {...commonStore.config.client.data.dateTimeRules}
        : {short_date_format: "DD.MM.YYYY",  time_format: "hh:mm"};
        dateTimeRules.short_date_format = dateTimeRules.short_date_format ? dateTimeRules.short_date_format : "DD.MM.YYYY";
        dateTimeRules.time_format = dateTimeRules.time_format ? dateTimeRules.time_format : "hh:mm";        
        const friendlyTimestampDateFormat = moment(data.timestamp).format(dateTimeRules.short_date_format)
        const friendlyTimestampTimeFormat = moment(data.timestamp).format(dateTimeRules.time_format === "hh:mm"?'H:mm':'hh:mm a')
        return (
            <div
                className={[
                    'message',
                    `${isMine ? 'mine' : ''}`,
                    `${startsSequence ? 'start' : ''}`,
                    `${endsSequence ? 'end' : ''}`,
                ].join(' ')}
            >
                {showTimestamp && <div className="timestamp">{friendlyTimestampDateFormat}  {friendlyTimestampTimeFormat}</div>}

                <div className="bubble-container">
                    <div className="avatar-container">
                        {!isMine && startsSequence && (
                        
                            <Avatar title={`${resolvedUser.fullname}`} icon={resolvedUser.image} />
                        )}
                    </div>
                    <div className="bubble" title={friendlyTimestamp}>
                        {data.message}    
                        {image_id !== 0 &&
                            <div>
                                <img
                                    className='message-image'
                                    src={this.getUrl(image_id)}                                    
                                    alt={'chat-image'}
                                />
                            </div>
                        }                                                            
                    </div>
                    <div className="avatar-container" style={{marginLeft:'5px'}}>
                        {isMine && startsSequence && (
                            <Avatar title={`${resolvedUser.fullname}`} icon={resolvedUser.image} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default inject('commonStore')(Message);
