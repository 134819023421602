import React, { Component } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';

//elements //
import { Input } from '../../../elements/Input';

// components //
import applicationRouter from '~/hoc/applicationRouter'
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';

//assets //
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

export class TipList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tip_amount: 0,
            tipAmount: 0,
            isTipEdit: false,
            edit_id: 0,
            acceptanceChanged: false
        }
    }

    cols = [
        {
            Header: 'Created By',
            id: 'created_by',
            accessor: ({ created_by }) => (
                created_by
            ),
            isSortable: true,
        },
        {
            Header: 'Tip Amount',
            id: 'tip_amount',
            accessor: ({ tip_amount }) => (
                tip_amount
            ),
        },
        {
            Header: 'Entery Date',
            id: 'tip_date',
            accessor: ({ tip_date }) => (
                moment(new Date(tip_date)).format("DD/MM/YYYY")
            ),
        },
        {
            Header: 'Actions',
            id: 'actions',
            sortable: false,
            minWidth: 150,
            accessor: (full) => this.renderTableActionButtons(full.id, full),
        },

    ];
    onDeleteAction(id) {
        return this.props.handleDelete(id, id => {
            return this.props.timelogStore.deleteTip(id).then(res => {
                this.props.commonStore.addNotification(this.props.t("Deleted Successfully"), null, 'success');
                this.props.timelogStore.resetLastListLoadTime(new Date());
                this.setState({ acceptanceChanged: true });
            });
        });
    }

    createNewTip() {
        const { userStore, timelogStore, commonStore, t } = this.props;
        if (this.state.tipAmount < 1) return commonStore.addNotification(t("Tip Amount can't be blank"), null, 'error');
        const { user_type, id: user_id } = userStore.currentUser;
        const { id: client_id } = this.props.commonStore.config.client;
        timelogStore.addTip({ amount: this.state.tipAmount, user_id, user_type, client_id }).then(() => {
            commonStore.addNotification(t('Saved successfully'), null, 'success');
            timelogStore.resetLastListLoadTime(new Date());
            this.setState({ isTipModal: !this.state.isTipModal, tipAmount: 0 })
        })
    }

    openEditModal(data) {
        this.setState({ isTipEdit: !this.state.isTipEdit, acceptanceChanged: false, tip_amount: data.tip_amount, edit_id: data.id });
    }
    saveEditData() {
        const { timelogStore, commonStore, t } = this.props;
        if (this.state.tip_amount < 1) return commonStore.addNotification(t("Tip Amount can't be blank"), null, 'error');
        timelogStore.updateTip(this.state.edit_id, { tip_amount: this.state.tip_amount }).then(() => {
            timelogStore.resetLastListLoadTime(new Date());
            this.setState({ isTipEdit: !this.state.isTipEdit, acceptanceChanged: true });
        })
    }

    renderTableActionButtons(id, data) {
        return (
            <div className="actions-center justify-content-center">
                <Button icon_sm fill onClick={() => this.openEditModal(data)}>
                    <img src={Edit} alt="edit" />
                </Button>
                <Button icon_sm_delete fill onClick={() => this.onDeleteAction(id)}>
                    <img src={Delete} alt="delete" />
                </Button>
            </div>

        )
    }
    render() {
        const { user_type } = this.props.userStore.currentUser;
        const { t, timelogStore } = this.props;
        const { config } = this.props.commonStore;
        const tipEditor = config.client.data.tipRules && config.client.data.tipRules.tipEditor;
        return (
            <>
                <Modal centered show={this.state.isTipEdit} onHide={() => { this.setState({ isTipEdit: !this.state.isTipEdit }) }}>
                    <Modal.Header>
                        <Modal.Title>{this.props.t('Enter Tip Amount')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            alt='close icon'
                            onClick={() => this.setState({ isTipEdit: !this.state.isTipEdit })}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <Input
                            type="number"
                            value={this.state.tip_amount}
                            className="big-input"
                            onChange={(evt) => { this.setState({ tip_amount: evt.target.value }) }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-fill" onClick={() => { this.saveEditData() }}>{t('Save')}</button>
                    </Modal.Footer>
                </Modal>
                <div className="table-list__header">
                    {(user_type === "pm" && tipEditor === "pm") && (
                        <Button fill wd onClick={() => this.setState({ isTipModal: !this.state.isTipModal })}>
                            <i className="fa fa-plus-circle fa-xl margin-right-10" />
                            {this.props.t('Add Tip')}
                        </Button>
                    )}
                    <Modal centered show={this.state.isTipModal} onHide={() => { this.setState({ isTipModal: !this.state.isTipModal }) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.t('Enter Tip Amount')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Input
                                type="number"
                                value={this.state.tipAmount}
                                className="big-input"
                                onChange={(evt) => { this.setState({ tipAmount: evt.target.value }) }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-fill" onClick={() => { this.createNewTip() }}>Save</button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <GenericList
                    tableHeader={this.props.t('Tips')}
                    columns={this.cols}
                    forceReload={timelogStore.deleteSuccess || this.state.acceptanceChanged}
                    filters={timelogStore.appliedTipFilters}
                    lastListLoadTime={timelogStore.lastListLoadTime}
                    header={
                        <GenericFilter
                            filters={timelogStore.tip_Filter}
                            setFilter={(name, value) => timelogStore.setTipFilter(name, value)}
                            onFilter={() => timelogStore.onTipFilter()}
                            loading={timelogStore.loading}
                            hasDateFilters={true}
                        />
                    }
                    requestData={(params) => timelogStore.getTipList(params)}
                    pageSize={5}
                />
            </>
        )
    }
}

export default inject('authStore', 'timelogStore', 'commonStore', 'userStore')(applicationRouter(withRemoveDialog(withLocalization(observer(TipList)))));