import { observable, action, makeObservable } from 'mobx';
import moment from 'moment/min/moment-with-locales';
import queryString from 'query-string';

import agent from '../agent';
import { calculateBusinessDays } from '../../utils/commonMethods';

class AbsenceStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentEntity: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            lastListLoadTime: observable,
            filters: observable,
            appliedFilters: observable,
            standardCompanyTimeInMins: observable,
            currentUserExtraPayments: observable,
            currentUserKids: observable,
            resetLastListLoadTime: action,
            setFilter: action,
            onFilter: action,
            loadList: action,
            recalc: action,
            returnDefaultNew: action,
            load: action,
            save: action,
            remove: action,
            resetSelectedChild: action,
            getAbsenceTotals: action,
            cancelAbsence: action
        });
    }

    currentList = [];
    currentEntity = {};
    updatingErrors = null;
    updating = false;
    loading = false;
    requestParams = null;
    deleteSuccess = false;
    lastListLoadTime = null;
    standardCompanyTimeInMins = 450;
    currentUserExtraPayments = "";
    currentUserKids = null;
    filters = {
        status: '',
        name: '',
        absence_type: '',
    };
    appliedFilters = {
        status: '',
        name: '',
        absence_type: '',
    };

    resetLastListLoadTime(value) {
        this.lastListLoadTime = value;
    }

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    loadList(params) {
        return agent.Absences.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    async recalc(data, extraPaymentRules) {
        if (data && data.user_id) {
            this.currentEntity.absence.user_id = data.user_id;
        }
        let pay = 0;
        if (data && data.absence_payment_per_day && data.absence_payment_per_day > 0
            && data.leave_type !== 'unpaid') {
            pay = data.absence_payment_per_day;
        }
        const from = data ? data.from : this.currentEntity.absence.from;
        const to = data ? data.to : this.currentEntity.absence.to;
        const _employeeExtraPayments = this.currentUserExtraPayments;
        let days = calculateBusinessDays(from, to, _employeeExtraPayments, extraPaymentRules);
        this.currentEntity.absence.from = from;
        this.currentEntity.absence.to = to;
        this.currentEntity.absence.total_days = days;
        this.currentEntity.absence.absence_type = data && data.absence_type ? data.absence_type : this.currentEntity.absence.absence_type;
        if (data && data.employement_grade !== undefined && data.leave_type && data.leave_type === 'paid') {
            this.currentEntity.absence.hours = Number(Number((this.standardCompanyTimeInMins / 100 * data.employement_grade) / 60).toFixed(2));
            pay = Number((pay * this.currentEntity.absence.hours).toFixed(2));
            this.currentEntity.absence.total_hours = Number(Number((days > 16 ? 16 : days) * this.currentEntity.absence.hours).toFixed(2));
        } else {
            this.currentEntity.absence.hours = 0;
            this.currentEntity.absence.total_hours = 0;
        }

        this.currentEntity.absence.absence_total_payment = pay * (days > 16 ? 16 : days);
        return {
            total_days: this.currentEntity.absence.total_days,
            absence_total_payment: this.currentEntity.absence.absence_total_payment,
            hours: this.currentEntity.absence.hours,
            total_hours: this.currentEntity.absence.total_hours
        };
    }

    returnDefaultNew(params) {
        this.currentEntity = {
            absence: {
                user_id: params.user_id,
                from: moment(new Date()).format('YYYY-MM-DD'),
                to: moment(new Date()).format('YYYY-MM-DD'),
                total_days: 1,
                absence_type: 'self',
                absence_payment_per_day: 0,
                status: 'active',
                data: {},
            },
        };
        if (params && params.location) {
            const search = queryString.parse(params.location.search);
            if (search.from) {
                this.currentEntity.absence.from = moment(new Date(search.from)).format('YYYY-MM-DD');
            }
            if (search.to) {
                this.currentEntity.absence.to = moment(new Date(search.to)).format('YYYY-MM-DD');
            }
        }
        this.recalc();
        this.loading = false;
    }

    load(id) {
        this.loading = true;
        return agent.Absences.load(id)
            .then(
                action(response => {
                    if (!response.absence.data) response.absence.data = {};
                    if (response.absence.data.child_index) {
                        response.absence.child = response.absence.data.child_index;
                    }
                    this.currentEntity = response;
                    this.currentEntity.absence.from = moment(this.currentEntity.absence.from).format('YYYY-MM-DD');
                    this.currentEntity.absence.to = moment(this.currentEntity.absence.to).format('YYYY-MM-DD');
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    resetSelectedChild() {
        this.currentEntity.absence.child = null;
    }

    save(values, isAdd) {
        this.updating = true;
        if (!values.data) values.data = {};
        if (values.child) {
            if (this.currentUserKids && this.currentUserKids[parseInt(values.child, 10)]) {
                values.data.child = this.currentUserKids[parseInt(values.child, 10)];
            } else {
                values.data.child = null;
            }
            values.data.child_index = values.child;
        }

        return agent.Absences.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    this.updating = false;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async remove(id) {
        return agent.Absences.remove(id).then(res => {
            return res;
        }).catch(err => {
            throw err;
        });
    }

    getAbsenceTotals(params) {
        return agent.Absences.getAbsenceTotals(params)
            .then(
                action(data => {
                    if (this.currentEntity?.absence) {
                        this.standardCompanyTimeInMins = data.employeeDailyContractedTimeInMins;
                        this.currentUserExtraPayments = data.employeeExtraPayments;
                    }
                    return data;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    getChildrenDetails(id) {
        return agent.Absences.getChildrenDetails(id)
            .then(
                action(data => {
                    this.currentUserKids = data.kids ? data.kids : []
                    return data.kids ? data.kids : [];
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    cancelAbsence(id) {
        return agent.Absences.cancelAbsence(id)
            .then(
                action(res => {
                    return res;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }
}

const _AbsenceStore = new AbsenceStore();
export default _AbsenceStore;