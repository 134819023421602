import { Modal} from "react-bootstrap";

import CloseIcon from "~/assets/img/modal-close-icon.svg";

const MemberAvailabilityList = (props) => {
    const { t, renderAvailabilityList, setIsAddAvailability, checkAvailibilty, closeModal, userType } = props;
    return (
        <>
            <Modal show={true} className="modal right fade">
                <Modal.Body>
                    <div className="scheduleModalHeader">
                        <div className="scheduleModalClosebtn" onClick={() => closeModal()}><img src={CloseIcon} alt="close icon"></img></div>
                        <div className="mngHeadTxt">{t('Availability')}</div>

                    </div>
                    <div className="scheduleModalBody">
                        <>
                            <div className="mb-4">
                                {userType !== 'member' && <button className="btn-fill btn-wd btn btn-grey me-3" onClick={() => checkAvailibilty()}> {t('Request Availability')}</button>}
                                <button className="btn-fill btn-wd btn btn-primary" onClick={() => setIsAddAvailability(true)}>{t('Add Availability')} </button>
                            </div>
                            {renderAvailabilityList()}
                        </>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default MemberAvailabilityList;