import { OverlayTrigger, Tooltip } from 'react-bootstrap';

//components
import UserProfileLogo from "../../../components/UserProfileLogo";

//utils
import { timeFormat, totalTimeFormat, shortDateFormat } from '~/utils/dateFormat'

//assets
import clockExclamation from "~/assets/img/clockExclamation.svg";
import userExclamation from "~/assets/img/userExclamation.svg";
import swapLogo from "~/assets/img/swap-right-line.svg";
import tradeArrowIcon from "~/assets/img/trade-arrow-icon.svg";

const ScheduleManagementCard = (props) => {

    const { scheduleData, renderTableActionButtons, dateTimeRules, t, overtimeCalcPeriod } = props;

    const getCardType = () => {
        switch (scheduleData.swap_type) {
            case 'sick-leave':
                return <span style={{ color: '#FF0303' }}>{t('Sick Leave')}</span>
            case 'swap-shift':
                return <span style={{ color: '#2550AC' }}>{t('swap-shift')}</span>
            case 'trade-shift':
                return <span style={{ color: '#E5B300' }}>{t('trade-shift')}</span>
            case 'grab-shift':
                return <span style={{ color: '#FF0303' }}>{t('Grab')}</span>
        }
    }

    const getCardStatus = () => {
        const {is_rejected: isRejected, is_approved: isApproved } = scheduleData;
        if(isRejected){
            return <span style={{ color: '#FF0303' }}>{t('rejected')}</span>
        }else if(isApproved){
            return <span style={{ color: '#1EA009' }}>{t('approved')}</span>
        }else{
            switch (scheduleData.swap_status) {
                case 'swap-approved':
                    if (scheduleData.is_approved) return <span style={{ color: '#1EA009' }}>{t('approved')}</span>
                    if (scheduleData.is_rejected) return <span style={{ color: '#FF0303' }}>{t('rejected')}</span>
                case 'swap-rejected':
                    return <span style={{ color: '#FF0303' }}>{t('rejected')}</span>
                case 'assigned':
                    return <span style={{ color: '#2550AC' }}>{t('Assigned')}</span>
                case 'pending-invited':
                        return <span style={{ color: '#E5B300' }}>{t('Invited')}</span>
                case 'swap-accepted':
                    return <span style={{ color: '#1EA009' }}>{t('Accepted')}</span>
                case 'pending':
                    return <span style={{ color: '#E5B300' }}>{t('Pending')}</span>
                case 'pending-swap':
                    if (scheduleData.is_accepted === false && scheduleData.is_declined === false) return <span style={{ color: '#E5B300' }}>{t('Awaiting')}</span>
                    if (scheduleData.is_accepted === false && scheduleData.is_declined) return <span style={{ color: '#FF0303' }}>{t('Declined')}</span>
                    if (scheduleData.is_accepted && scheduleData.is_declined === false) return <span style={{ color: '#1EA009' }}>{t('Accepted')}</span>
            }
        }
    }

    const getShiftTypeIcon = () => {
        switch (scheduleData.swap_type) {
            case 'trade-shift':
                return <div className="tradeShiftIcon"><img src={tradeArrowIcon} alt="trade"></img></div>
            default:
                return <img src={swapLogo} alt="swap"></img>
        }
    }

    const renderOvertimeDetails = (scheduleData, user_type, t, dateTimeRules) => {
        if (!scheduleData.isOvertime) return null;
        if (user_type === 'initiator' && scheduleData.swap_type !== 'trade-shift') return null;
        if (user_type === 'acceptor' && !scheduleData.shiftTransactionOvertimeDetails.acceptorOvertimeMin) return null;
        if (user_type === 'initiator' && !scheduleData.shiftTransactionOvertimeDetails.swapperOvertimeMin) return null;

        let overtimeText = '';
        if (user_type === 'acceptor') {
            overtimeText = `${scheduleData.shiftTransactionOvertimeDetails.acceptorName} ${t('has')} ${totalTimeFormat(scheduleData.shiftTransactionOvertimeDetails.acceptorShiftTotalMins, dateTimeRules)} ${t('Scheduled').toLowerCase()} ${t('hours')} ${t('for this')} ${t(overtimeCalcPeriod)}. ${t('The')} ${t(scheduleData.swap_type)} ${t('will change total scheduled hours to')} ${totalTimeFormat(scheduleData.shiftTransactionOvertimeDetails.acceptorShiftTotalMinsAfterSwap, dateTimeRules)} ${t('hours')}, ${t('resulting in an overtime payment of')} ${totalTimeFormat(scheduleData.shiftTransactionOvertimeDetails.acceptorOvertimeMin, dateTimeRules)} ${t('hours')}.`;
        } else {
            overtimeText = `${scheduleData.shiftTransactionOvertimeDetails.swapperName} ${t('has')} ${totalTimeFormat(scheduleData.shiftTransactionOvertimeDetails.swapperShiftTotalMins, dateTimeRules)}  ${t('Scheduled').toLowerCase()} ${t('hours')} ${t('for this')} ${t(overtimeCalcPeriod)}. ${t('The')} ${t(scheduleData.swap_type)} ${t('will change total scheduled hours to')} ${totalTimeFormat(scheduleData.shiftTransactionOvertimeDetails.swapperShiftTotalMinsAfterSwap, dateTimeRules)} ${t('hours')}, ${t('resulting in an overtime payment of')} ${totalTimeFormat(scheduleData.shiftTransactionOvertimeDetails.swapperOvertimeMin, dateTimeRules)} ${t('hours')}.`;
        }

        return (
            <OverlayTrigger key={`overtimetext- ${user_type}`} placement="top" overlay={<Tooltip id="tooltip-top">{overtimeText}</Tooltip>}>
                <img src={clockExclamation} alt="overtime" width={'20px'}></img>
            </OverlayTrigger>
        )
    }

    const renderUserRoleException = (scheduleData, t) => {
        if ((scheduleData.swap_type !== 'trade-shift' && scheduleData.swap_type !== 'swap-shift') || scheduleData.is_rejected) return null;
        if (scheduleData.from_user_role === scheduleData.accepted_user_role) return null;
        const userRoleexception = t("These two employees do not have the same profession");

        return (
            <OverlayTrigger key="userRoleException" placement="top" overlay={<Tooltip id="tooltip-top">{userRoleexception}</Tooltip>}>
                <img src={userExclamation} alt="overtime" width={'20px'}></img>
            </OverlayTrigger>
        )
    }

    return (
        <>
            <div className="mngmtCard">
                <div className="mngmtCrdS1">
                    <div>
                        <UserProfileLogo image_id={scheduleData.from_user_image ? scheduleData.from_user_image : false} width='50px' height="50px"></UserProfileLogo>
                    </div>
                    <div className="mngmtCrdText">
                        <div>{scheduleData.from_user.trim() ? scheduleData.from_user : "Pending"} {renderOvertimeDetails(scheduleData, "initiator", t, dateTimeRules)}</div>
                        <div>{shortDateFormat(scheduleData.shift_date, dateTimeRules)}</div>
                        <div>{timeFormat(scheduleData.start_time, dateTimeRules)} - {timeFormat(scheduleData.end_time, dateTimeRules)}</div>
                    </div>
                </div>
                <div className="mngmtCrdLine">{getShiftTypeIcon()}</div>
                <div className="mngmtCrdS2">
                    <div>
                        <UserProfileLogo image_id={scheduleData.accepted_user_image ? scheduleData.accepted_user_image : false} width='50px' height="50px"></UserProfileLogo>
                    </div>
                    <div className="mngmtCrdText">
                        <div>{scheduleData.accepted_username} {renderOvertimeDetails(scheduleData, "acceptor", t, dateTimeRules)} {renderUserRoleException(scheduleData, t)}</div>
                        {scheduleData.accepted_user_date &&
                            <div>{shortDateFormat(scheduleData.accepted_user_date, dateTimeRules)}</div>
                        }
                        <div>{timeFormat(scheduleData.accepted_user_start_time, dateTimeRules)} - {timeFormat(scheduleData.accepted_user_end_time, dateTimeRules)}</div>
                    </div>

                </div>
                <div className="mngmtCrdS3">
                    <div className="mngmtCrdStatus">
                        <div>
                            <span className="typeTitle">Type</span>
                            <span className="crdType"> {getCardType()} </span>
                        </div>
                        <span className="crdStatus">{getCardStatus()}</span>
                    </div>
                    <div className="mngmtListActionBtn">
                        {renderTableActionButtons(scheduleData)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScheduleManagementCard;