import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

//stores
import commonStore from './stores/commonStore';
import userStore from './stores/userStore';

//utils
import config from './config';

//localization
import i18n from '../i18n';

const superagent = superagentPromise(_superagent, global.Promise);

const { API_ROOT } = config;

const redirectToLogin = () => {
    const host = window.location.hostname;
    const port = window.location.port;
    const url = host === 'localhost' ? `http://${host}:${port}` : `https://${host}`;
    window.location.replace(url)
}

const handleErrors = (err, response, skipErrors) => {
    if (!err) {
        // no error.
        return null;
    }
    if (err && err.response && err.response.status === 401) {
        // authStore.logout();
        if (response && response.body && response.body.errors && response.body.errors.error
            && response.body.errors.error.code && response.body.errors.error.code === 'invalid_token') {
            userStore.forgetUser();
            redirectToLogin();
            if (commonStore) commonStore.addNotification('Token Expired', null, 'error', 'tr', 4000);
            return;
        }

        if (commonStore) commonStore.addNotification('Authentication error', null, 'error');
        return new Error('Authentication error');
    }
    if (err && err.response && err.response.status === 413) {
        if (commonStore) commonStore.addNotification('Payload/File size exceed limit', null, 'error');
        return new Error('Payload/File size exceed limit');
    }
    if (err?.response?.body?.errors?.error_msg) {
        if (commonStore) commonStore.addNotification(i18n.t(`${err.response.body.errors.error_msg}`), null, 'error');
        return new Error('Please Fill the Details');
    }
    if (err && err.response && (err.response.status === 405 || err.response.status === 403)) {
        commonStore.addNotification(i18n.t(`${response?.body?.error}`), null, 'error');
        userStore.forgetUser();
        window.sessionStorage.removeItem('cusToken')
        redirectToLogin();
        return;
    }
    let text = 'Unknown error connecting to the server';
    console.error(err);
    if (err && response && response.body && response.body.error && typeof response.body.error === 'string') {
        text = response.body.error;
    }

    if (
        err &&
        err.response &&
        err.response.body &&
        err.response.body.errors &&
        err.response.body.errors.error &&
        typeof err.response.body.errors.error === 'string'
    ) {
        text = err.response.body.errors.error;
    }
    if (err && err.response && err.response.body && err.response.body.errors && err.response.body.errors.message) {
        text = err.response.body.errors.message;
    }

    if (commonStore && !skipErrors) commonStore.addNotification(i18n.t(text), null, 'error', "top-right", text.length > 30 ? 3000 : 1000);
    else {
        return new Error(text);
    }
};

const responseBody = (res) => res.body;

const tokenPlugin = (req) => {
    if (commonStore.token) {
        req.set('authorization', `Token ${commonStore.token}`);
    }
    if (window.sessionStorage.getItem('cusToken')) {
        req.set('authorization', `Token ${window.sessionStorage.getItem('cusToken')}`);
    }
};

const convertToGetParams = (params) =>
    Object.keys(params).reduce((total, currentValue, currentIndex, arr) => {
        let param = JSON.stringify(params[currentValue]);
        if (!param) {
            return total;
        }
        if (param.indexOf('{') < 0 && param.indexOf('[') < 0) param = param.replace('"', '').replace('"', '');
        return `${total}${currentValue}=${param}&`;
    }, '?');

const requests = {
    del: (url) => superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).end(handleErrors).then(responseBody),
    get: (url, skipErrors) =>
        superagent
            .get(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end((e, r) => handleErrors(e, r, skipErrors))
            .then(responseBody),
    put: (url, body, skipErrors) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end((e, r) => handleErrors(e, r, skipErrors))
            .then(responseBody),
    post: (url, body, skipErrors) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end((e, r) => handleErrors(e, r, skipErrors))
            .then(responseBody),
};

const Auth = {
    current: () => requests.get('/user'),
    loadById: (id) => requests.get(`/user/${id}`),
    config: () => requests.get('/config'),
    login: (email, password) => requests.post('/users/login', { user: { email, password } }),
    loginAs: (id) => requests.get(`/users/loginas/${id}`, true),
    register: (values) =>
        requests.post('/users', {
            user: values,
        }),
    save: (user, type = 'basic') => requests.put('/user', { user, type }),
    log: (log) => requests.post('/log', { log }),
    resetPassword: (email) => requests.put('/reset_password', { email }),
    upload: (images) => requests.post('/images', { images }),
    setLang: (lang) => !!window.sessionStorage.getItem('jwt') ? requests.post('/user/lang', { lang }) : requests.post('/customers/lang', { lang })
};

const BizTypes = {
    list: (params) => requests.get(`/biztypes${convertToGetParams(params)}`),
    load: (id) => requests.get(`/biztypes/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/biztypes/', values) : requests.put(`/biztypes/${values.id}`, values),
    remove: (id) => requests.del(`/biztypes/${id}`),
    getBizTypeDocumentsById: (id) => requests.get(`/biztype_documents/biz/${id}`),
    saveBizTypeDocuments: (data) => requests.post('/biztype_documents', data),
};

const Clients = {
    list: (params) => requests.get(`/clients${convertToGetParams(params)}`),
    load: (id) => requests.get(`/clients/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/clients/', values) : requests.put(`/clients/${values.id}`, values),
    remove: (id) => requests.del(`/clients/${id}`),
    addAttachments: (data) => requests.post('/clients/attachments', data),
    renewClient: (data) => requests.post('/clients/renew', data),
    getMultipleAttachments: (data) => requests.post('/attachments/getmultiple', data),
    getTripleTexToken: (id, data) => requests.put(`/clients/getTripleTexToken/${id}`, data),
    getmultipleAccountsForClient: (id) => requests.get(`/clients/multi_accounts/${id}`),
    saveVismaDetails: (data) => requests.post(`/clients/saveVismaDetails`, data),
    getActiveTaskWithMembersForExtraPayments: () => requests.get('/clients/getActiveTasksWithMembers')
};

const Users = {
    list: (params) => requests.get(`/admins${convertToGetParams(params)}`),
    load: (id) => requests.get(`/admins/${id}`),
    save: (values, isAdd) => (isAdd ? requests.post('/admins/', values) : requests.put(`/admins/${values.id}`, values)),
    remove: (id) => requests.del(`/admins/${id}`),
    uploadImage: (params) => requests.post('/attachments', params),
    lookupByName: (mode, name, project, includeInactiveUsers) => requests.get(`/admins/lookup_users?name=${name}&mode=${mode}&project=${project}&includeInactiveUsers=${includeInactiveUsers}`),
    sendReport: (params) => requests.get(`/dashboard/send_report${convertToGetParams(params)}`),
    getReportInfo: (params) => requests.get(`/dashboard/report_info${convertToGetParams(params)}`),
    clearNotifications: (params) => requests.get(`/user/clear-notifications_v2${convertToGetParams(params)}`),
    getNotifications: () => requests.get('/user/notifications_v2'),
    gitRev: () => requests.get('/git-rev'),
    validateNewUser: (params) => requests.post('/admins/validate', params),
    validateAddPossible: () => requests.get('/admins/validate_add_possible'),
    increaseUserLimit: () => requests.get('/admins/increase_user_limit'),
    byProjectId: (project_id) => requests.get(`/user/byprojectid/${project_id}`),
    updateAttachmentName: (data) => requests.put('/attachments', data),
    saveInviteUser: (data) => requests.post('/admins/inviteUser', data),
    saveRegisterUser: (params) => requests.get(`/admins/register-user/${convertToGetParams(params)}`),
    getInvitedClientId: (data) => requests.post('/admins/isurl', data),
    saveMember: (data) => requests.post('/admins/register-user', data),
    setActiveInactive: (values) => requests.put(`/admins/setToInactive/${values.id}`, values),
    readNotification: (data) => requests.post('/user/notifications-read', data),
    saveIntoIntegration: (values) => requests.put(`/admins/saveIntoIntegration/${values.id}`, values),
    desyncIntegration: (values) => requests.put(`/admins/desyncIntegration/${values.id}`, values),
    checkIfValidLink: (id) => requests.get(`/user/checkUserLink/${(id)}`),
    setSecurePassword: (data) => requests.post('/user/password_setup', data),
    getDepartmentForIntegration: () => requests.get('/admins/getTripletexDepartmentForTripletex'),
    resetUserSickLeaveStartDate: (data) => requests.post('/user/reset_sick_leave_start_date', data),
    getAllEmployeeFromIntegration: (params) => requests.get(`/admins/getAllEmployeeFromIntegration${convertToGetParams(params)}`),
    saveMemberFromIntegration: (data) => requests.post(`/admins/saveMemberFromIntegration`, data),
    getUsersWithGroup: () => requests.get(`/admins/getUsersWithGroup`),
    getPmMembersToAssign: (pm_id) => requests.get(`/users/member-to-assign-to-pm/${pm_id}`),
    assignMembersToPm: (pm_id, data) => requests.put(`/admins/assign-members-to-pm/${pm_id}`, data),
    getUserPermissions: (user_id) => requests.get(`/user_access_rules/${user_id}`),
    setUserPermissions: (data) => requests.post(`/user_access_rules`, data)
};

const UserGroups = {
    getGroupByUserAndUsageDetails: () => requests.get('/user_group_details'),
    getGroupDetails: (params) => requests.get(`/user_group_details/get_group_details${convertToGetParams(params)}`),
    getUserGroups: (user_id) => requests.get(`/user_group_details/get_user_groups/${user_id}`),
    getUsersInGroup: (user_group_code) => requests.get(`/user_group_details/get_users_in_group/${user_group_code}`),
    checkIfGroupInUse: (user_group_code) => requests.get(`/user_group_details/check_user_group_usage/${user_group_code}`),
    addUserGroupAndAssignMembers: (data) => requests.post('/user_group_details/add_user_group_and_assign_members', data),
    updateUserGroupAndAssignees: (data) => requests.put('/user_group_details/update_user_group_and_assignees', data),
    removeGroup: (id) => requests.del(`/user_group_details/${id}`),
}

const Signature = {
    createCaseID: (data) => requests.post(`/sign_documents`, data),
    uploadDocs: (data) => requests.post(`/sign_documents/addDocument`, data),
    deleteDocs: (doc_id) => requests.del(`/sign_documents/removeDocument/${encodeURIComponent(doc_id)}`),
    fetchAllDocs: (case_id) => requests.get(`/sign_documents/getById/${encodeURIComponent(case_id)}`),
    updateDocName: (data) => requests.post('/sign_documents/updateDocumentName', data),
    deleteDocPage: (id) => requests.del(`/sign_documents/removeDocumentPage/${id}`),
    signDocHistory: (params) => requests.get(`/sign_doc_history/getById/${convertToGetParams(params)}`),
    addWidgets: (data) => requests.post(`/sign_documents/addWidgetsData`, data),
    createDocument: (params) => requests.get(`/sign_documents${convertToGetParams(params)}`),
    sendDocNow: (data) => requests.post(`/sign_documents/review_send`, data),
    getRecipientDoc: (id) => requests.get(`/sign_documents/getDocument/${encodeURIComponent(id)}`),
    recipientAgreement: (data) => requests.post(`/recipient_agreements`, data),
    getByDocCaseId: (id) => requests.get(`/sign_documents/getByDocCaseId/${encodeURIComponent(id)}`),
    storeSignature: (data) => requests.post('/recipient_signs', data),
    downloadPdf: (id) => requests.get(`/sign_documents/download/${encodeURIComponent(id)}`),
    generateSignatureLink: (id) => requests.get(`/sign_documents/create_signature_link/${encodeURIComponent(id)}`),
    downloadCompletedDocument: (id) => requests.get(`/sign_documents/completed_signing_document/${encodeURIComponent(id)}`),
    verifyAccessCode: (data) => requests.post(`/sign_documents/verify_access_code`, data),
    deleteSignatureDoc: (case_id) => requests.del(`/sign_documents/${encodeURIComponent(case_id)}`)
}

const Dashboard = {
    get: (params) => requests.get(`/dashboard${convertToGetParams(params)}`),
    getSchedules: (params) => requests.get(`/schedule/dashboard_schedules${convertToGetParams(params)}`),
    contact: (params) => requests.put('/contact_email', params),
};

const Projects = {
    list: (params) => requests.get(`/projects${convertToGetParams(params)}`),
    load: (id) => requests.get(`/projects/${id}`),
    getProjectMembersIds: (id) => requests.get(`/projects/projectmemberids/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/projects/', values) : requests.put(`/projects/${values.id}`, values),
    remove: (id, confirm) => requests.del(`/projects/${id}?confirm=${confirm}`),
    lookupByName: (mode, name, _module, user_id) =>
        requests.get(`/projects/lookup_projects?name=${name}&mode=${mode}&module=${_module}&user_id=${user_id}`),
    saveIntoIntegration: (values) => requests.put(`/projects/saveIntoIntegration/${values.id}`, values),
    getAllProjectFromIntegration: () => requests.get(`/projects/getAllProjectFromIntegration`),
    saveProjectFromIntegration: (data) => requests.post(`/projects/saveProjectFromIntegration`, data),
    getProjectPmShiftPermissions: (id) => requests.get(`/project_pm_rules/${id}`),
    setProjectPmShiftPermissions: (data) => requests.post('/project_pm_rules', data),
    sendInviteToContactPerson: (data) => requests.post('/projects/inviteToContactPerson', data),
};

const Tasks = {
    list: (params) => requests.get(`/tasks${convertToGetParams(params)}`),
    load: (id) => requests.get(`/tasks/${id}`),
    save: (values, isAdd) => (isAdd ? requests.post('/tasks/', values) : requests.put(`/tasks/${values.id}`, values)),
    remove: (id) => requests.del(`/tasks/${id}`),
    lookupByName: (name) => requests.get(`/tasks/lookup_tasks?name=${name}`),
    gpsAddress: (address, taskId, isFromTask) => requests.get(`/tasks/geolocation?address=${encodeURI(address)}&isFromTask=${isFromTask}&changeId=${taskId}`),
    getTaskToClone: (id) => requests.get(`/tasks/${id}/getAllTasks`),
    cloneTasks: (data) => requests.post('/tasks/cloneTasks', data),
    saveIntoIntegration: (values) => requests.put(`/tasks/saveIntoIntegration/${values.id}`, values)
};

const Chat = {
    loadRooms: () => requests.get('/chat/rooms'),
    loadRoom: (id) => requests.get(`/chat/room/${id}`),
    loadMessages: (id) => requests.get(`/chat/messages/${id}`),
    createPrivateRoom: (data) => requests.post(`/chat/privaterooms`, data),
    loadPrivateRooms: () => requests.get('/chat/privaterooms'),
    loadPrivateRoom: (id) => requests.get(`/chat/privateroom/${id}`),
    saveRoom: (values, isAdd) =>
        isAdd ? requests.post('/chat/rooms', values) : requests.put(`/chat/room/${values.room.id}`, values),
    removeRoom: (id) => requests.del(`/chat/rooms/${id}`),
    removePrivateRoom: (id) => requests.del(`/chat/${id}`)
};

const Recipients = {
    list: (params) => requests.get(`/recipients${convertToGetParams(params)}`),
    save: (values) => requests.post('/recipients/', values),
    fetchAllRecipients: (id) => requests.get(`/recipients/getByCaseId/${encodeURIComponent(id)}`),

}

const Timelogs = {
    list: (params) => requests.get(`/timelogs${convertToGetParams(params)}`),
    load: (id) => requests.get(`/timelogs/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/timelogs/', values) : requests.put(`/timelogs/${values.id}`, values),
    remove: (id) => requests.del(`/timelogs/${id}`),
    // saveHolidays: (values) => requests.post('/timelogs/holidays', values),
    setStatus: (entityName, id, data) => requests.put(`/timelogs/status/${entityName}/${id}`, data),
    presenceList: (params) => requests.get(`/presences${convertToGetParams(params)}`),
    getDraftId: (autostart) => requests.get(`/timelogs/draft_id${convertToGetParams(autostart || {})}`),
    getCurrentDraftOrSkip: (forceProject, user, onlyValid) =>
        requests.get(
            `/timelogs/current_draft_or_skip?forceProject=${forceProject}&user=${user}&onlyValid=${onlyValid}`
        ),
    getCurrentDraftForLocked: (forceProject, user) =>
        requests.get(`/timelogs/current_draft_for_locked?project=${forceProject}&user=${user}`),
    finishByButton: (id, data) => requests.put(`/timelogs/finish/${id}`, data || {}),
    reportInjury: (id, data) => requests.put(`/timelogs/reportInjury/${id}`, data),
    breakByButton: (id, action) => requests.put(`/timelogs/break/${id}/${action}`, {}),
    startByButton: (id, data) => requests.put(`/timelogs/start/${id}`, data),
    getAllLocations: (params) => requests.get(`/timelogs/all_locations${convertToGetParams(params)}`),
    sendMassStatusPushNotification: (userId, comment, action) => requests.post(`/timelogs/multiplePush/${userId}`, { comment, action }),
    sendSingleStatusPushNotification: (timelogId, comment, action, syncWithIntegration) => requests.post(`/timelogs/singlePush/${timelogId}`, { comment, action, syncWithIntegration }),
    addTip: (data) => requests.post(`/timelogs/addtip`, data),
    getTipList: (params) => requests.get(`/timelogs/tip${convertToGetParams(params)}`),
    deleteTip: (id) => requests.del(`/timelogs/tip/${id}`),
    updateTip: (id, data) => requests.put(`/timelogs/tip/${id}`, data),
    validateTimelogWithIntegration: (values) => requests.post('/timelogs/validateTimelogWithIntegration', values),
    cancelPendingAbsenceOrVacation: (link) => requests.put(`/${link}`)
};

const Vacations = {
    list: (params) => requests.get(`/vacations${convertToGetParams(params)}`),
    load: (id) => requests.get(`/vacations/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/vacations/', values) : requests.put(`/vacations/${values.id}`, values),
    remove: (id) => requests.del(`/vacations/${id}`),
    cancelVacation: (id) => requests.put(`/vacations/cancel/${id}`)
};

const Absences = {
    list: (params) => requests.get(`/absences${convertToGetParams(params)}`),
    load: (id) => requests.get(`/absences/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/absences/', values) : requests.put(`/absences/${values.id}`, values),
    remove: (id) => requests.del(`/absences/${id}`),
    getAbsenceTotals: (params) => requests.get(`/absences/absenceTotals${convertToGetParams(params)}`),
    getChildrenDetails: (id) => requests.get(`/absences/childrenDetails/${id}`),
    cancelAbsence: (id) => requests.put(`/absences/cancel/${id}`)
};

const Deviation = {
    list: (params) => requests.get(`/deviations${convertToGetParams(params)}`),
    load: (id) => requests.get(`/deviations/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/deviations/', values, true) : requests.put(`/deviations/${values.id}`, values, true),
    remove: (id) => requests.del(`/deviations/${id}`),
    transition: ({ id, transitionName, transitionData }) =>
        requests.put(`/deviations/${id}/transition/${transitionName}`, transitionData, true),
    sendEmail: (id, email) => requests.put(`/deviations/${id}/email`, { email }, true),
};

const ExtraDoc = {
    list: (params) => requests.get(`/extradocs${convertToGetParams(params)}`),
    load: (id) => requests.get(`/extradocs/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/extradocs/', values, true) : requests.put(`/extradocs/${values.id}`, values, true),
    remove: (id) => requests.del(`/extradocs/${id}`),
};

const Locked = {
    startLock: (data) => requests.post('/locked/pm?start=true', data, true),
    endLock: (data) => requests.post('/locked/pm?start=false', data, true),
    loginWithPin: (data) => requests.post('/locked/loginwithpin', data, true),
    // makeAction: (id, data) => requests.put(`/locked/action/${id}`, data),
    loadList: (params) => requests.get(`/locked/list${convertToGetParams(params)}`),
    sendReportByMail: (params) => requests.get(`/locked/send${convertToGetParams(params)}`),
    sendSms: (params) => requests.get(`/locked/sendsms${convertToGetParams(params)}`, true),
};

const Schedule = {
    list: (params) => requests.get(`/v2/schedule${convertToGetParams(params)}`),
    availabilityList: (params) => requests.get(`/availability/get-table${convertToGetParams(params)}`),
    load: (id) => requests.get(`/schedule/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/schedule/', values) : requests.put(`/schedule/${values.id}`, values),
    getAvailableAssignees: (id) => requests.get(`/schedule/${id}/available_assignees`),
    sendSwapShiftRequest: (users) => requests.post('/schedule/send_swap_request', users),
    getSwapShiftRequests: (swap_id) => requests.get(`/schedule/swap_shift_requests`, { swap_id: swap_id }),
    getSwapShiftRequestsForPM: (params) => requests.get(`/schedule/swap_shift_requests_approval${convertToGetParams(params)}`),
    acceptSwapShiftRequests: (data) => requests.post(`/schedule/swap_accepted`, data),
    rejectSwapShiftRequests: (data) => requests.post(`/schedule/swap_rejected`, data),
    approveOrRejectSwapShiftRequestsFromPM: (data) => requests.post('/schedule/shift_swap_approve_or_reject', data),
    approveOrRejectTradeShiftRequestsFromPM: (data) => requests.post('/schedule/shift_trade_approve_or_reject', data),
    addBulkShift: (values) => requests.post('/schedule/bulk-shift/', values),
    remove: (id, params) => requests.del(`/schedule/${id}${convertToGetParams(params)}`),
    loadResources: (params) => requests.get(`/schedule/resources${convertToGetParams(params)}`),
    move: (data) => requests.put('/schedule/move', data),
    copy: (data) => requests.put('/schedule/copy', data),
    listWeekly: () => requests.get('/schedule/weekly'),
    copyToNextWeek: (data) => requests.put('/schedule/copy_to_next', data),
    copyMonth: (data) => requests.put('/schedule/copy_month', data),
    memberAction: (id, action, data) => requests.put(`/schedule/${id}/memberaction/${action}`, data),
    removeMultiple: (id, params) => requests.del(`/schedule/multi_delete/${id}${convertToGetParams(params)}`),
    sendNotify: (values) => requests.post('/availability/sendnotifications/', values),
    updateAvailabilityStatus: (id, data) => requests.put(`/availability/update-status/${id}`, data),
    removeAvailability: (id) => requests.del(`/availability/${id}`),
    updateStatus: (id, data) => requests.put(`/availability/${id}`, data),
    saveAvailability: (values) => requests.post('/availability/', values),
    getAvailability: (values) => requests.get(`/availability/${convertToGetParams(values)}`),
    hideShowShifts: (values) => requests.post(`/schedule/hide_show_shifts`, values),
    getWeekHeader: (params) => requests.get(`/schedule/currentWeekHeader${convertToGetParams(params)}`),
    overwriteOrRevertShiftChanges: (data) => requests.put('/schedule/overwrite_shifts', data),
    getShiftsAndAssignees: (params) => requests.get(`/schedule/get_shifts_and_assignees${convertToGetParams(params)}`),
    getAvailableAssigneesForBulkShift: () => requests.get('/schedule/getAvailableAssigneesForBulkShift'),
    approveTimesheets: (data) => requests.post('/v2/schedule/approve-timelogs', data)
};

const checklist = {
    listelementbyclientid: (params) => requests.get(`/elements/client`),
    addelement: (data) => requests.post('/elements', data, true),
    getElementById: (id) => requests.get(`/elements/${id}`),
    deleteElementById: (id) => requests.post(`/elements/delete/${id}`),
    getMultipleAttachments: (data) => requests.post('/attachments/getmultiple', data),

    searchElement: (data) => requests.post('/elements/search', data, true),
    getElementChildByParentId: (data) => requests.post('/elements/child', data, true),
    copyElementById: (data) => requests.post('/elements/copy', data, true),

    getChecklistTemplateByClientId: (params) => requests.get(`/checklists${convertToGetParams(params)}`),
    getChecklistTemplateByClientIdPagination: (params) => requests.get(`/elements/cpage/${convertToGetParams(params)}`),
    addChecklistTemplate: (data) => requests.post('/checklists', data, true),
    getChecklistTemplateById: (id) => requests.get(`/checklists/${id}`),
    deleteChecklistTemplateById: (id) => requests.del(`/checklists/${id}`),
    deleteChecklistById: (id) => requests.del(`/userchecklists/${id}`),
    assignChecklist: (data) => requests.post('/userchecklists/assign', data, true),
    scheduleToNone: (data) => requests.post('/userchecklists/scheduleToNone', data, true),
    getChecklistById: (id) => requests.get(`/userchecklists/get/${id}`),
    lookupByName: (data) => requests.post(`/checklists/lookup_checklist`, data, true),
    getChecklists: (params) => requests.get(`/v2/userchecklists${convertToGetParams(params)}`),
    getChecklistsIsSchedule: (params) => requests.get(`/userchecklists/isSchedule${convertToGetParams(params)}`),
    checkChecklistElement: (data) => requests.post(`/userchecklists/check`, data, true),
    checkAllChecklist: (data) => requests.post(`/userchecklists/checkall`, data, true),
    approveChecklist: (data) => requests.post('/userchecklists/approve', data, true),
    report: (type) => requests.post(`/userchecklists/report/${type}`),
    deviationChecklist: (data) => requests.post('/userchecklists/deviation', data, true),
}

const documents = {
    //HTTP methods for documents//
    getDocuments: (params) => requests.get(`/documents/page${convertToGetParams(params)}`),
    getDocumentsList: (params) => requests.get(`/documents/list`),
    getDocumentById: (id) => requests.get(`/documents/getById/${id}`),
    addDocument: (data) => requests.post('/documents', data, true),
    copyDocument: (data) => requests.post('/documents/copy', data, true),
    deleteDocumentById: (id) => requests.del(`/documents/${id}`),
    getTemplateDocuments: (params) => requests.get(`/documents/getTemplateDocs${convertToGetParams(params)}`),
    getAdminDocuments: (params) => requests.get(`/documents/admin/list`),
    getPDFDataByAttachmentId: (id) => requests.get(`/attachments/filedata/${id}`),

    //HTTP methods for document_template//
    getDocumentTemplates: (params) => requests.get(`/document_templates${convertToGetParams(params)}`),
    getDocumentTemplateById: (id) => requests.get(`/document_templates/getById/${id}`),
    addDocumentTemplate: (data) => requests.post('/document_templates', data, true),
    deleteDocumentTemplateById: (id) => requests.del(`/document_templates/${id}`),
    getDocumentsInTemplate: (id) => requests.get(`/document_templates/getDocs/${id}`),
    getDocumentsInTemplateInSelectedOrder: (id) => requests.get(`/document_templates/getDocsByOrder/${id}`),
    getMultipleDoumentsByIds: (params) =>
        requests.get(`/document_templates/getMultipleDoumentsByIds${convertToGetParams(params)}`),
    getDocumentReportByTemplateId: (id) => requests.get(`/document_templates/report/${id}`),
    getDocumentReportPreviewByTemplateId: (id) => requests.get(`/document_templates/reportPreview/${id}`),
    getmultipleDocumentsPdfPreviewUrl: (params) =>
        requests.get(`/document_templates/multipleDocumentsPdfPreview${convertToGetParams(params)}`),
    getDocumentTemplateAssignList: (id) => requests.get(`/document_templates/getTemplateAssignList/${id}`),

    //HTTP methods for document_read//
    addDocumentRead: (data) => requests.post('/document_read', data),
    sendDocumentReadMailToMembers: (data) => requests.post('/document_read/sendMailsToMembers', data),
    updateDocumentReadStatus: (data) => requests.post('/document_read/edit', data),
    assignDocumentTemplatesToUser: (data) => requests.post('/document_read/assignDocumentTemplatesToUser', data),
};

const visitors = {
    //HTTP methods for visitors//
    getVisitors: (params) => requests.get(`/visitors${convertToGetParams(params)}`),
    getVisitorsReport: (params) => requests.get(`/visitors/report${convertToGetParams(params)}`),
    getVisitorById: (id) => requests.get(`/visitors/getById/${id}`),
    addVisitor: (data) => requests.post('/visitors', data, true),
    addVisitorPublic: (data) => requests.post('/visitors/visitorRegister', data, true),
    addClientVisitorPublic: (data) => requests.post('/visitors/visitorClientRegister', data, true),
    verifyClientVisitor: (data) => requests.post('/visitors/verifyClientRegister', data, true),
    deleteVisitorById: (id) => requests.del(`/visitors/${id}`),
};

const contracts = {
    getContractTypes: (params) => requests.get(`/contract_types${convertToGetParams(params)}`),
    getContractTypesById: (id) => requests.get(`/contract_types/getById/${id}`),
    addContractType: (data) => requests.post('/contract_types', data, true),
    UpdateContractType: (data) => requests.put(`/contract_types/`, data),
    deleteContractById: (id) => requests.del(`/contract_types/${id}`),
    loadEditorDataById: (id) => requests.get(`/contract_documents/getById/${id}`),
    saveEditorData: (data) => requests.post('/contract_documents/', data),
    saveContractArchives: (data) => requests.post(`/contract_archives`, data),
    getPDFData: (id) => requests.get(`/contract_archives/getById/${id}`),
    archivesPDFDownload: (id) => requests.get(`/contract_archives/download/${id}`)
};

const contractslist = {
    getContractsData: (params) => requests.get(`/contracts${convertToGetParams(params)}`),
    getContractsById: (id) => requests.get(`/contracts/${id}`),
    addContractData: (data) => requests.post('/contracts', data, true),
    deleteContractsData: (id) => requests.del(`/contracts/${id}`),
    UpdateContracts: (data) => requests.put(`/contracts/`, data),
    //   UpdateContracts: (values, isAdd) =>
    //   isAdd ? requests.post('/contracts/', values) : requests.put(`/contracts/${values.id}`, values)
}

const Monitor = {
    list: (params) => requests.get(`/monitor${convertToGetParams(params)}`),
    load: (id) => requests.get(`/monitor/${id}`),
    save: (data) => requests.post('/monitor/', data),
    // save: (data, isAdd) =>
    //     isAdd ? requests.post('/monitor/', data) : ,
    loadGridData: (id, params) => requests.get(`/monitor/gridData/${id}/${convertToGetParams(params)}`),
    saveGrid: (data) => requests.post('/monitor/gridData', data),
    edit: (id, data) => requests.put(`/monitor/${id}`, data),
    getGridData: (id) => requests.get(`/monitor/getDataToUpdate/${id}`),
    getPDF: (id, params) => requests.get(`/monitor/gridDataPdf/${id}/${convertToGetParams(params)}`),
    report: (id) => requests.get(`/monitor/report/${id}`)
};

const subscriptions = {
    list: (params) => requests.get(`/subscriptions${convertToGetParams(params)}`),
    load: (id) => requests.get(`/subscriptions/${id}`),
    remove: (id) => requests.del(`/subscriptions/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/subscriptions/', values, true) : requests.put(`/subscriptions/${values.id}`, values),
}

const TaskTemplates = {
    list: (params) => requests.get(`/task_templates${convertToGetParams(params)}`),
    load: (uuid) => requests.get(`/task_templates/get_details/${uuid}`),
    loadView: (uuid, params) => requests.get(`/task_templates/get_template_view/${uuid}${convertToGetParams(params)}`),
    remove: (uuid) => requests.del(`/task_templates/${uuid}`),
    save: (values) => requests.post('/task_templates/', values),
    assign: (values) => requests.post('/task_templates/assign_task_templates', values),
}

const ShiftAllowance = {
    list: (params) => requests.get(`/shift_allowance${convertToGetParams(params)}`),
    loadGroup: (params) => requests.get(`/shift_allowance/loadGroup${convertToGetParams(params)}`),
    remove: (uuid) => requests.del(`/shift_allowance/${uuid}`),
    save: (values, isAdd) => isAdd ? requests.post('/shift_allowance/', values)
        : requests.put(`/shift_allowance/${values.editId}`, values),
    removeEmergencyShift: (uuid) => requests.del(`/emergency_shifts/${uuid}`),
    getShiftAllowanceCount: (params) => requests.get(`/shift_allowance/getShiftAllowanceCount${convertToGetParams(params)}`),
    syncShiftAllowance: (uuid) => requests.put(`/shift_allowance/syncShiftAllowance/${uuid}`),
}

const ServiceRequests = {
    getAvailableClients: (email) => requests.get(`/customers/getAvailableClients?email=${email}`),
    signInWithEmail: (data) => requests.post('/customers/getOTP', data),
    loginWithOtp: (data) => requests.post('/customers/loginWithOtp', data),
    loadCustConfig: () => requests.get('/customers/loadCustomerConfig'),
    saveServiceRequest: (values, isAdd, isCustomer) => !isCustomer ? isAdd ? requests.post('/serviceRequests', values)
        : requests.put(`/serviceRequests/${values.id}`, values) : isAdd ? requests.post('/customers/serviceRequests', values)
        : requests.put(`/customers/serviceRequests/${values.id}`, values),
    list: (params) => params.user_type ? requests.get(`/serviceRequests${convertToGetParams(params)}`) : requests.get(`/customers/serviceRequests${convertToGetParams(params)}`),
    load: (uuid, user_type, extendValue) => user_type ? requests.get(`/serviceRequests/${uuid}${extendValue ? `?extend=${extendValue}` : ''}`) : requests.get(`/customers/serviceRequests/${uuid}`),
    saveStatus: (values) => requests.put(`/serviceRequests/handleStatus/${values.id}`, values),
    saveExtendRequest: (values) => requests.put(`/serviceRequests/extendRequest/${values.id}`, values),
    remove: (id) => requests.del(`/serviceRequests/${id}`),
    uploadFile: (params) => requests.post('/customers/attachments', params),
    assignMembers: (values) => requests.put(`/serviceRequests/assignMembers/${values.id}`, values),
    loadMemberCountForShiftAndLeaves: () => requests.get(`/serviceRequests/fetchMemberShiftsAndLeaves`),
    removeAssignedMember: (value) => requests.put(`/serviceRequests/removeAssignedMember/${value.serviceRequestId}`, value),
}

const agent = {
    config,
    Auth,
    Clients,
    Users,
    BizTypes,
    Dashboard,
    Projects,
    Chat,
    Tasks,
    Timelogs,
    Vacations,
    Absences,
    Locked,
    Schedule,
    Deviation,
    ExtraDoc,
    checklist,
    convertToGetParams,
    documents,
    visitors,
    Recipients,
    Signature,
    contracts,
    contractslist,
    Monitor,
    subscriptions,
    TaskTemplates,
    UserGroups,
    ShiftAllowance,
    ServiceRequests
};

export default agent;