import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Row, Col, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import ManagesMembersForm from './ManagesMembersForm';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter';
import UserProfileLogo from '../../../components/UserProfileLogo';
import UserImport from './UserImport';
import ManageGroups from './UserGroupManagement.js'
import UserPermissions from './UserPermissions.js'

// Elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input, Textarea } from '../../../elements/Input';
import StatusBox from '../../../elements/StatusBox';
import { users_statuses } from '~/utils/normalStatuses';
import LoadingSpinner from '~/elements/LoadingSpinner';

//services
import config from '~/library/config';
import agent from '~/library/agent';

//assets
import Edit from '../../../assets/img/editing.svg';
import addUser from '../../../assets/img/addUser.svg';
import InactivateIcon from '../../../assets/img/InactiveUser.svg';
import activateIcon from '../../../assets/img/ActivateUser.svg';
import RemoveUserIcon from '../../../assets/img/RemoveUserIcon.svg';
import AddressBookIcon from '../../../assets/img/AddressBookIcon.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';
import MobileCheckIcon from '../../../assets/img/mobileDeviceCheck.svg';
import MobileCrossIcon from '../../../assets/img/mobileDeviceCross.svg';
import DesktopDeviceIcon from '../../../assets/img/desktopDeviceCheck.svg';
import Sync from '../../../assets/img/Sync.svg'
import AwaitingClock from '../../../assets/img/AwaitingClock.svg';

// utils //
import { formatBankAccountNumber } from '../../../utils/commonMethods';
import { getIntegrationProduct } from '~/utils/integrationUtils';
import { MultiCheckSGV } from '../../../utils/constants';
import { checkUserPermissions } from '../../../library/stores/userStore.js';


class UserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nowEditingId: null,
            currenttab: null,
            isModalOn: false,
            email: '',
            message: '',
            showOnlyInactiveUser: false,
            isLoading: true,
            showImportComponent: false,
            showUserGroupManagementModal: false,
            showUserPermissionModal: false
        };
        this.handleModalState = this.handleModalState.bind(this);
    }

    componentDidMount() {
        this.loadData()
    }
    loadData() {
        if (!this.props.router.location?.state?.dashboardRedirected) return this.setState({ isLoading: false })
        if (this.props.router.location?.state?.dashboardRedirected === 'users_not_at_work') {
            this.props.userStore.setFilter('status', `${this.props.router.location?.state?.dashboardRedirected}`);
            this.props.userStore.onFilter()
            this.setState({ isLoading: false })
        }
    }

    startEdit = (id) => {
        this.setState({ nowEditingId: id });
    };

    handleTableButtonAction = (id, type) => {
        if (type === 'remove') {
            return this.props.handleDelete(id, (id) => {
                return this.props.userStore.remove(id).then((res) => {
                    this.props.userStore.resetLastListLoadTime(new Date());
                    return res;
                });
            });
        }

        if (type === 'loginas') {
            this.props.authStore
                .loginAs(id)
                .then(() => {
                    window.location.href = `${window.location.href.split('/admin')[0]}/admin/?reload`;
                })
                .catch((e) => {
                    this.props.commonStore.addNotification(e.message || this.props.t('Error'), null, 'error');
                });
            return;
        }
    };

    handleSyncWithIntegration = (id, action) => {
        const { userStore, t, commonStore } = this.props;
        let str = `${t(`Do You want to ${action} Member with`)} ${commonStore.config?.integration_details?.product}`
        if (action === 'Sync') {
            confirmAlert({
                title: this.props.t('Confirm to Sync'),
                message: str,
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: () => {
                            return userStore.saveIntoIntegration({ id: id }).then((res2) => {
                                userStore.resetLastListLoadTime(new Date());
                                commonStore.addNotification(`${t(res2.message)} ${commonStore.config?.integration_details?.product}`, null, 'success');
                                return res2;
                            }).catch(() => {
                                this.props.onAddEdit(id);
                            });
                        },
                    },
                    {
                        label: this.props.t('No'),
                        onClick: () => { },
                    },
                ],
            })
        }
        else {
            str = `${t('Please Note that Desyncing the user will not remove the hours from Time&Control or integration product. But it will desync the hours from Time&Control')}`
            confirmAlert({
                title: this.props.t(`Confirm to ${action}`),
                message: str,
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: () => {
                            return userStore.desyncIntegration({ id }).then((res2) => {
                                userStore.resetLastListLoadTime(new Date());
                                commonStore.addNotification(`${t(res2.message)} ${commonStore.config?.integration_details?.product}`, null, 'success');
                                return res2;
                            }).catch(() => {
                                this.props.onAddEdit(id);
                            });
                        },
                    },
                    {
                        label: this.props.t('No'),
                        onClick: () => { },
                    },
                ],
            })
        }
    }

    validateRoleAccess = (action) => {
        const userStore = this.props.userStore
        switch (userStore.currentUser.user_type) {
            case 'member':
                return false
            case 'pm':
                return checkUserPermissions('md_User', action);
            default:
                return true;
        }
    }

    renderTableActionButtons = (id, user_type, status, register_type, int_id, int_status, int_data, currentUserType) => (
        <div className="actions-center">
            {this.props.commonStore.config?.integration_details?.product && status === 'active' ?
                user_type !== 'admin' ? int_id ?
                    <img
                        src={getIntegrationProduct(this.props.commonStore.config.integration_details.product)}
                        width={24}
                        height={24}
                        alt='int'
                        title={`${this.props.t('Synced with')} ${this.props.commonStore.config?.integration_details?.product}`}
                        className='m-1'
                        onClick={() => this.handleSyncWithIntegration(id, 'Desync')}
                    />
                    :
                    int_status === 'failed' ?
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {`${int_data.validationResults[0].message ?
                                        int_data.validationResults[0].message
                                        : int_data.message ?
                                            int_data.message
                                            :
                                            this.props.t('Something went wrong')
                                        } from Visma`}
                                </Tooltip>
                            }
                        >
                            <div className='ml-10'>
                                <i className="fa fa-exclamation m-2" />
                            </div>
                        </OverlayTrigger>
                        :
                        int_status === 'pending' ?
                            <div className='m-1' title={`${this.props.t("Waiting")} ${this.props.t("For")} ${this.props.t("Status")} ${this.props.t("From")} ${this.props.commonStore.config?.integration_details?.product}`}>
                                <img src={AwaitingClock} alt="clock" />
                            </div>
                            :
                            <div>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {!this.validateRoleAccess('manage') ?
                                                this.props.t('Unauthorized access! Please Contact Administrator.')
                                                :
                                                this.props.t('Sync with integration')
                                            }
                                        </Tooltip>
                                    }
                                >
                                    <Button icon_sm fill style={{ width: 32 }}
                                        disabled={!this.validateRoleAccess('manage')}
                                        onClick={() => this.handleSyncWithIntegration(id, 'Sync')}>
                                        <img src={Sync} alt='sync' />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                    :
                    <></>
                :
                <></>
            }
            {user_type !== 'admin' && status === 'active' && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{!this.validateRoleAccess('manage') ?
                        this.props.t('Unauthorized access! Please Contact Administrator.')
                        : this.props.t('Login as')}
                    </Tooltip>}
                >
                    <Button icon_sm fill
                        disabled={!this.validateRoleAccess('manage')}
                        onClick={() => this.handleTableButtonAction(id, 'loginas')}
                    >
                        <img src={addUser} alt={'login as user'} />
                    </Button>
                </OverlayTrigger>
            )}
            {user_type === 'pm' && status === 'active' && (
                <>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">
                            {!this.validateRoleAccess('manageMembers') ?
                                this.props.t('Unauthorized access! Please Contact Administrator.')
                                : this.props.t('Assign Members to PM')}
                        </Tooltip>}
                    >
                        <Button icon_sm fill
                            disabled={!this.validateRoleAccess('manageMembers')}
                            onClick={() => this.startEdit(id)}>
                            <img src={AddressBookIcon} alt={'addressbook'} />
                        </Button>
                    </OverlayTrigger>
                    {currentUserType === 'admin' &&
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{this.props.t('User Permissions')}</Tooltip>}
                        >
                            <Button icon_sm fill
                                onClick={() => this.handleModalState('showUserPermissionModal', id, false)}>
                                {MultiCheckSGV({ width: 17, fillColor: "#2550ac" })}
                            </Button>
                        </OverlayTrigger>
                    }
                </>
            )}
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{!this.validateRoleAccess('manage') ?
                    this.props.t('Unauthorized access! Please Contact Administrator.')
                    : this.props.t('Update Details')}
                </Tooltip>}
            >
                <Button icon_sm fill
                    disabled={!this.validateRoleAccess('manage')}
                    onClick={() => this.props.onAddEdit(id)}>
                    <img src={Edit} alt={'edit'} />
                </Button>
            </OverlayTrigger>
            {status === 'inactive' && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{!this.validateRoleAccess('remove') ?
                        this.props.t('Unauthorized access! Please Contact Administrator.') :
                        this.props.t('Remove')}
                    </Tooltip>}
                >
                    <Button icon_sm_delete fill
                        disabled={!this.validateRoleAccess('remove')}
                        onClick={() => this.handleTableButtonAction(id, 'remove')}>
                        <img src={RemoveUserIcon} alt={'Removeusericon'} />
                    </Button>
                </OverlayTrigger>
            )}
            {register_type !== 'invited' && (
                <div>
                    {status === 'active' ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{!this.validateRoleAccess('manage') ?
                                this.props.t('Unauthorized access! Please Contact Administrator.')
                                : this.props.t('Inactivate')}
                            </Tooltip>}
                        >
                            <Button icon_sm fill
                                disabled={!this.validateRoleAccess('manage')}
                                onClick={() => this.handleActiveInactive(id, 'inactive')}
                            >
                                <img src={InactivateIcon} alt="InactivateUser" />
                            </Button>
                        </OverlayTrigger>
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{!this.validateRoleAccess('manage') ?
                                this.props.t('Unauthorized access! Please Contact Administrator.')
                                : this.props.t('Activate')}
                            </Tooltip>}
                        >
                            <Button icon_sm fill
                                disabled={!this.validateRoleAccess('manage')}
                                onClick={() => this.handleActiveInactive(id, 'active')}
                            >
                                <img src={activateIcon} alt="ActivateUser" />
                            </Button>
                        </OverlayTrigger>
                    )}
                </div>
            )}
        </div>
    );

    handleActiveInactive = (id, value) => {
        const { t, commonStore, userStore } = this.props;
        let hydrated = {
            id: id,
            status: value,
        };
        confirmAlert({
            title: this.props.t('Confirm to Change User status'),
            message: 'Are You Sure you want to modify the user status',
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        return userStore.setActiveInactive(hydrated).then((res) => {
                            this.props.userStore.resetLastListLoadTime(new Date());
                            commonStore.addNotification(
                                t('Updated user status to ') + `${hydrated.status}`,
                                null,
                                'success'
                            );
                            return res;
                        });
                    },
                },
                {
                    label: this.props.t('No'),
                    onClick: () => { },
                },
            ],
        });
    };

    loadlistbystatus = (st) => {
        if (this.state.currenttab === st) return;
        else {
            this.setState({ currenttab: st });
            if (st !== null) {
                if (st === 'all') {
                    this.setState({ showOnlyInactiveUser: false });
                }
                if (st === 'inactive') {
                    this.setState({ showOnlyInactiveUser: true });
                }
                this.props.userStore.setFilter('status', st);
                this.props.userStore.onFilter();
            } else {
                if (st === null) {
                    this.setState({ showOnlyInactiveUser: false });
                }
                this.props.userStore.setFilter('status', '');
                this.props.userStore.onFilter();
            }
            return;
        }
    };

    getUserReport = () => {
        let hydrated = {
            authorization: this.props.commonStore.token,
        };
        window.open(`${config.API_ROOT}/users/report${agent.convertToGetParams(hydrated)}`);
    };

    handleInvite = () => {
        this.setState({ isModalOn: true });
    };

    handleCloseModal = () => {
        this.setState({ isModalOn: false });
    };

    handleChange = (name, value) => {
        if (name === 'email') {
            this.setState({ email: value });
        }
        if (name === 'message') {
            this.setState({ message: value });
        }
    };

    onShare = () => {
        const { userStore, t, commonStore } = this.props;
        if (this.state.email === '') {
            return commonStore.addNotification(t('Please Fill All the Fields'), null, 'warning');
        }
        const state = Object.assign({}, this.state);
        state.email = this.state.email;
        state.message = this.state.message;
        userStore.saveInviteUser(state).then((result) => {
            if (!result || !result.result.id) {
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return true;
            }
            commonStore.addNotification(t('Mail Sent Successfully'), null, 'success');
        });
        this.setState({ isModalOn: false, email: '' });
    };

    copyLink = () => {
        const { t, commonStore, userStore } = this.props;
        if (userStore.currentList.inviteLink && userStore.currentList.inviteLink !== '') {
            navigator.clipboard.writeText(userStore.currentList.inviteLink);
            commonStore.addNotification(t('Link has been copied!'), null, 'success');
        } else {
            commonStore.addNotification(
                t('There are some issue in link, please refresh and try again or contact admin.'),
                null,
                'error'
            );
        }
    };

    renderHeader = () => {
        const { userStore, t, onAddEdit } = this.props;
        const { config, addNotification } = this.props.commonStore;
        return (
            <Row className="align-content-center mt-2 gx-0">
                {userStore.currentList && userStore.currentList.total && config.client && (
                    <Col xs={12} lg={7}>
                        <Row className="member_status__summary">
                            <Col xs={4}
                                className="mt-2 mt-sm-0 justify-content-center justify-content-sm-start justify-content-lg-center"
                            >
                                <div
                                    className="highlightedgreen"
                                    role={'button'}
                                    onClick={() => this.loadlistbystatus('active')}
                                >
                                    {t('Active members')}: {userStore.currentList.activeuser}
                                </div>
                            </Col>
                            <Col xs={4} className="mt-2 mt-sm-0 justify-content-center">
                                <div
                                    className="highlightedred"
                                    role={'button'}
                                    onClick={() => this.loadlistbystatus('inactive')}
                                >
                                    {t('Inactive members')}: {userStore.currentList.inactiveuser}
                                </div>
                            </Col>
                            <Col xs={4}
                                className="mt-2 mt-sm-0 justify-content-center justify-content-sm-end justify-content-lg-center"
                            >
                                <div className="highlighted" role={'button'} onClick={() => this.loadlistbystatus('')}>
                                    {t('Allowed users')}: {config.client.max_users_requested}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col
                    xs={12} lg={5}
                    className="mt-2 mt-lg-0 text-start text-lg-end"
                >
                    <Row className="gx-0 genericlist_header_xxl_auto justify-content-lg-end" >
                        <Col xs={4} className="text-end custom-style-userlist">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {!this.validateRoleAccess('manage') ?
                                            this.props.t('Unauthorized access! Please Contact Administrator.')
                                            :
                                            this.props.t('Open to view more actions')
                                        }
                                    </Tooltip>
                                }
                            >
                                <Dropdown className="d-inline mx-2">
                                    <Dropdown.Toggle id="dropdown-autoclose-inside"
                                        disabled={!this.validateRoleAccess('manage')}
                                    >
                                        {t('More Actions')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => this.getUserReport()}
                                            title={t("Download Report")}
                                        >
                                            <div>
                                                <i className="fa fa-file-pdf-o me-3" width="25px" height="25px" style={{ color: '#2550ac' }} />
                                                {this.props.t('Download')}
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            title={t('Manage Groups')}
                                            onClick={() => this.setState({ showUserGroupManagementModal: true })}
                                        >
                                            <div>
                                                <i className="fa fa-users me-3" width="25px" height="25px" style={{ color: '#2550ac' }} />
                                                {this.props.t('Manage Groups')}
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            title={t('Invite members to join using a quick link')}
                                            onClick={() => this.handleInvite()}>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" className='margin-right-10'>
                                                    <path fill="#2550ac" d="M16.2 6a1 1 0 0 1 1-1c.637 0 1.262.128 1.871.372c.663.265 1.173.658 1.636 1.12c.463.464.856.974 1.121 1.637c.244.609.372 1.234.372 1.871s-.128 1.262-.372 1.871c-.261.655-.648 1.16-1.104 1.619l-.984 1.083a.967.967 0 0 1-.033.034l-3.2 3.2c-.463.463-.973.856-1.636 1.122A5.012 5.012 0 0 1 13 19.3a5.012 5.012 0 0 1-1.871-.372c-.663-.265-1.173-.658-1.636-1.12c-.463-.464-.856-.974-1.121-1.637A5.145 5.145 0 0 1 8 14.2c0-.637.128-1.262.372-1.871c.265-.663.658-1.173 1.12-1.636l1.1-1.1a1 1 0 1 1 1.415 1.414l-1.1 1.1c-.337.337-.544.627-.678.964A3.014 3.014 0 0 0 10 14.2c0 .476.077.85.229 1.229c.134.337.341.627.678.964c.337.337.627.544.964.678c.391.157.766.229 1.129.229s.738-.072 1.129-.229c.337-.134.627-.341.964-.678l3.183-3.183l.984-1.083a.967.967 0 0 1 .033-.034c.337-.337.544-.627.678-.964c.157-.391.229-.766.229-1.129s-.072-.738-.229-1.129c-.134-.337-.341-.627-.678-.964c-.337-.337-.627-.544-.964-.679A3.014 3.014 0 0 0 17.2 7a1 1 0 0 1-1-1zm-4.9 1.5c-.363 0-.738.072-1.129.228c-.337.135-.627.342-.964.68L5.924 11.69l-.984 1.083l-.033.034c-.337.337-.544.627-.679.964A3.014 3.014 0 0 0 4 14.9c0 .363.072.738.228 1.129c.135.337.342.627.68.964c.336.337.626.544.963.679c.391.156.766.228 1.129.228a1 1 0 1 1 0 2a5.011 5.011 0 0 1-1.871-.371c-.663-.266-1.173-.659-1.636-1.122c-.463-.463-.856-.973-1.121-1.636A5.012 5.012 0 0 1 2 14.9c0-.637.128-1.262.372-1.871c.261-.655.648-1.16 1.104-1.619l.984-1.083l.033-.034l3.3-3.3c.463-.463.973-.856 1.636-1.121A5.012 5.012 0 0 1 11.3 5.5c.637 0 1.262.128 1.871.372c.663.265 1.173.658 1.636 1.12c.463.464.856.974 1.121 1.637c.244.609.372 1.234.372 1.871s-.128 1.262-.372 1.871c-.262.655-.649 1.162-1.105 1.62l-1.086 1.185a1 1 0 0 1-1.474-1.352l1.1-1.2l.03-.031c.337-.337.544-.627.678-.964c.157-.391.229-.766.229-1.129s-.072-.738-.229-1.129c-.134-.337-.341-.627-.678-.964c-.337-.337-.627-.544-.964-.679A3.014 3.014 0 0 0 11.3 7.5z"></path>
                                                </svg>
                                                {t('Invite')}
                                            </div>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={() => {
                                                if (!this.props.commonStore.config?.integration_details?.product) {
                                                    return addNotification(t('Please set up integration to import employees'), null, 'warning', "top-right", 2000)
                                                }
                                                this.setState((state) => ({ showImportComponent: !state.showImportComponent }))
                                            }}
                                            title={this.props.commonStore.config?.integration_details?.product ? ` ${t('Import Employees from')} ${this.props.commonStore.config.integration_details.product}` : t("Please set up integration to import employees")}
                                            className={this.props.commonStore.config?.integration_details?.product ? "" : "drop-down-item-disabled"}
                                        >
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" className='margin-right-10'>
                                                    <path fill="#2550ac" d="M10 2h4a2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8c0-1.11.89-2 2-2h4V4c0-1.11.89-2 2-2m4 4V4h-4v2h4m-2 3l-5 5h3v4h4v-4h3l-5-5Z"></path>
                                                </svg>
                                                {this.props.t('Import')}
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={4} xxl={3} className="text-end">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {!this.validateRoleAccess('create') ?
                                            this.props.t('Unauthorized access! Please Contact Administrator.')
                                            :
                                            this.props.t('Add new')
                                        }
                                    </Tooltip>
                                }
                            >
                                <Button fill
                                    disabled={!this.validateRoleAccess('create')}
                                    onClick={() => onAddEdit()}>
                                    <div className="btn-container">
                                        <i className="fa fa-plus-circle fa-xl me-2" />
                                        <span>{this.props.t('Add new')}</span>
                                    </div>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Col >
            </Row >
        );
    };

    renderSuperAdminHeader = () => {
        const { onAddEdit } = this.props;
        return (
            <Button fill onClick={() => onAddEdit()}>
                <div className="btn-container">
                    <i className="fa fa-plus-circle fa-xl me-2" />
                    <span>{this.props.t('Add new')}</span>
                </div>
            </Button>
        );
    };

    //handles generic list page change//
    handlePageChange = (page, isEdit) => {
        this.props.handlePageChange(page, isEdit);
    };

    componentWillUnmount() {
        this.props.userStore.resetLastListLoadTime(null);
        this.props.userStore.setFilter("", "", true)
        this.props.userStore.onFilter()
    }

    handleModalState = (stateName, state, resetPage = false) => {
        this.setState({ [stateName]: state });
        resetPage && this.props.userStore.resetLastListLoadTime(new Date());
    }

    render() {
        const { mode, t } = this.props;
        const { nowEditingId, showImportComponent, showUserGroupManagementModal, showUserPermissionModal } = this.state;

        const { userStore, commonStore } = this.props;
        const currentUserType = userStore.currentUser.user_type;
        if (currentUserType === 'member') {
            return <div>Not Allowed</div>;
        }
        const { appliedFilters, loading, filters } = userStore;
        const clientConfig = commonStore.config.client.data;
        const isMobileAccessDisabled = clientConfig && clientConfig.loginRules ? clientConfig.loginRules.disableMobileAccess : false;
        if (this.state.isLoading) return <LoadingSpinner />;

        return (
            <>
                <Fragment>
                    <GenericList
                        columns={[
                            {
                                Header: '',
                                id: 'image',
                                accessor: ({ image }) => <UserProfileLogo image_id={image ? image : false} />,
                                maxWidth: 60,
                            },
                            {
                                Header: 'Name',
                                id: 'first_name',
                                accessor: ({ id, first_name, last_name, user_type, allow_mobile_app_access, status }) => (
                                    <>
                                        <span
                                            role={this.validateRoleAccess('manage') ? 'link' : 'none'}
                                            className="list-main-column"
                                            title={this.validateRoleAccess('manage') ? t('View employee') : t('Not enough acceess to view')}
                                            disabled={!this.validateRoleAccess('manage')}
                                            onClick={() => this.validateRoleAccess('manage') && this.props.onAddEdit(id)}
                                        >
                                            {status === 'active' && user_type !== 'super-admin' && user_type !== 'admin' && isMobileAccessDisabled && (
                                                <img src={allow_mobile_app_access ? MobileCheckIcon : MobileCrossIcon} alt={'mobile access icon'}
                                                    title={allow_mobile_app_access ? 'Mobile Access Enabled' : 'Mobile Access Disabled'}
                                                    className='image_icon_size_20 me-2' />
                                            )}
                                            {status === 'active' && user_type === 'admin' && isMobileAccessDisabled && (
                                                <img src={DesktopDeviceIcon} alt={'mobile access icon'} className='image_icon_size_20 me-2' />
                                            )}
                                            {`${first_name} ${last_name}`}
                                        </span>
                                    </>

                                ),
                                isSortable: true,
                                minWidth: 100,
                                maxWidth: 130,
                                textAlign: 'start',
                            },
                            {
                                Header: 'Email',
                                id: 'email',
                                accessor: ({ email }) => <span>{email}</span>,
                                isSortable: true,
                                maxWidth: 180,
                                minWidth: 150,
                                textAlign: 'start',
                            },
                            {
                                Header: 'Phone',
                                id: 'phone',
                                accessor: 'phone',
                                isSortable: true,
                                maxWidth: 80,
                                minWidth: 100,
                            },
                            {
                                Header: 'Bank Account',
                                id: '',
                                accessor: ({ bank_account_number }) => (
                                    <span>
                                        {bank_account_number ? formatBankAccountNumber(bank_account_number) : ""}
                                    </span>
                                ),
                                isSortable: true,
                                maxWidth: 80,
                                minWidth: 100,
                            },
                            {
                                Header: 'Role',
                                id: 'user_type',
                                accessor: 'user_type',
                                isSortable: true,
                                maxWidth: 100,
                                minWidth: 80,
                            },
                            {
                                Header: 'Status',
                                id: 'status',
                                accessor: ({ status, register_type }) =>
                                    register_type === 'invited' ? (
                                        <StatusBox status={'pending' || ''} />
                                    ) : (
                                        <StatusBox status={status || ''} />
                                    ),
                                isSortable: true,
                                maxWidth: 100,
                                minWidth: 80,
                            },
                            {
                                Header: 'Operation',
                                id: 'operation',
                                sortable: false,
                                accessor: ({ id, user_type, status, register_type, int_id, int_status, int_data }) =>
                                    this.renderTableActionButtons(id, user_type, status, register_type, int_id, int_status, int_data, currentUserType),
                                maxWidth: 200,
                                minWidth: 150,
                            },
                        ]}
                        tableHeader={this.props.t(mode === 'superadmins' ? 'Super-admins' : 'Users')}
                        lastListLoadTime={this.props.userStore.lastListLoadTime}
                        forceReload={this.props.userStore.deleteSuccess}
                        header={
                            <GenericFilter
                                filters={filters}
                                setFilter={(name, value) => userStore.setFilter(name, value)}
                                onFilter={() => userStore.onFilter()}
                                loading={loading}
                                hasStatusFilter={currentUserType === 'super-admin' ? false : true}
                                statusOptions={users_statuses}
                            />
                        }
                        filters={appliedFilters}
                        requestData={(params) => this.props.userStore.loadList(params, mode)}
                        handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)} //handles page change
                        page={this.props.page} //new page props to maintain page from parent//
                        isPageAddedOrEdited={this.props.isPageAddedOrEdited} //to load same page after edit//
                        splitHeader={mode !== 'superadmins'}
                    >
                        {mode === 'superadmins' ? this.renderSuperAdminHeader() : this.renderHeader()}
                    </GenericList>

                    <Modal size="lg" show={!!nowEditingId} onHide={() => this.setState({ nowEditingId: null })}>
                        <Modal.Header>
                            <Modal.Title className="w-100 text-center m-0">
                                {this.props.t('Assign Members to PM')}
                            </Modal.Title>
                            <img
                                src={CloseModalIcon}
                                className="cursor-pointer"
                                onClick={() => this.setState({ nowEditingId: null })}
                                alt={'cross-Icon'}
                            />
                        </Modal.Header>

                        <Modal.Body style={{ minHeight: '80vh' }}>
                            <ManagesMembersForm
                                id={nowEditingId}
                                afterSave={() => this.setState({ nowEditingId: null })}
                            />
                        </Modal.Body>
                    </Modal>

                    <Modal size="lg" show={!!showUserGroupManagementModal} onHide={() => this.setState({ showUserGroupManagementModal: false })}>
                        <Modal.Header>
                            <Modal.Title className="w-100 text-center m-0">
                                {this.props.t('Manage Groups')}
                            </Modal.Title>
                            <img
                                src={CloseModalIcon}
                                className="cursor-pointer"
                                onClick={() => this.setState({ showUserGroupManagementModal: false })}
                                alt={'cross-Icon'}
                            />
                        </Modal.Header>
                        <Modal.Body style={{ minHeight: '50vh' }}>
                            <ManageGroups />
                        </Modal.Body>
                    </Modal>
                </Fragment>

                <Modal show={this.state.isModalOn} onHide={this.handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{t('Invite member to Join')}</Modal.Title>
                        <img src={CloseModalIcon} alt="close icon" className="cursor-pointer" onClick={this.handleCloseModal} />
                    </Modal.Header>
                    <Modal.Body>
                        <Input
                            label={`${t('Enter Email')}*`}
                            name="emails"
                            placeholder={t('[Email 1], [Email 2]')}
                            value={this.state.email}
                            onChange={(e) => this.handleChange('email', e.target.value)}
                        />
                        <span>You can add multiple Email-ID Here by adding comma(,) between two Email Address</span>
                        <Textarea
                            className="mt-10"
                            label={`${t('Write Comment')}`}
                            value={this.state.message}
                            style={{ width: '100%' }}
                            name="message"
                            onChange={(e) => this.handleChange('message', e.target.value)}
                            placeholder={t('Write Comment')}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.copyLink()} className="btn-wd btn-fill">
                            {t('Copy Link')} <i className="fa fa-link" />
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={(values) => this.onShare(values)}
                            className="btn-wd btn-fill"
                        >
                            {t('Share')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleCloseModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {showImportComponent &&
                    <UserImport
                        handleModalState={this.handleModalState}
                        modalState={showImportComponent}
                    />
                }
                {!!showUserPermissionModal &&
                    <UserPermissions
                        handleModalState={this.handleModalState}
                        userId={showUserPermissionModal}
                    />
                }
            </>
        );
    }
}
export default inject(
    'userStore',
    'commonStore',
    'authStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(UserList)))));
