import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import CopyWeekModal from './CopyForm';
import ShiftAllowanceManagement from './ShiftAllowanceManagement';

// Widgets
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';

//elements
import Select from '../../../elements/Select';
import CustomButtonCheckbox from '../../../components/CustomButtonCheckbox/CustomButtonCheckbox';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import CustomButton from '../../../components/CustomButton/CustomButton';

//utils
import { timeFormat } from '../../../utils/dateFormat';
import scheduleConfig from '../../../components/Scheduler/config'
import { ClockCheckIcon } from '../../../utils/constants'

//assets
import CloseIcon from '~/assets/img/modal-close-icon.svg';
import Delete from '~/assets/img/deleting.svg';
import ScheduleFilterIcon from '~/assets/img/schedule-filter.svg'
import DatePickerIcon from '~/assets/img/date-picker-icon.svg';
import ScheduleCopy from '~/assets/img/schedule-copy.svg';
import BulkShift from '~/assets/img/bulk-shift.svg';
import ManagementIcon from '~/assets/img/management.svg';
import MorningBlack from '~/assets/img/MorningBlack.svg';
import MorningWhite from '~/assets/img/MorningWhite.svg';
import AfternoonBlack from '~/assets/img/AfternoonBlack.svg';
import AfternoonWhite from '~/assets/img/AfternoonWhite.svg';
import NightBlack from '~/assets/img/NightBlack.svg';
import NightWhite from '~/assets/img/NightWhite.svg';

const scheduleCalenderTypeOptions = [{ value: '1', label: 'Week', title: 'Week' },
{ value: '2', label: 'Month', title: 'Month' }];
const scheduleResourceSortingOptions = [{ value: 'sortableName', label: 'Sort By Members', title: 'Sort By Members' },
{ value: 'start_time', label: 'Sort By Start Time', title: 'Sort By Start Time' }];
const scheduleResourceFilterOptions = [{ value: null, label: 'All', title: 'All' },
{ value: true, label: 'Scheduled', title: 'Scheduled' },
{ value: false, label: 'Not Scheduled', title: 'Not Scheduled' }];
const scheduleShiftTypeFilter = [{ value: '4,12', label: 'Morning', title: "Morning", image: MorningBlack, imageSelected: MorningWhite },
{ value: '12,20', label: 'Afternoon', title: "Afternoon", image: AfternoonBlack, imageSelected: AfternoonWhite },
{ value: '20,4', label: 'Night', title: "Night", image: NightBlack, imageSelected: NightWhite }];
const pmManagedShiftsOptions = [{ value: null, label: 'All Shifts', title: 'All Shifts' },
{ value: true, label: 'Managed Shifts', title: 'Managed Shifts' },
{ value: false, label: 'View Only Shifts', title: 'View Only Shifts' }];

class ScheduleWeekHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project_id: props.scheduleStore.filters.project,
            sortFilterValue: props.scheduleStore.filters.sortFilterValue,
            resourceFilterValue: props.scheduleStore.filters.resourceFilterValue,
            pmManagedShiftsFilterValue: props.scheduleStore.filters.pmManagedShiftsFilterValue,
            dayDurationFilterValue: props.scheduleStore.filters.dayDurationFilterValue,
            showFilterMenu: false,
            copying: false,
            showShiftAllowanceManagement: false
        }
    }

    onViewChange = (e) => {
        const { onViewChange, schedulerData } = this.props;
        const viewType = e.value;
        const isEventPerspective = e.value === 1;
        onViewChange(schedulerData, { viewType, isEventPerspective });
    };

    async handleProjectChange(value) {
        const { projectStore } = this.props;
        if (value) {
            await projectStore.getProjectMembersIds(value).then(
                (data) => {
                    let projectMemberIds = data.membersArray || [];
                    this.props.scheduleStore.setFilteredResources(projectMemberIds);
                    this.props.scheduleStore.setFilter("project", value);
                    this.setState({ project_id: value }, () => {
                        this.props.handleProjectChange(value);
                    });
                }
            );
        }
        else {
            this.props.scheduleStore.resetFilteredResources();
            this.props.scheduleStore.setFilter("project", "");
            this.setState({ project_id: '' }, () => {
                this.props.handleProjectChange('', []);
            });
        }
    };

    goNext = () => {
        const { nextClick, schedulerData } = this.props;
        nextClick(schedulerData);
    };

    goBack = () => {
        const { prevClick, schedulerData } = this.props;
        prevClick(schedulerData);
    };

    handleClosesFilterMenu = () => {
        this.setState((state) => ({
            showFilterMenu: !state.showFilterMenu,
        }))
    }

    handleFilterChange = (event, filterName, callBackFunction = null) => {
        this.props.scheduleStore.setFilter(filterName, event);
        this.setState({ [filterName]: event },
            () => {
                if (!callBackFunction) this.props.checkFiltersAndSetResources(this.props.schedulerData, true);
                else callBackFunction();
            })
    }

    handleApproveTimelogsButton = () => {
        const { commonStore, scheduleStore, t } = this.props;
        const selectedShifts = Object.values(scheduleStore.shiftEventSelectionData).filter(ele => ele.checked && ele.details.timelogCount);
        if (!selectedShifts.length)
            return commonStore.addNotification(t('These scheduled hours are missing on the timesheet. Please enter the missing hours before approving it'), null, 'warning');

        if (selectedShifts.every(ele => !ele.details.timelogsToBeApprovedCount))
            return commonStore.addNotification(t("Selected hours are already approved or in progress"), null, 'warning');

        this.props.handleTimesheetApprovalModal()
    }

    translateLabels() {
        const { t } = this.props;
        const optionsArrayToBeTranslated = [scheduleConfig.scheduleListingViews, scheduleCalenderTypeOptions,
            scheduleResourceSortingOptions, scheduleResourceFilterOptions, scheduleShiftTypeFilter, pmManagedShiftsOptions];
        optionsArrayToBeTranslated.forEach(_array => _array.forEach(item => {
            item.label = t(item.title);
        }));
    }

    parseShiftTimeToDateTimeFormat = (shiftTimeRangeString, getDateTimeRules) => {
        const shiftTimeRangeInArray = shiftTimeRangeString.split(',').map(e => timeFormat(Number(e) * 60, getDateTimeRules()));
        return shiftTimeRangeInArray.join(' - ');
    }

    renderTools() {
        const { userStore, t, isAvailibiltyFeatureEnabled } = this.props;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';

        return (
            <>
                {!isMember ? (
                    <>
                        <CustomButton id="schedule-today-btn"
                            schedule onClick={() => this.props.filterTodayShifts()} title={t('Today')}>
                            <img className='m-0' src={DatePickerIcon} alt={"datepickericon"}/>
                        </CustomButton>
                        <CustomButton schedule id="schedule-copy-btn"
                            onClick={() => this.props.startCopyToNextWeek()} title={t('Copy')}>
                            <img className='m-0' src={ScheduleCopy} alt="Copy Icon" /></CustomButton>
                        <CustomButton schedule
                            id="schedule-bulk-shift-btn" onClick={() => this.props.newBulkEvent()} title={t('Bulk shift')}>
                            <img className='m-0' src={BulkShift} />
                        </CustomButton>
                        <CustomButton schedule
                            id="schedule-management-btn" title={t('Management')}
                            onClick={() => this.props.openManagementModal()}>
                            <img className='m-0' src={ManagementIcon} alt={"manageSettingsIcon"}/>
                        </CustomButton>
                        <CustomButton schedule id="schedule-filter-btn" icon_sm fill title={'Filter'}
                            onClick={() => this.setState((state) => ({ showFilterMenu: !state.showFilterMenu }))}
                        >
                            <img className='m-0' src={ScheduleFilterIcon} alt={"filter"}/>
                        </CustomButton>
                    </>
                ) :
                    <>
                        {isAvailibiltyFeatureEnabled &&
                            <CustomButton fill id="schedule-availability-btn"
                                onClick={() => this.props.viewAvailabilityAssign()}>
                                {t('Availability')}
                            </CustomButton>
                        }
                    </>

                }
            </>
        );
    }

    render() {
        const { t, schedulerData, getDateTimeRules, commonStore, scheduleStore, shiftAllowanceStore, userStore } = this.props;
        const { sortFilterValue, resourceFilterValue, pmManagedShiftsFilterValue, copying, showShiftAllowanceManagement } = this.state;
        const isMember = userStore.currentUser.user_type === 'member';
        const isShiftAllowanceExtraPaymentActive = commonStore.config?.client?.data?.extraPayments.some(ele => ele.code === 'shift_allowance' && ele.isActive);
        let getWeek = schedulerData.getDateWeek('1');
        let dateLabel = schedulerData.getDateLabel(1);
        this.translateLabels();
        const currentShiftsLength = this.props.scheduleStore.shiftEventSelectionData ?
            Object.keys(this.props.scheduleStore.shiftEventSelectionData).length : 0;
        const currentTimesheetsToApprove = this.props.scheduleStore.shiftEventSelectionData
            && Object.values(this.props.scheduleStore.shiftEventSelectionData).filter(ele => ele.checked).length;

        let schedulerHeader = (
            <div className="RBS-Scheduler-config">
                <div style={{ minWidth: '150px' }}>
                    <Select
                        options={scheduleConfig.scheduleListingViews}
                        defaultValue={scheduleConfig.scheduleListingViews[0]}
                        value={scheduleConfig.scheduleListingViews.find(e => e.value === scheduleStore.filters.currentView)}
                        onChange={event => this.props.onListViewChange(event)}
                    />
                </div>
                <div>
                    <div className="custom_dropdown">
                        <Select options={scheduleCalenderTypeOptions}
                            defaultValue={scheduleCalenderTypeOptions[0]}
                            onChange={event => this.onViewChange(event)} />
                    </div>
                </div>
                {!isMember &&
                    <div style={{ minWidth: '200px' }}>
                        <ProjectsWidget
                            value={this.state.project_id || 'all'}
                            onChange={(id) => this.handleProjectChange(id)}
                            allLabel={t('All projects')}
                            allowAll
                            pmMode={userStore.currentUser.user_type === 'pm' ? 'pm_members' : null}
                        />
                    </div>
                }
                <div>
                    <CustomButton schedule style={{ cursor: 'auto' }}>
                        <span className="week_block" >
                            {getWeek}
                        </span>
                        <b className="scheduleCaret rotate-90" onClick={this.goBack}></b>
                        <span className="header2-text-label">{dateLabel}</span>
                        <b className="scheduleCaret rotate-270" onClick={this.goNext}></b>
                    </CustomButton>
                </div>
                {this.renderTools()}
                <div className='d-flex'>
                    <div className='d-flex'>
                        {scheduleShiftTypeFilter.map((item) => (
                            <CustomButtonCheckbox
                                key={uuid()}
                                isChecked={item.value === this.state.dayDurationFilterValue}
                                onChange={evt => this.handleFilterChange(evt ? item.value : "", "dayDurationFilterValue", this.props.handleDayDurationChange)}
                                label={`${item.label} ( ${this.parseShiftTimeToDateTimeFormat(item.value, getDateTimeRules)} )`}
                                className="me-2"
                                image={item.value === this.state.dayDurationFilterValue ? item.imageSelected : item.image}
                            />
                        ))}
                        {!isMember &&
                            <CustomButton id="schedule-month-copy" schedule scheduleSm
                                onClick={() => this.setState({ copying: true })}
                            >
                                <i className='fa fa-copy' />
                                &nbsp;{this.props.t('Copy')}&nbsp;{this.props.t('Weeks')}
                            </CustomButton>
                        }
                        <div className='ml-10'>
                            <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-bottom">
                                        {!isShiftAllowanceExtraPaymentActive && !isMember ?
                                            t("To utilise this function, Please Activate the Rule under Additional payment Section")
                                            : !isMember ? t("Manage Shift allowance for employees")
                                                : !isShiftAllowanceExtraPaymentActive ? t("This feature is currently disabled by the administrator")
                                                    : t("View shift allowance")}
                                    </Tooltip>
                                }
                            >
                                <CustomButton id="schedule-month-copy" schedule scheduleSm
                                    onClick={() => this.setState({ showShiftAllowanceManagement: true })}
                                    disabled={!isShiftAllowanceExtraPaymentActive}
                                >
                                    <i className="fas fa-wallet m-1"></i>
                                    &nbsp;{this.props.t('Shift Allowance')}
                                    <Badge className='ms-2 bg-secondary'>{shiftAllowanceStore?.allShiftAllowance?.length}</Badge>
                                </CustomButton>
                            </OverlayTrigger>

                        </div>
                    </div>
                </div>
                {!isMember &&
                    <div className='d-flex'>
                        <div className='d-flex align-items-center me-2'>
                            <OverlayTrigger
                                key="approveTimesheetsBtn"
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-bottom">
                                        {currentTimesheetsToApprove ?
                                            t("Approve selected timesheets in shifts")
                                            : t("Please select timesheets to approve")}
                                    </Tooltip>
                                }
                            >
                                <CustomButton schedule me2
                                    id="timesheet-bulk-approve-btn"
                                    onClick={this.handleApproveTimelogsButton}
                                    disabled={!currentTimesheetsToApprove}
                                >
                                    {ClockCheckIcon({ width: 28, fillColor: '#038102' })}
                                </CustomButton>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key="deleteShiftsBtn"
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-bottom">
                                        {currentShiftsLength ?
                                            t("Delete selected Shifts")
                                            : t("No shifts to delete")}
                                    </Tooltip>
                                }
                            >
                                <CustomButton schedule id="schedule-bulk-delete-btn"
                                    onClick={() => this.props.handleMultiDelete()}
                                    disabled={!currentShiftsLength}
                                >
                                    <img className='m-0' src={Delete} alt='delete-btn' />
                                </CustomButton>
                            </OverlayTrigger>
                        </div>
                        <OverlayTrigger
                            key="selectAllShiftsBtn"
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-bottom">
                                    {currentShiftsLength ?
                                        t("Select/Deselect All Shifts")
                                        : t("No shifts to select")}
                                </Tooltip>
                            }
                        >
                            <div className='d-flex align-items-center'>
                                <CustomCheckbox
                                    isChecked={this.props.scheduleStore.isAllShiftEventSelected}
                                    onChange={value => this.props.scheduleStore.toggleSelectAllShiftEvent(value)}
                                    label={t("Select All")}
                                    disabled={!currentShiftsLength}
                                />
                            </div>
                        </OverlayTrigger>
                    </div>
                }
                <Modal
                    className="modal right fade filter modal_revised"
                    show={this.state.showFilterMenu}
                    onHide={this.handleClosesFilterMenu}
                >
                    <Modal.Header className='modal-header__revised'>
                        <div className='modal-header__revised__closebtn'
                            onClick={() => this.setState((state) => ({ showFilterMenu: !state.showFilterMenu }))} >
                            <img
                                src={CloseIcon}
                                className="cursor-pointer"
                                alt='close_button'
                            />
                        </div>
                        <Modal.Title>{t('Filter')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mt-4'>
                            <div className='d-flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27px" height="27px" viewBox="0 0 24 24"><path fill="#2550ac" d="M19 3h-1V1h-2v2H8V1H6v2H4.75c-.44.07-.85.27-1.17.58c-.31.32-.51.73-.58 1.17v14.5c.07.44.27.85.58 1.17c.32.31.73.51 1.17.58H15v-2H5V9h14v2h2V5c0-1.11-.89-2-2-2m0 4H5V5h14v2m-2 14l1.8 1.77c.5.5 1.2.1 1.2-.49V18l2.8-3.4A1 1 0 0 0 22 13h-7c-.8 0-1.3 1-.8 1.6L17 18v3"></path></svg>
                                <div className='ms-2'>
                                    {`${t('Filter')} by:`} {resourceFilterValue && !!resourceFilterValue.value ? resourceFilterValue.label : ""}
                                </div>
                            </div>
                            <Select className="min-width-100"
                                options={scheduleResourceFilterOptions}
                                value={resourceFilterValue ? resourceFilterValue : scheduleResourceFilterOptions[0]}
                                onChange={event => this.handleFilterChange(event, "resourceFilterValue")}
                            />
                        </div>
                        <div className={`${this.props.currentView ? 'opacity-50' : ''} mt-4`}>
                            <div className='d-flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27px" height="27px" viewBox="0 0 24 24"><path fill="#2550ac" d="m18 21l-4-4h3V7h-3l4-4l4 4h-3v10h3M2 19v-2h10v2M2 13v-2h7v2M2 7V5h4v2H2Z"></path></svg>
                                <div className='ms-2'>
                                    {sortFilterValue ? t(`${sortFilterValue.label}`) : ""}
                                </div>
                            </div>
                            <Select className="min-width-100"
                                options={scheduleResourceSortingOptions}
                                defaultValue={sortFilterValue ? sortFilterValue : scheduleResourceSortingOptions[0]}
                                onChange={event => this.handleFilterChange(event, "sortFilterValue")}
                                isDisabled={this.props.currentView}
                            />
                        </div>
                        {userStore.currentUser.user_type === 'pm' &&
                            <div className='mt-4'>
                                <div className='d-flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27px" viewBox="0 0 24 24"><path fill="#2550ac" d="M3 21V3h18v10.325q-.475-.275-.962-.5t-1.038-.4V11h-3.325v1q-.525.025-1.025.075t-.975.175V11h-3.35v2.775q-.6.45-1.137 1.013T8.2 16H5v3h2.05q.25.55.538 1.05T8.2 21zm2-7h3.325v-3H5zm0-5h14V5H5zm11 14q-2.275 0-4.2-1.2T9 18.5q.875-2.1 2.8-3.3T16 14t4.2 1.2t2.8 3.3q-.875 2.1-2.8 3.3T16 23m0-2q1.05 0 1.775-.725T18.5 18.5t-.725-1.775T16 16t-1.775.725T13.5 18.5t.725 1.775T16 21m0-1q-.625 0-1.063-.437T14.5 18.5t.438-1.062T16 17t1.063.438t.437 1.062t-.437 1.063T16 20"/></svg>
                                    <div className='ms-2'>
                                        {t('Show shifts')}: {pmManagedShiftsFilterValue && !!pmManagedShiftsFilterValue.value ? pmManagedShiftsFilterValue.label : ""}
                                    </div>
                                </div>
                                <Select className="min-width-100"
                                    options={pmManagedShiftsOptions}
                                    value={pmManagedShiftsFilterValue ? pmManagedShiftsFilterValue : pmManagedShiftsOptions[0]}
                                    onChange={event => this.handleFilterChange(event, "pmManagedShiftsFilterValue")}
                                />
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='w-100 ml-30'>
                            <CustomButton fill wd onClick={() => this.setState((state) => ({
                                showFilterMenu: !state.showFilterMenu,
                            }))}>
                                {t('Done')}
                            </CustomButton>
                        </div>
                    </Modal.Footer>
                </Modal>
                {copying &&
                    <CopyWeekModal finish={() => this.setState({ copying: false })} />
                }
                {showShiftAllowanceManagement &&
                    <ShiftAllowanceManagement
                        finish={() => this.setState({ showShiftAllowanceManagement: false })}
                        schedulerData={schedulerData}
                    />
                }
            </div >
        );
        return (
            <div className='RBS-Scheduler-header'>
                {schedulerHeader}
            </div>
        )
    }
}
export default inject('scheduleStore', 'userStore', 'projectStore', 'commonStore', 'shiftAllowanceStore')(withLocalization(applicationRouter(observer(ScheduleWeekHeader))));