import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';

//components
import withPermissions from '../../../hoc/withPermissions';
import applicationRouter from '~/hoc/applicationRouter';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/project';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

const defaultUiSchema = {
    'ui:field': 'tabs',
    'ui:tabs': [
        {
            'ui:title': 'Project info',
            id: 'tab1',
            'ui:field': 'layout',
            'ui:layout:hideframe': true,
            'ui:layout': [
                {
                    'ui:subtitle': 'Project basic',
                    name: { xs: 6, sm: 6, md: 4 },
                    project_number: { xs: 6, sm: 6, md: 4 },
                    status: { className: 'mt-sm-2', sm: 6, md: 4 },
                    assignee_id: { className: 'mt-sm-2', sm: 6, md: 4 },
                    //          'project_type':{md:4},
                    image: { sm: 12, md: 4 },
                },
                {
                    tax_number: { xs: 12, sm: 4 },
                    ceo_name: { xs: 12, sm: 4 }
                },
                {
                    'ui:subtitle': 'Customer info',
                    contact_person: { xs: 12, sm: 4, md: 4 },
                    //          'title':{md:4},
                    email: { xs: 12, sm: 4, md: 4 },
                    phone: { xs: 12, sm: 4, md: 4 },
                },
                {
                    'ui:subtitle': 'Location',
                    address: { className: 'mt-sm-2', xs: 12, sm: 4, md: 4 },
                    post_place: { className: 'mt-sm-2', xs: 6, sm: 4, md: 4 },
                    post_number: { className: 'mt-sm-2', xs: 6, sm: 4, md: 4 },
                    g_nr: { className: 'mt-sm-2', xs: 6, sm: 4, md: 4 },
                    b_nr: { className: 'mt-sm-2', xs: 6, sm: 4, md: 4 },
                    gps_data: { className: 'mt-sm-2', xs: 12, sm: 4, md: 4 },
                },
                {
                    'ui:subtitle': 'Extras',
                    data: { md: 12 },
                },
                {
                    'ui:subtitle': 'Project documents',
                    attachments: { md: 12 },
                },
            ],
            data: {
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                'ui:flex': true,
                'ui:layout': [
                    {
                        require_hms: { className: 'col-md-6 mt-sm-2', xs: 6, sm: 6 },
                        security_analyze: { className: 'col-md-6 mt-sm-2', xs: 6, sm: 6 },
                        start_date: { className: 'col-md-6 mt-sm-2', xs: 6, sm: 6 },
                        finish_date: { className: 'col-md-6 mt-sm-2', xs: 6, sm: 6 },
                    },
                ],
                require_hms: {
                    'ui:widget': 'CheckBoxWidget',
                },
                security_analyze: {
                    'ui:widget': 'CheckBoxWidget',
                },
            },
            est_work: {
                'ui:widget': 'Radio',
            },
            image: {
                'ui:widget': 'ImageUpload',
                'ui:imageContext': {
                    model: 'Project',
                    fileType: 'logo',
                    id: 0,
                    isDashedBg: true
                },
                'ui:title': "Project logo"
            },
            attachments: {
                'ui:widget': 'AttachmentsWidget',
                'ui:imageContext': {
                    model: 'Project',
                    fileType: 'docs',
                    id: 0,
                },
            },
            gps_data: {
                'ui:widget': 'GpsCoordinatesWidget',
            },
            status: {
                'ui:widget': 'ProjectStatuses',
            },
            assignee_id: {
                'ui:widget': 'ManagersWidget',
            },
            project_number: {
                'ui:placeholder': 'Leave blank to auto-populate',
            },
        },
        {
            'ui:title': 'Members',
            'ui:layout:hideframe': true,
            id: 'tab2',
            'ui:field': 'layout',
            'ui:layout': [
                {
                    members: {
                        md: 12,
                        className: "custom-layout-class"
                    },
                },
            ],
            multipleMembersSelection: {
                'ui:options': { orderable: false, removable: true, inline: true },
                className: 'form-chooser',
                items: {
                    'ui:options': { orderable: false, removable: true, inline: true },
                    className: 'form-chooser',
                    user_id: {
                        classNames: 'col-sm-6 col-12',
                        'ui:widget': 'MembersWidget',
                    },
                },
            },
            members: {
                'ui:options': { orderable: false, removable: true, inline: true },
                className: 'form-chooser',
                items: {
                    'ui:options': { orderable: false, removable: true, inline: true },
                    className: 'form-chooser',
                    user_id: {
                        classNames: 'col-sm-6 col-12',
                        'ui:widget': 'MembersWidget',
                    },
                    additional_pay: {
                        classNames: 'col-sm-6 col-12',
                    },
                    hours: { classNames: 'col-sm-6 col-12' },
                    timeframe: {
                        classNames: 'col-sm-6 col-12',
                        'ui:widget': 'Radio',
                    },
                },
            },
        },
    ],
};

class ProjectsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: getSchema(),
            uiSchema: defaultUiSchema,
        };
    }

    getListUrl() {
        return '/admin/projects';
    }

    async loadData() {
        const { projectStore, commonStore } = this.props;
        this.setState({
            schema: getSchema(commonStore.config?.biztype?.data?.allow_multiple_branches),
        });
        let getId = (this.props.router.location.state && this.props.router.location.state.projects) || null;
        if (!getId) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return projectStore.returnDefaultNew();
        }
        const project = await projectStore.load(getId, !getId);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, project.project) });
    }

    prepareSchema(_schema, currentEntity) {
        const { currentUser } = this.props.userStore;
        const schema = cloneDeep(_schema);
        schema['ui:tabs'][0].attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Project',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        if (this.props.aclAllowed('show_times_on_project')) {
            schema['ui:tabs'][0]['ui:layout'].push({
                'ui:subtitle': 'Costs and expenses',
                est_hours: { md: 6 },
                est_work: { md: 6 },
            });
        }
        if (currentEntity && currentUser.user_type === 'admin') {
            schema['ui:tabs'].push({
                'ui:title': 'Project Managers',
                id: 'tab3',
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                'ui:layout': [
                    {
                        project_managers: { md: 12 }
                    }
                ],
                project_managers: {
                    'ui:widget': 'ProjectMemberRulesWidget',
                }
            })
        }
        return schema;
    }

    componentWillMount() {
        this.props.projectStore.loading = true;
    }

    async componentDidMount() {
        await this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    async recalcGps(e) {
        return this.props.projectStore.recalcGpsForProject(e);
    }

    onSave(values) {
        try {
            const { projectStore, t, commonStore } = this.props;
            const clientData = commonStore.config && commonStore.config.client && commonStore.config.client.data;
            const isGPSObligatory = clientData.basicRules.workinghourGPSObligatory;
            let getId = (this.props.router.location.state && this.props.router.location.state.projects) || null;
            if (isGPSObligatory) {
                if (values.gps_data === null || values.gps_data === undefined || values.gps_data === '') {
                    commonStore.addNotification(t('Please add address.'), null, 'error');
                    return false;
                }
            }
            if (values.taskCount && projectStore.currentEntity.project.status === 'active' && projectStore.currentEntity.project.status !== values.status) {
                const str = `${t(`By Inactiving this project, It will remove all the project member from the project and it will inactive task as well`)}.  ${t('Task Count')}: ${values.taskCount}`
                confirmAlert({
                    title: t('Confirm the action') + '?',
                    message: str,
                    buttons: [
                        {
                            label: t('Yes') + ', ' + t('Inactive'),
                            onClick: () => {
                                return this.saveProjectDetails({ ...values, inactiveTask: true }, getId);
                            },
                        },
                        {
                            label: t('No'),
                            onClick: () => { },
                        },
                    ],
                })
            }
            else if (projectStore.currentEntity.project.status === 'inactive' && values.status === 'active')
                return this.saveProjectDetails({ ...values, activeTask: true }, getId)
            else
                return this.saveProjectDetails(values, getId)
        }
        catch (err) {
            console.error(err);
        }
    }

    saveProjectDetails = async (values, getId) => {
        const { projectStore, t, commonStore } = this.props;
        return projectStore.save(values, !getId).then((result) => {
            if (!result.project || !result.project.id) {
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            if (commonStore.config?.integration_details?.product === 'tripletex') {
                if (!getId || !projectStore?.currentEntity?.project?.int_id) {
                    const str = t(`Do You want to Sync Project with Tripletex`);
                    confirmAlert({
                        title: this.props.t('Confirm to Sync'),
                        message: str,
                        buttons: [
                            {
                                label: this.props.t('Yes'),
                                onClick: () => {
                                    return projectStore.saveIntoIntegration(result.project).then((res2) => {
                                        projectStore.resetLastListLoadTime();
                                        commonStore.addNotification(t(res2.message), null, 'success');
                                        return res2;
                                    });
                                },
                            },
                            {
                                label: this.props.t('No'),
                                onClick: () => { },
                            },
                        ],
                    })
                }
                else {
                    projectStore.saveIntoIntegration(result.project).then((res2) => {
                        return res2;
                    }).catch(e => {
                        console.error(e);
                    });
                }
            }
            this.getBack();
            return true;
        }).catch(e => {
            console.error((e));
        });
    }

    getBack = () => {
        const { page, prevPath } = this.props.router.location.state || {};
        if (prevPath)
            return this.props.router.navigate(prevPath)
        this.props.router.navigate('/admin/projects', { state: { page: page, isEdit: true } });
    }

    validateProject(formData, errors) {
        const { t, commonStore } = this.props;
        if (commonStore.config.client.data.basicRules.workinghourGPSObligatory) {
            if (!formData.address)
                errors.address.addError(t('Please Add Address'));
        }
        return errors;
    }

    render() {
        const { projectStore, add } = this.props;
        const { loading, currentEntity } = projectStore;
        const { schema, uiSchema } = this.state;

        if (loading) return <LoadingSpinner />;
        return (
            <>
                <div className="primary-page">
                    <GenericForm
                        entity={currentEntity.project}
                        recomputeFields={['gps_data', 'data.addressCache']}
                        uiSchema={uiSchema}
                        schema={schema}
                        translationScope="forms.project"
                        onSave={(values) => this.onSave(values)}
                        onChange={async (data) => this.recalcGps(data)}
                        listUrl={this.getListUrl()}
                        isAdding={add}
                        goBack={() => this.getBack()}
                        customvalidate={(formData, errors) => this.validateProject(formData, errors)}
                    />
                </div>
            </>
        );
    }
}

export default inject(
    'projectStore',
    'commonStore',
    'userStore'
)(applicationRouter(withLocalization(withPermissions(observer(ProjectsForm)))));
