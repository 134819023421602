import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';

class ServiceRequestStore {
    constructor() {
        makeObservable(this, {
            loading: observable,
            lastListLoadTime: observable,
            filters: observable,
            appliedFilters: observable,
            currentEntity: observable,
            currentList: observable,
            resetLastListLoadTime: action,
            setFilter: action,
            onFilter: action,
            loadList: action,
            save: action,
            remove: action,
            load: action,
            saveStatus: action,
            assignMembers: action,
            saveExtendRequest: action,
            showAvailableClients: action
        });
    }

    loading = false;

    lastListLoadTime = null;

    allShiftAllowance = 0;

    currentEntity = {};

    currentList = null;

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    filters = {
        status: '',
        listStatusFilter: '',
        name: '',
    };

    appliedFilters = {
        status: '',
        listStatusFilter: '',
        name: '',
    };

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    load(id, user_type, extendValue) {
        return agent.ServiceRequests.load(id, user_type, extendValue)
            .then(
                action(response => {
                    this.currentEntity = response;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    loadList(params) {
        if (!params?.filters?.listStatusFilter)
            params.filters.listStatusFilter = 'active';
        return agent.ServiceRequests.list(params)
            .then(
                action(list => {
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list.rows;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    loadMemberCountForShiftAndLeaves() {
        return agent.ServiceRequests.loadMemberCountForShiftAndLeaves()
            .then(
                action(list => {
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    save(values, isAdd, isCustomer) {
        return agent.ServiceRequests.saveServiceRequest(values, isAdd, isCustomer)
            .then(
                action(data => {
                    return data;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    async remove(id) {
        await agent.ServiceRequests.remove(id);
        this.deleteSuccess = true;
        this.resetLastListLoadTime()
        return 1;
    }

    saveStatus(values) {
        return agent.ServiceRequests.saveStatus(values)
            .then(
                action(data => {
                    return data;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    assignMembers(values) {
        return agent.ServiceRequests.assignMembers(values)
            .then(
                action(data => {
                    return data;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    removeAssignedMember(values) {
        return agent.ServiceRequests.removeAssignedMember(values)
            .then(
                action(data => {
                    return data;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    saveExtendRequest(values) {
        return agent.ServiceRequests.saveExtendRequest(values)
            .then(
                action(data => {
                    return data;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    showAvailableClients(email) {
        return agent.ServiceRequests.getAvailableClients(email)
            .then(
                action(data => {
                    return data;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

}

const _ServiceRequestStore = new ServiceRequestStore();
export default _ServiceRequestStore;