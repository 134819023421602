import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

//components
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import Button from '../../../components/CustomButton/CustomButton.jsx'

//assets
import editIcon from '../../../assets/img/editing.svg';
import deleteIcon from '../../../assets/img/deleting.svg';
import { SimpleSelectWidget } from '../../../components/GenericForm/Widgets/SimpleSelect.js'

const defaultSettingsCode = ["active", "inactive", "self", "child", "normal",
    "nonpaid", "doctor", "materials", "buying", "default"];

class TableWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: props.headers,
            data: props.data,
            metaData: props.metaData,
            newRow: {},
            editableId: -1
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDataChanges = this.handleDataChanges.bind(this);
        this.handleTableActionButtonAction = this.handleTableActionButtonAction.bind(this);
        this.addRowToData = this.addRowToData.bind(this);
        this.editRowInData = this.editRowInData.bind(this);
        this.setNewRowFields = this.setNewRowFields.bind(this);
        this.checkForRequiredFields = this.checkForRequiredFields.bind(this);
        this.updateRowInData = this.updateRowInData.bind(this);
        this.removeObjectFormData = this.removeObjectFormData.bind(this);
        this.validateDataWithServer = this.validateDataWithServer.bind(this);
    }

    componentDidMount() {
        this.setNewRowFields();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data != prevState.data) {
            return { data: nextProps.data };
        }
        else return null;
    }

    handleTableActionButtonAction = (type, index, customFormHandler = null, customDeleteHandler = null) => {
        if (type === 'add') {
            this.addRowToData();
        }
        else if (type === 'edit') {
            if (customFormHandler) {
                customFormHandler(index)
            }
            else {
                this.editRowInData(index);
            }
        }
        else if (type === 'update') {
            this.updateRowInData(index);
        }
        else {
            if (customDeleteHandler) {
                customDeleteHandler(index)
            } else {
                this.removeObjectFormData(index);
            }
        }
    }

    setNewRowFields = () => {
        const { newRow, metaData } = this.state;
        for (const i in metaData) {
            newRow[metaData[i].field] = metaData[i].type === 'text' ? '' : 0;
        }
        this.setState({ newRow });
    }

    checkForRequiredFields(row) {
        const { metaData } = this.state;
        const { commonStore, t } = this.props;
        let total_share = 0;
        for (const i in metaData) {
            if (!row[metaData[i].field] && metaData[i].required) {
                commonStore.addNotification(t('Please fill all required fields'), null, 'error');
                return false;
            } else if (this.props.page === "tip" && metaData[i].field === "percentage" && row[metaData[i].field] > 100) {
                commonStore.addNotification(t('Share percentage(%) must be less then 100'), null, 'error');
                return false
            }
            if (this.props.page === "tip" && metaData[i].field === "percentage") {
                total_share = total_share + row[metaData[i].field];
            }
        }

        if (this.props.page === "tip") {
            this.state.data.forEach((data, index) => {
                if (index !== this.state.editableId) {
                    total_share = total_share + data.percentage
                }
            });
            if (total_share > 100) {
                commonStore.addNotification(t('Total share percentage(%) of all employee type must be less then 100'), null, 'error');
                return false
            }
        }
        return true;
    }

    checkForUniqueFields(row) {
        const { metaData, data, editableId, headers } = this.state;
        const { commonStore, t } = this.props;
        for (const i in metaData) {
            if (row[metaData[i].field] && metaData[i].unique &&
                data.some((e, index) => e[metaData[i].field] === row[metaData[i].field] && index !== editableId)) {
                commonStore.addNotification(`${t('Please fill unique value for')} ${t(headers[i])}! ${t("Same value already exists.")}`, null, 'warning');
                return false;
            }
        }
        return true;
    }

    addRowToData = async () => {
        const { newRow, metaData, data } = this.state;
        if (!this.checkForRequiredFields(newRow)) return;
        if (!this.checkForUniqueFields(newRow)) return;
        let temp = {};
        for (const i in metaData) {
            temp[metaData[i].field] = newRow[metaData[i].field]
        }
        if (temp && temp.code && defaultSettingsCode.includes(temp.code)) {
            this.props.commonStore.addNotification(this.props.t("Can't add default setting code"), null, 'warning');
            return;
        }
        data.push(temp);
        this.setState({ data }, () => {
            this.props.onChange(this.state.data)
            this.setNewRowFields();//reset fields
        });
        return;
    }

    editRowInData = (index) => {
        const { data } = this.state;
        if (data && data[index] && data[index].code && defaultSettingsCode.includes(data[index].code)) {
            this.props.commonStore.addNotification(this.props.t("Can't edit default setting code"), null, 'warning');
            return;
        }
        this.setState({ editableId: index })
    }

    updateRowInData = (index) => {
        const { data } = this.state;
        if (data && data[index] && data[index].code && defaultSettingsCode.includes(data[index].code)) {
            return this.props.commonStore.addNotification(this.props.t("Can't add default setting code"), null, 'warning');
        }
        if (!this.checkForRequiredFields(data[index])) return;
        if (!this.checkForUniqueFields(data[index])) return;
        this.setState({ editableId: -1 })
        return;
    }

    removeObjectFormData = (index) => {
        const { data } = this.state;
        const { handleDelete, t, commonStore, onChange, page, deleteValidator } = this.props;
        if (data && data[index] && data[index].code && defaultSettingsCode.includes(data[index].code)) {
            return this.props.commonStore.addNotification(this.props.t("Can't remove default setting code"), null, 'warning');
        }

        return handleDelete(index, async () => {
            if (page === 'tip' || page === 'roles') {
                const dataValidator = [...data]
                dataValidator.splice(index, 1);
                const isValid = await this.validateDataWithServer(dataValidator);
                if (!isValid) {
                    return false
                }
            }
            if (deleteValidator) {
                const { isValid, message } = await deleteValidator(data[index].code);
                if (!isValid) {
                    if (message) commonStore.addNotification(message, null, 'warning');
                    return false
                }
            }
            data.splice(index, 1);
            commonStore.addNotification(t('Deleted'), null, 'error');
            this.setState({ data }, onChange(data));
        });
    }


    validateDataWithServer = async (value) => {
        const { clientStore } = this.props;
        const currentClient = _.cloneDeep(clientStore.currentEntity.client);
        const tipRules = currentClient.data && currentClient.data.tipRules ? currentClient.data.tipRules : {};
        currentClient.data.tipRules = { ...tipRules, profiles: value }
        currentClient.data.roles = value;
        return clientStore.save(currentClient, false).then((result) => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    handleInputChange = (name, evt) => {
        const { newRow } = this.state;
        newRow[name] = evt?.target?.type === "number" ?
            evt.target.value.includes('.') ? parseFloat(evt.target.value) :
                parseInt(evt.target.value, 10) : evt.target.value;
        this.setState({ newRow });
    }

    handleDataChanges(name, evt, index) {
        const { data } = this.state;
        data[index][name] = evt.target.type === "number" ?
            evt.target.value.includes('.') ? parseFloat(evt.target.value) :
                parseInt(evt.target.value, 10) : evt.target.value;
        this.setState({ data }, () => this.props.onChange(this.state.data))
    }

    render() {
        const { headers, data, metaData, editableId, newRow } = this.state;
        const { t, minRemoveIndex, maxLength, addExtraItemsForActionCol,
            className, customFormHandler, customDeleteHandler } = this.props;
        const checkIndexToDelete = minRemoveIndex && minRemoveIndex > 0 ? true : false;
        const isMaxLengthReached = maxLength && maxLength > 0 && (data.length >= maxLength) ? true : false;
        return (
            <Table striped hover className={className ? `${className} cp-table` : 'cp-table'} responsive>
                <thead>
                    <tr>
                        {headers.map((item, index) => (
                            <th key={"header_key_oece" + index} style={metaData[index]?.thStyle ? metaData[index].thStyle : null}><b>{t(item)}</b></th>
                        ))}
                        <th key={"header_key_oece16x"}><b>{t('Actions')}</b></th>
                    </tr>
                </thead>
                <tbody>
                    {!customFormHandler && this.props.page !== 'tip' && !isMaxLengthReached &&
                        <tr>
                            {metaData.map((item, index) => (
                                item.select ?
                                    <td key={`data_key_${index}`}
                                        style={item.tdStyle ? item.tdStyle : null}>
                                        <SimpleSelectWidget
                                            options={item.data}
                                            placeholder={t(item.placeholder)}
                                            value={newRow[item.field]}
                                            onChange={(id) => this.handleInputChange(item.field, { target: { value: id } })}
                                            styleClasses={"custom-select-max-height-110"}
                                            returnObject={true}
                                            wrapperClass='w-100'
                                        />
                                    </td>
                                    :
                                    <td key={`data_key_${index}`}>
                                        <input className="cp-input" style={item.thStyle ? item.thStyle : null} type={item.type} name={item.field} placeholder={t(item.placeholder)}
                                            onChange={evt => this.handleInputChange(item.field, evt)}
                                            value={newRow[item.field] ? newRow[item.field] : item.type === 'number' ? '' : ''} />
                                        {item.required && <span className="text-danger d-inline"> <b>{t('*')}</b></span>}
                                    </td>
                            ))}
                            <td key={"data_key_16x"}>
                                <Button simple fill
                                    disabled={editableId > -1}
                                    onClick={() => this.handleTableActionButtonAction('add', null, customFormHandler, null)}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </Button>
                            </td>
                        </tr>
                    }
                    {data.map((item, index) => (
                        <tr key={"data_key_oece" + index}>
                            {metaData.map((label, idx) =>
                                <td key={`data_list key_${idx}`} style={label.tdStyle ? label.tdStyle : null}>
                                    {index === editableId ?
                                        !label.add && label.field === 'employee_type' ?
                                            <label>{item.employee_type}</label>
                                            :
                                            <>
                                                {label.select ?
                                                    <SimpleSelectWidget
                                                        options={label.data}
                                                        placeholder={t(label.placeholder)}
                                                        value={item[label.field]}
                                                        onChange={(id) => this.handleDataChanges(label.field, { target: { value: id } }, index)}
                                                        styleClasses={"custom-select-max-height-110"}
                                                        returnObject={true}
                                                        wrapperClass='w-100'
                                                    />
                                                    :
                                                    <input className="cp-input" type={label.type} style={{ maxWidth: label.maxWidth }} name={label.field} placeholder={t(label.placeholder)}
                                                        onChange={evt => this.handleDataChanges(label.field, evt, index)}
                                                        value={item[label.field] ? item[label.field] : ''}
                                                        checked={item[label.field] ? item[label.field] : false}
                                                    />}
                                                {label.required && <span className="text-danger d-inline"> <b>{t('*')}</b></span>}
                                            </>
                                        :
                                        <>
                                            {label.select ? t(item[label.field]?.label) : t(item[label.field])}
                                        </>
                                    }
                                </td>
                            )}
                            <td key={"data_list_key_16x"} style={{ minWidth: "100px" }}>
                                {item && item.code && defaultSettingsCode.includes(item.code) ?
                                    <span>
                                        {addExtraItemsForActionCol && addExtraItemsForActionCol(item)}
                                        {t('Defaults')}
                                    </span>
                                    : <>
                                        {index === editableId ?
                                            <Button style={{ marginRight: "8px" }} onClick={() => this.handleTableActionButtonAction('update', index, null, null)}
                                                icon_sm fill>
                                                <i className="fa fa-save" style={{ fontSize: "18px" }} aria-hidden="true"></i>
                                            </Button>
                                            :
                                            <Button
                                                disabled={(editableId > -1 && index !== editableId)}
                                                icon_sm fill style={{ marginRight: "8px" }}
                                                onClick={() => this.handleTableActionButtonAction('edit', index, customFormHandler, null)}>
                                                <img src={editIcon} alt={"edit button"} />
                                            </Button>
                                        }
                                        {!checkIndexToDelete && !(minRemoveIndex > index) &&
                                            <Button
                                                disabled={(editableId > -1) || item.isNotDeletable}
                                                icon_sm_delete fill
                                                onClick={() => this.handleTableActionButtonAction('delete', index, null, customDeleteHandler)}  >
                                                <img src={deleteIcon} alt="delete button" />
                                            </Button>
                                        }
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table >
        );
    }
}

export default inject('commonStore', 'clientStore')(withLocalization(withRemoveDialog(observer(TableWidget))));