import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';
import { distanceUnitType } from '../../../../utils/dateFormat'
import RequiredStar from '../../../../elements/RequiredStar.js';

class kilometerRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kilometersRules: []
        }
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.extendedRules) {
            const extendedRules = Object.assign({}, currentEntity.client.data.extendedRules);
            this.setState({
                kilometersRules: extendedRules.kilometersRules
            })
        }
    }

    render() {
        const { kilometersRules } = this.state;
        const { config } = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        let metaData =[{ field: 'distance', type: 'text', placeholder: 'Distance / Zone', required: true, unique: true },
        { field: 'max_km', type: 'text', placeholder: '0', required: false, unique: true }]
        let headers = ['Distance / Zone', 'Max' + " " + `${distanceUnitType(dateTimeRules)}`];
        if (this.props.commonStore?.config?.integration_details?.wageType?.length) {
            metaData = [{ field: 'distance', type: 'text', placeholder: 'Distance / Zone', required: true, unique: true },
            { field: 'payrollWageType', type: 'select', placeholder: 'Wage Type', select: true, data: this.props.commonStore?.config?.integration_details?.wageType, unique: true },
            { field: 'max_km', type: 'text', placeholder: '0', required: false, unique: true }];
            headers = ['Distance / Zone', 'Wage Type', `Max ${distanceUnitType(dateTimeRules)}`];
        }
        return (
            <div>
                {!!this.props.commonStore?.config?.integration_details?.wageType?.length &&
                    <>
                        <div className='fw-600'><RequiredStar/><RequiredStar/> {this.props.t('Please select the payroll wagetype if you want to Sync the Km/Miles to integration system')}.</div>
                        {!this.props.commonStore.config?.client?.gps_data &&
                            <div className='fw-600'><RequiredStar/><RequiredStar/> {this.props.t('If You want to use this Feature, Please add the company address under company profile')}.</div>
                        }
                    </>
                }
                <TableWidget
                    headers={headers}
                    metaData={metaData}
                    data={kilometersRules}
                    onChange={data => this.props.handleUserDataChange('kilometersRules', data)}
                    className={'mb-100'}
                />
            </div >
        );
    }
}
export default inject('clientStore', 'commonStore')(applicationRouter(withLocalization(observer(kilometerRules))));
