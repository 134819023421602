import React, { Component } from 'react';
import shave from 'shave';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import withRemoveDialog from '~/hoc/withRemoveDialog';

import './ConversationListItem.css';

class ConversationListItem extends Component {
    loadData() {
        this.props.chatStore.loadPrivateRooms();
        this.props.chatStore.setCurrentPrivateRoom();
        this.props.chatStore.setCurrentPrivateRoomId();
        this.props.router.navigate('/admin/privatechat')
    }

    componentDidMount() {
        shave('.conversation-snippet', 25);
    }

    handleRemove(id) {
        const { commonStore, t, chatStore } = this.props;
        this.props.handleDelete(id, id => {
            return this.props.chatStore.removePrivateRoomData(id).then(res => {
                commonStore.addNotification(t('Deleted'), null, 'error');
                this.loadData();
                this.props.chatStore.sendDeletedEvent();
                return res;
            })
        });
    }

    render() {
        const { name, id, room_initiator } = this.props.data;
        const { changeRoom, currentRoomId, unreads, commonStore, userStore } = this.props;
        const unread = unreads[id];
        const { chatCurrentTab } = this.props.chatStore;
        const lastMessage = this.props.data.lastMessage ? this.props.data.lastMessage : "";
        let dateTime = false;
        let dateTimeRules = commonStore.config.client && commonStore.config.client.data &&
            commonStore.config.client.data.dateTimeRules ?
            { ...commonStore.config.client.data.dateTimeRules }
            : { short_date_format: "DD.MM.YYYY", time_format: "hh:mm" };
        dateTimeRules.short_date_format = dateTimeRules.short_date_format ? dateTimeRules.short_date_format : "DD.MM.YYYY";
        dateTimeRules.time_format = dateTimeRules.time_format ? dateTimeRules.time_format : "hh:mm";
        if (lastMessage != "") {
            if (moment(lastMessage.created_at).isBefore(new Date(), 'day'))
                dateTime = moment(lastMessage.created_at).format(dateTimeRules.short_date_format);
            else
                dateTime = moment(lastMessage.created_at).format(dateTimeRules.time_format == "hh:mm" ? 'H:mm' : 'hh:mm a');
        }
        const showUnread = !!(unread && unread !== '0');
        return (
            <div
                className={`conversation-list-item ${currentRoomId === id ? 'conversation-list-item-current' : ''}`}
                onClick={() => changeRoom(id)} style={{ marginTop: '10px' }}
            >
                <div className='conversation-room-initials'>{name.replace('and', '').split(" ").map(obj => obj[0]).join('').substring(0, 3)}</div>
                <div className="conversation-info">
                    <h1 className="conversation-title" title={name}>{name}</h1>
                    <p className="conversation-snippet">
                        {lastMessage.message != "" ?
                            lastMessage.message :
                            lastMessage.image > 0 ? this.props.t('Shared an image') : ''
                        }
                    </p>
                </div>
                {showUnread ? <div className="conversation-badge">{unread}</div>
                    : <div className="conversation-last-message-time">
                        {dateTime != false && <p style={{ fontSize: '12px', marginTop: '10px' }}>{dateTime}</p>}
                    </div>
                }
                {chatCurrentTab === 2 && room_initiator === userStore.currentUser.id ?
                    <i
                        className="fas fa-trash-alt cursor-pointer"
                        aria-hidden="true"
                        style={{ fontSize: '20px', color: 'grey', paddingLeft: '12px' }}
                        onClick={() => this.handleRemove(id)}
                    ></i> : ''}
            </div>
        );
    }
}

export default inject('commonStore', 'chatStore', 'userStore')(withRemoveDialog(observer(ConversationListItem)));
