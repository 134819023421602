import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';
import { confirmAlert } from 'react-confirm-alert';


//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericList from '~/components/GenericList/GenericList';
import Button from '~/components/CustomButton/CustomButton.jsx';
import ShowAvailabiliy from './ShowAvailability';
import GenericFilter from '../../../components/GenericList/GenericFilter';

//elements
import StatusBox from '../../../elements/StatusBox';

//utils
import { shortDateFormat, timeFormat } from '~/utils/dateFormat';

//assets
import Delete from '~/assets/img/deleting.svg';
import Edit from '~/assets/img/editing.svg';
import CloseModalIcon from '~/assets/img/CloseApproveModalIcon.svg';

import '../../../styles/css/styles.css';
import '~/components/Scheduler/css/style.css';

class ScheduleAvailability extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowMemberAvailability: false,
            memberData: {
                from_date: '',
                to_date: '',
                user_id: ''
            },
            accepting: null,
        }
        this.hideAvailability = this.hideAvailability.bind(this)
    }

    componentWillMount() {
        this.props.scheduleStore.loading = true;
    }

    memberAvailability = (full) => {
        this.setState({ isShowMemberAvailability: true })
        this.setState({
            memberData: {
                from_date: full.from_date,
                to_date: full.to_date,
                user_id: full.user_id
            }
        })
    }

    hideAvailability() {
        this.setState({ isShowMemberAvailability: false })
    }

    handleTableButtonAction = (id, type, data) => {
        const { t } = this.props;
        switch (type) {
            case 'edit':
                this.props.editRowData(data);
                break;
            case 'remove':
                const str = `${t("Are you sure to delete the availability")}?`;
                confirmAlert({
                    title: this.props.t('Confirm to delete'),
                    message: str,
                    overlayClassName: "custom-confirm-overlay-class",
                    buttons: [
                        {
                            label: t('Yes'),
                            onClick: () => {
                                return this.props.scheduleStore.removeAvailability(id).then(res2 => {
                                    this.props.scheduleStore.resetLastListLoadTime();
                                    return res2;
                                });
                            },
                        },
                        {
                            label: t('No'),
                            onClick: () => { },
                        },
                    ],
                });
            default:
                break;
        }
    }

    handleAccept(id, full) {
        if (full.status == 'approved' && !full.validateTimetrackOption) return;
        this.setState({
            accepting: {
                id: id,
                count: 1,
                entity: 'availability',
                full: full
            },
        });
    }

    updateAvailabilityStatus(id, value) {
        const { t, commonStore, scheduleStore } = this.props
        let values = {
            status: value,
            comment: value
        }
        scheduleStore.updateAvailabilityStatus(id, values).then(res => {
            if (res) {
                commonStore.addNotification(t('Success'), null, 'success');
                this.setState({ accepting: null }, () => {
                    this.props.scheduleStore.resetLastListLoadTime();
                    this.props.updateAvailabilityStatus();
                })
            }
        }).catch(e => {
            console.error(e);
            return commonStore.addNotification(t('Something went wrong'), null, 'error');
        })
    }

    renderTableActionButtons = (id, full) => {
        const { user_type } = this.props.userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type == 'admin')
        const actionable = showStatus && full.status != 'approved';
        return (
            <div className="actions-center justify-content-center align-items-center">
                {user_type !== 'member' &&
                    <div
                        className={`status-block status-${full.status || 'active'}${actionable ? ' status-actionable' : ''
                            }${(full.status === 'approved' && full.validateTimetrackOption) ? ' status-approved-validate-timetrack' : ''}${user_type === 'member' ? ' status-no-click-for-member' : ''}`}
                        onClick={() => (showStatus ? this.handleAccept(id, full) : null)}
                    >
                        <i><svg style={{ height: '16px', paddingRight: '2px' }} aria-hidden focusable={false} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa"><path fill="currentColor" d="M224 512a64 64 0 0 0 64-64H160a64 64 0 0 0 64 64zm215.39-149.71c-19.32-20.76-55.47-52-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32a32 32 0 1 0-64 0v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29A31.24 31.24 0 0 0 0 384c.11 16.4 13 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32a31.23 31.23 0 0 0-8.61-21.71zM224 352a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm25.4-110.4a16 16 0 0 1-15.9 14.4h-19a16 16 0 0 1-15.9-14.4l-12.8-96a16.06 16.06 0 0 1 15.9-17.6h44.6a16 16 0 0 1 15.9 17.6z"></path></svg></i>
                        <small>
                            <small>
                                {full.fullname ? full.fullname : ''}
                            </small>
                        </small>
                    </div>
                }
                <Button icon_sm fill
                    disabled={full.status !== 'pending'}
                    onClick={() => this.handleTableButtonAction(id, 'edit', full)}>
                    <img src={Edit} alt={"edit"} />
                </Button>

                <Button icon_sm fill
                    disabled={full.status !== 'approved'}
                    onClick={() => this.memberAvailability(full)}>
                    <i className='fa fa-clipboard-check' style={{ fontSize: '19px' }}></i>
                </Button>

                <Button icon_sm_delete fill
                    disabled={full.status === 'approved'}
                    onClick={() => this.handleTableButtonAction(id, 'remove', full)}>
                    <img src={Delete} alt="delete" />
                </Button>
            </div>
        );
    };

    render() {
        const { t, userStore, commonStore } = this.props;
        const { loading, availabilityFilters, appliedAvailabilityFilters } = this.props.scheduleStore;
        const { config } = commonStore;
        const { user_type } = userStore.currentUser;
        const { memberData, isShowMemberAvailability, accepting } = this.state;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            <div>
                <GenericList
                    columns={[
                        {
                            Header: 'Member',
                            id: 'first_name',
                            sortable: false,
                            accessor: ({ first_name, last_name }) => (
                                <span>
                                    {first_name} {last_name}
                                </span>
                            ),
                            maxWidth: 90,
                            minWidth: 80,
                        },
                        {
                            Header: 'Effective dates',
                            id: 'request_type',
                            sortable: false,
                            accessor: ({ request_type, from_date, to_date, }) => (
                                <span>
                                    {request_type == 'repeating' ?
                                        t('Repeating')
                                        : (shortDateFormat(from_date, dateTimeRules) + ' | ' + shortDateFormat(to_date, dateTimeRules))}
                                </span>
                            ),
                        },
                        {
                            Header: 'Date submitted',
                            id: 'date_submit',
                            sortable: true,
                            accessor: ({ date_submit }) => date_submit && shortDateFormat(date_submit, dateTimeRules),
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            sortable: true,
                            accessor: ({ status }) => (
                                <StatusBox status={status || ''} />
                            ),
                            maxWidth: 90,
                            minWidth: 80,
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            sortable: false,
                            accessor: full => this.renderTableActionButtons(full.id, full),
                        },
                    ]}
                    forceReload={this.props.scheduleStore.deleteSuccess}
                    header={(
                        <GenericFilter
                            filters={availabilityFilters}
                            setFilter={(name, value) => this.props.scheduleStore.setAvailabilityFilter(name, value)}
                            hasSearch={user_type !== 'member'}
                            hasDateFilters={true}
                            searchFilterPlaceholder={"Search by employee name"}
                            onFilter={() => this.props.scheduleStore.onAvailabilityFilter()}
                            loading={loading}
                        />
                    )}

                    filters={appliedAvailabilityFilters}
                    lastListLoadTime={this.props.scheduleStore.lastListLoadTime}
                    handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                    page={this.props.page}//new page props to maintain page from parent//
                    isPageAddedOrEdited={this.props.isPageAddedOrEdited}//to load same page after edit//
                    requestData={(params) => this.props.scheduleStore.loadAvailabilityList(params)}
                />

                {isShowMemberAvailability &&
                    <Modal dialogClassName="lg bulk-shift-modal" className="lg" size="lg" show onHide={this.hideAvailability}>
                        <Modal.Header className="set_ModalHeader" >
                            <Modal.Title className='w-100 text-center custom-modal-title'>
                                {this.props.t('Member Availability')}
                            </Modal.Title>
                            <img
                                src={CloseModalIcon}
                                className="cursor-pointer"
                                alt='close icon'
                                onClick={this.hideAvailability}
                            />
                        </Modal.Header>
                        <Modal.Body>
                            <ShowAvailabiliy
                                start_date={moment(memberData.from_date).format('YYYY-MM-DD')}
                                end_date={moment(memberData.to_date).format('YYYY-MM-DD')}
                                userIDs={memberData.user_id} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                fill wd icon
                                type="submit"
                                style={{ backgroundColor: 'green' }}
                                onClick={() => this.updateAvailabilityStatus(accepting.id, 'approved')}>
                                {t('Approve')}
                            </Button>
                            <Button
                                fill wd icon
                                type="submit"
                                style={{ backgroundColor: 'red', marginRight: 30, border: 0 }}
                                onClick={() => this.updateAvailabilityStatus(accepting.id, 'rejected')}>
                                {t('Reject')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
                <Modal size="lg" show={!!accepting} onHide={() => this.setState({ accepting: null })}>
                    <Modal.Header>
                        <Modal.Title>{this.props.t('Accept/reject')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            alt='close icon'
                            onClick={() => this.setState({ accepting: null })}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div className='ms-3'>
                            {!!accepting &&
                                <>
                                    <Row>
                                        <Col xs={4}>
                                            <div>
                                                <p className='fw-bold m-0'>{t("Employee")}</p>
                                                <p>{accepting.full.first_name}&nbsp;{accepting.full.last_name}</p>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div>
                                                <p className='fw-bold m-0'>{t("Effective dates")}</p>
                                                <p>{accepting.full.request_type == 'repeating' ?
                                                    t('Repeating')
                                                    : (shortDateFormat(accepting.full.from_date, dateTimeRules) + ' | ' + shortDateFormat(accepting.full.to_date, dateTimeRules))}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div>
                                                <p className='fw-bold m-0'>{t('Date submitted')}</p>
                                                <p>{shortDateFormat(accepting.full.date_submit, dateTimeRules)}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
                                        {accepting?.full?.availability?.length ?
                                            <div className='table-responsive'>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: "left" }}>{t("Availability")}</th>
                                                            <th style={{ textAlign: "left" }}>{t("Date")}</th>
                                                            <th style={{ textAlign: "left" }}>{t("Time")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {accepting.full.availability.map(ele =>
                                                            <tr>
                                                                <td style={{ textAlign: "left" }}>
                                                                    <span style={{ color: ele.availability_type === 'available' ? 'green' : 'red' }}>
                                                                        {ele.availability_type === 'available' ? t("Available") : "Unavailable"}
                                                                    </span>
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    <span>
                                                                        {shortDateFormat(ele.for_date, dateTimeRules)}
                                                                    </span>
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    <span>
                                                                        {ele.from ? `${timeFormat(ele.from)} - ${timeFormat(ele.to)}` : t('Full day')}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            : <span>{t("No data found")}</span>
                                        }
                                    </Row>
                                </>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            fill wd icon
                            type="submit"
                            style={{ backgroundColor: 'green' }}
                            onClick={() => this.updateAvailabilityStatus(accepting.id, 'approved')}>
                            {t('Approve')}
                        </Button>
                        <Button
                            fill wd icon
                            type="submit"
                            style={{ backgroundColor: 'red', marginRight: 30, border: 0 }}
                            onClick={() => this.updateAvailabilityStatus(accepting.id, 'rejected')}>
                            {t('Reject')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default inject('userStore', 'commonStore', 'scheduleStore')(applicationRouter(withLocalization(withRemoveDialog(observer(ScheduleAvailability)))));
